import * as RB from "react-bootstrap";
import HealthBox from "./health_box";
import { useState } from "react";
import Summary from "./summary";
import TabuleaView from "../../DashboardViews/TABLEAU";
import POWERBI from "../../DashboardViews/POWERBI";

import WhatHappen from "../Inbound_Analytics/what_happen";
import "./style.css";

const Default_Function = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");
  let [d_v_m_thisweek, set_d_v_m_thisweek] = useState(1);

  let com_view = [{ name: "This week", value: "this_week" }];
  let health = {
    this_week: {
      card: {
        icon: "Icon01",
        icon_type: "img",
        name: "OSA",
        value: 77,
        value_suffix: "%",
        sub_value: 15.61,
        sub_value_suffix: "%",
        sub_value_prefix: "+",
      },
      sub_card: [
        {
          icon: "faChartArea",
          icon_type: "icon",
          name: "Actual Sales",

          value: 30.02,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 17.21,
          sub_value_suffix: "%",
          sub_value_prefix: "+",

          value1: 0.12,
          value1_prefix: "",
          value1_suffix: "M",
          sub_value1: 5.38,
          sub_value1_suffix: "%",
          sub_value1_prefix: "+",
        },
        {
          icon: "Icon02",
          icon_type: "img",
          name: "Forecasted Sales",

          value: 33.71,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 14.4,
          sub_value_suffix: "%",
          sub_value_prefix: "+",

          value1: 0.17,
          value1_prefix: "",
          value1_suffix: "M",
          sub_value1: 6.19,
          sub_value1_suffix: "%",
          sub_value1_prefix: "+",
        },
        {
          icon: "Icon03",
          icon_type: "img",
          name: "Promotion Spending",

          value: 0.72,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 4.66,
          sub_value_suffix: "%",
          sub_value_prefix: "+",

          value1: 0,
          value1_prefix: "",
          value1_suffix: "",
          sub_value1: 0,
          sub_value1_suffix: "",
          sub_value1_prefix: "",
        },
        {
          icon: "Icon04",
          icon_type: "img",
          name: "Lost Sales",

          value: 2.11,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: -24.6,
          sub_value_suffix: "%",
          sub_value_prefix: "-",

          value1: 0,
          value1_prefix: "",
          value1_suffix: "",
          sub_value1: 0,
          sub_value1_suffix: "",
          sub_value1_prefix: "",
        },
      ],
    },
  };

  let summary = {
    this_week: [
      {
        text: "The Delivery Promise levels for next week is predicted to be around 70% which is 5% lower than this week",
        mode: "",
      },
      {
        text: "This is likely to result in decreased sales of 2% and a value of $ 1.2 million.",
        mode: "",
      },
      {
        text: "MC2000 massage chair is the most profitable product for the selected category, this week, with a total sales of $0.12M.",
        mode: "",
      },
      {
        text: "Google trends and seasonality are the 2 leading factors contributing to sales with 26% and 22% contributions respectively.",
        mode: "",
      },
    ],
  };

  let what_happen = {
    this_week: {
      what_happen: [
        {
          text: "MC2000 Massage Chair is the most profitable product for the selected category, next week, with a total sales units of 205 and sales value of $54K",
          mode: "inc",
        },
      ],
      what_did_happen: [
        {
          text: "Google trends and seasonality are the 2 leading factors contributing to sales with 28% and 22% contributions respectively.",
          mode: "inc",
        },
        {
          text: "A 10% discount and Promotion type A can improve sales by 66 units or $18k next week.",
          mode: "inc",
        },
      ],
    },
  };
  return (
    <>
      <RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => set_sel_com_view(e.currentTarget.value)}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>

        {sel_com_view === "this_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["this_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text">
                    <b>Executive Summary</b>
                  </h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["this_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="wi_view_card zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="wi_card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"1000px"}
                      URL={
                        "https://public.tableau.com/views/ForecastingDashboard_whatif/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiMGJiZGVjNWEtOGE3My00ZWJmLWI0OGMtOWE2OGZhNDI5N2FhIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1000}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappen data={what_happen["this_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
      </RB.Row>
    </>
  );
};

export default Default_Function;
