import * as RB from "react-bootstrap";
import {
  faLongArrowRight,
  faDownload,
  faEnvelope,
  faShare,
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

const Default_Function = (props) => {
  let ex_summary = [
    "Inhand Inventory increased to 95.96%",
    "OTIF increased to 59.74% which is 6 % higher than the mean performance",
  ];

  return (
    <RB.Row>
      <RB.Col>
        <RB.Row>
          <h5><b>Your north star metrics</b></h5>
        </RB.Row>
        <RB.Row>
          <RB.Col md={3}>
            <RB.Card className="initial_card shadow zero_border_radius text_center">
              <RB.Card.Body className="text_white">
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      OSA / Service Level
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
                <RB.Card.Text className="inner_card_body">
                  {(props.Health.service_level.length > 0 &&
                    props.Health.service_level[0][
                      "Average_of_Service_Level"
                    ]) ||
                    0}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleDown}
                              className="inner_card_icon text_red"
                            />
                          </span>
                          <span>
                            <span className="text_red"> 5.39 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={3}>
            <RB.Card className="zero_border_radius shadow text_center">
              <RB.Card.Body>
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      Fill Rate
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>

                <RB.Card.Text className="inner_card_body">
                  {props.Health.case_fillrate.length &&
                    props.Health.case_fillrate[0]["Average_of_Fill_Rate"]}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleUp}
                              className="inner_card_icon text_green"
                            />
                          </span>
                          <span>
                            <span className="text_green"> 4.6 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={3}>
            <RB.Card className="zero_border_radius shadow text_center">
              <RB.Card.Body>
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      OOS Cases
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>

                <RB.Card.Text className="inner_card_body">
                  {props.Health.oos.length &&
                    props.Health.oos[0]["Out_Of_Stock_Volume"]}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleDown}
                              className="inner_card_icon text_red"
                            />
                          </span>
                          <span>
                            <span className="text_red"> 5.39 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={3}>
            <RB.Card className="zero_border_radius shadow text_center">
              <RB.Card.Body>
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      Delivery Accuracy
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>

                <RB.Card.Text className="inner_card_body">{"72%"}</RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleUp}
                              className="inner_card_icon text_green"
                            />
                          </span>
                          <span>
                            <span className="text_green"> 2.7 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
        </RB.Row>
      </RB.Col>
      <RB.Col className="cpg_health_summary">
        <RB.Row>
          <RB.Col md={7}>
            <h5 className="summary_text"><b>Executive Summary</b></h5>
          </RB.Col>
          <RB.Col md={5}>
            <RB.Row className="summary_icons_top">
              <RB.Col md={4}>
                <RB.Card className="round_div">
                  <FontAwesomeIcon className="cpg_summary_icon" icon={faShare} />
                </RB.Card>
              </RB.Col>
              <RB.Col md={4}>
                <RB.Card className="round_div">
                  <FontAwesomeIcon className="cpg_summary_icon" icon={faEnvelope} />
                </RB.Card>
              </RB.Col>
              <RB.Col md={4}>
                <RB.Card className="round_div">
                  <FontAwesomeIcon className="cpg_summary_icon" icon={faDownload} />
                </RB.Card>
              </RB.Col>
            </RB.Row>
          </RB.Col>
        </RB.Row>
        <RB.Row>
          <RB.Card className="cpg_summary_min_height shadow zero_border_radius">
            <RB.Card.Body className="font_13">
              {props.Health.quick_summary.map((item, index) => (
                <RB.Row>
                  <RB.Col md={1} className="zero_padding">
                    <FontAwesomeIcon
                      className="summary_arrow"
                      icon={faLongArrowRight}
                    />
                  </RB.Col>
                  <RB.Col md={11} className="zero_padding">
                    {item}
                  </RB.Col>
                </RB.Row>
              ))}
            </RB.Card.Body>
          </RB.Card>
        </RB.Row>
      </RB.Col>
    </RB.Row>
  );
};

export default Default_Function;
