import * as RB from "react-bootstrap";

export default function LinkWithTooltip({ id, children, href, tooltip }) {
  return (
    <RB.OverlayTrigger
      overlay={<RB.Tooltip id={id}>{tooltip}</RB.Tooltip>}
      placement="right"
    >
      <a href={href}>{children}</a>
    </RB.OverlayTrigger>
  );
}
