import * as RB from "react-bootstrap";
import { useState, forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LatentviewLogo from "../../../images/latent-view-analytics-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import * as service from "../../app_service";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const Header = (props) => {
  const history = useNavigate();
  const [user, set_user] = useState({});

  const [startDate, setStartDate] = useState(new Date("2022-02-06"));
  const naigate_to = () => {
    history("/");
  };

  let logout = () => {
    service.logout();
    history("/login");
  };

  let fetch_user_obj = () => {
    let user_obj = service.get_user_obj();
    if (user_obj !== null && user_obj["email"] !== "") {
      set_user(user_obj);
    } else {
      history("/login");
    }
  };

  useEffect(() => {
    fetch_user_obj();
  }, []);

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <FontAwesomeIcon
      ref={ref}
      value={value}
      className="calender_icon"
      icon={faCalendarAlt}
      onChange={onChange}
      onClick={onClick}
    />
  ));

  return (
    <RB.Row className="null_p_m header_content">
      <RB.Row className="null_p_m">
        <RB.Col md={1}>
          <img
            onClick={naigate_to}
            className="header_lvlogo"
            src={LatentviewLogo}
            alt={LatentviewLogo}
          />
        </RB.Col>
        <RB.Col md={4}>
          <RB.Row>
            <h3>
              <b>{"Connected View"}</b>
            </h3>
            <h7 className={"cpg_header_subtext"}>
              {"Tomorrow’s Supply Chain, Optimised Today"}
            </h7>
          </RB.Row>
        </RB.Col>
        <RB.Col md={7} className="cpg_calender_content">
          <span className="cpg_calender_p_r25">
            {props.view_mode === "home" ? (
              <DatePicker
                selected={startDate}
                customInput={<ExampleCustomInput />}
                onChange={(date) => {
                  props.date_event_change(date);
                  setStartDate(date);
                }}
                filterDate={(date) =>
                  date.getDay() === 0 && date.getDay() !== 6
                }
                maxDate={new Date("2022-12-31")}
                minDate={new Date("2022-01-01")}
              />
            ) : (
              <></>
            )}
          </span>
          <span className="cpg_calender_p_r25">Hi, {user.displayName}</span>
          <span>
            <FontAwesomeIcon
              className="logout_icon cpg_calender_p_r25"
              icon={faSignOut}
              title="Logout"
              onClick={() => {
                logout();
              }}
            />
          </span>
        </RB.Col>
      </RB.Row>
    </RB.Row>
  );
};

export default Header;
