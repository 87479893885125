import * as RB from "react-bootstrap";
import {
  faLongArrowRight,
  faDownload,
  faEnvelope,
  faShare,
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

const Default_Function = (props) => {
  let ex_summary = [
    "Inhand Inventory increased to 95.96%",
    "OTIF increased to 59.74% which is 6 % higher than the mean performance",
  ];
  return (
    <RB.Row>
      <RB.Col>
        <RB.Row>
          <h5>
            <b>Your north star metrics</b>
          </h5>
        </RB.Row>
        <RB.Row>
          <RB.Col md={3}>
            <RB.Card className="initial_card zero_border_radius text_center">
              <RB.Card.Body className="text_white">
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      Service Level
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
                <RB.Card.Text className="inner_card_body">
                  {props.health.health && props.health.health.length > 0
                    ? props.health.health[0]["Service_Level"]
                    : ""}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleDown}
                              className="ind_innercard_icon text_red"
                            />
                          </span>
                          <span>
                            <span className="text_red"> 5.39 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={3}>
            <RB.Card className="zero_border_radius text_center">
              <RB.Card.Body>
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      Reliability
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>

                <RB.Card.Text className="inner_card_body">
                  {props.health.health && props.health.health.length > 0
                    ? props.health.health[0]["Reliability"]
                    : ""}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleUp}
                              className="ind_innercard_icon text_green"
                            />
                          </span>
                          <span>
                            <span className="text_green"> 4.6 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={3}>
            <RB.Card className="zero_border_radius text_center">
              <RB.Card.Body>
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      Responsiveness
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>

                <RB.Card.Text className="inner_card_body">
                  {props.health.health && props.health.health.length > 0
                    ? props.health.health[0]["Responsiveness"]
                    : ""}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleDown}
                              className="ind_innercard_icon text_red"
                            />
                          </span>
                          <span>
                            <span className="text_red"> 5.39 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={3}>
            <RB.Card className="zero_border_radius text_center">
              <RB.Card.Body>
                <RB.Card.Title>
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_top">
                      Quality
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>

                <RB.Card.Text className="inner_card_body">
                  {props.health.health && props.health.health.length > 0
                    ? props.health.health[0]["Quality"]
                    : ""}
                </RB.Card.Text>
                <RB.Card.Title className="inner_card_title">
                  <RB.Card className="border_none">
                    <RB.Card.Body className="inner_card_lower">
                      <RB.Row>
                        <RB.Col md={12}>
                          <span className="inner_card_lower_svg">
                            <FontAwesomeIcon
                              icon={faChevronCircleUp}
                              className="ind_innercard_icon text_green"
                            />
                          </span>
                          <span>
                            <span className="text_green"> 2.7 % </span>
                            <span>Week on Week</span>
                          </span>
                        </RB.Col>
                      </RB.Row>
                      <span></span>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Card.Title>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
        </RB.Row>
      </RB.Col>
      <RB.Col className="indus_health_summary">
        <RB.Row>
          <RB.Col md={8}>
            <h5 className="summary_text">
              <b>Executive Summary</b>
            </h5>
          </RB.Col>
          <RB.Col md={4}></RB.Col>
        </RB.Row>
        <RB.Row>
          <RB.Card className="ind_summary zero_border_radius">
            <RB.Card.Body className="font_13">
              {props.health.f_anomalies.map((item) => {
                if (item["executive_summary"] != "") {
                  return (
                    <RB.Row>
                      <RB.Col md={1} className="zero_padding">
                        <FontAwesomeIcon
                          className="summary_icon"
                          icon={faLongArrowRight}
                        />
                      </RB.Col>
                      <RB.Col md={9} className="zero_padding">
                        {item["executive_summary"]}
                      </RB.Col>
                    </RB.Row>
                  );
                }
              })}
            </RB.Card.Body>
          </RB.Card>
        </RB.Row>
      </RB.Col>
    </RB.Row>
  );
};

export default Default_Function;
