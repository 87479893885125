import * as RB from "react-bootstrap";
import Flow_chart from "./flow_chart_img_v2.png";
import "./style.css";

const Default_Function = () => {
  return (
    <>
      <RB.Row>
        <h3>
          <b>Connected Optimization</b>
        </h3>
      </RB.Row>
      <RB.Row>
        {/* <RB.Col md={12} sm={12} className="no_r_padding"> */}
        <img src={Flow_chart} alt={Flow_chart} />
        {/* </RB.Col> */}
      </RB.Row>
    </>
  );
};

export default Default_Function;
