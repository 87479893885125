import * as RB from "react-bootstrap";
import { useState } from "react";
import HealthBox from "./health_box";
import MultiBox from "./multibox";
import Summary from "./summary";
import WhatHappen from "./what_happen";
import OnlyWhatHappen from "./only_what_happen";
import TabuleaView from "../../DashboardViews/TABLEAU";
import POWERBI from "../../DashboardViews/POWERBI";

import "./style.css";

const Default_Function = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");
  let [d_v_m_thisweek, set_d_v_m_thisweek] = useState(1);

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];
  let health_box = {
    this_week: [
      {
        name: "OSA",
        value: 77.21,
        value_suffix: "%",
        sub_value: 15.61,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "Sales",
        value: 30.02,
        value_suffix: "M",
        sub_value: 17.21,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Profits",
        value: 9,
        value_suffix: "M",
        sub_value: 15.38,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "Perfect Supply Rate",
        value: 49.58,
        value_suffix: "%",
        sub_value: 4.11,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "Fulfilment Rate",
        value: 68.34,
        value_suffix: "%",
        sub_value: 3.52,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
      {
        name: "On Time Rate",
        value: 68.98,
        value_suffix: "%",
        sub_value: 2.41,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
    ],
    next_week: [
      {
        name: "OSA",
        value: 71,
        value_suffix: "%",
        sub_value: 14.21,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "Sales",
        value: 28.22,
        value_suffix: "M",
        sub_value: 16.22,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Profits",
        value: 8.46,
        value_suffix: "M",
        sub_value: 16.3,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "Perfect Supply Rate",
        value: 45.18,
        value_suffix: "%",
        sub_value: 8.87,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "FulFilment Rate",
        value: 65.22,
        value_suffix: "%",
        sub_value: 4.56,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
      {
        name: "On Time Rate",
        value: 66.71,
        value_suffix: "%",
        sub_value: 3.21,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
    ],
  };

  let multi_box = {
    this_week: [
      {
        header: "Average Lead Time",
        value: 43,
        value_prefix: "",
        value_suffix: " Days",
        sub_value: 8.01,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
      {
        header: "Delayed Inv. Val",
        value: 18.25,
        value_prefix: "$",
        value_suffix: "M",
        sub_value: 8.9,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },

      {
        header: "Delayed ASNS",
        value: 197,
        value_prefix: "",
        value_suffix: "",
        sub_value: 22,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
      {
        header: "Delayed Suppliers",
        value: 18,
        value_prefix: "",
        value_suffix: "",
        sub_value: 15.78,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
      {
        header: "Average Delay",
        value: 18,
        value_prefix: "",
        value_suffix: " Days",
        sub_value: 14.7,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
    ],
    next_week: [
      {
        header: "Average Lead Time",
        value: 43,
        value_prefix: "",
        value_suffix: " Days",
        sub_value: 8.01,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
      {
        header: "Delayed Inv. Val",
        value: 18.25,
        value_prefix: "$",
        value_suffix: "M",
        sub_value: 8.9,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },

      {
        header: "Delayed ASNS",
        value: 197,
        value_prefix: "",
        value_suffix: "",
        sub_value: 22,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
      {
        header: "Delayed Suppliers",
        value: 18,
        value_prefix: "",
        value_suffix: "",
        sub_value: 15.78,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
      {
        header: "Average Delay",
        value: 18,
        value_prefix: "",
        value_suffix: " Days",
        sub_value: 14.7,
        sub_value_prefix: "(+",
        sub_value_suffix: "%)",
      },
    ],
  };

  let summary = {
    this_week: [
      {
        text: "The OSA level for this week is 15.61% higher than the same week last year",
        mode: "",
      },
      {
        text: "This improves sales by 17.21% compared to the same week last year",
        mode: "",
      },
      {
        text: "Supply sources DC 10 and Supplier 16 have the highest delayed inventory for the entire cluster",
        mode: "",
      },
      {
        text: "For the supply source DC 10, Winix 4 and Dyson V10 are the top 2 products in terms of potential lost profit with values of $440,000 and $390,000 for next cycle.",
        mode: "",
      },
    ],
    next_week: [
      {
        text: "The OSA level for next week is 14.21% higher than the same week last year.",
        mode: "",
      },
      {
        text: "This is likely to improve sales by 16.22%",
        mode: "",
      },
      {
        text: "Supply sources DC 10 and Supplier 16 have the highest delayed inventory for the entire cluster.",
        mode: "",
      },
      {
        text: "For the supply source DC10, Winix 4 and Dyson V10 are the top 2 products in terms of potential lost profit with values of $440,000 and $390,000 for next cycle.",
        mode: "",
      },
    ],
  };

  let what_happen = {
    this_week: {
      what_happen: [
        {
          text: "Supply sources DC 10 and Supplier 16 have the highest delayed inventory for the entire cluster.",
          mode: "dec",
        },
      ],
      what_did_happen: [],
    },
    next_week: {
      what_happen: [
        {
          text: "Supply sources DC 10 and Supplier 16 have the highest delayed inventory for the entire cluster.",
          mode: "dec",
        },
      ],
      what_did_happen: [],
    },
  };

  let what_happen01 = {
    this_week: {
      what_happen: [
        {
          text: "For the supply source DC10, Winix 4 and Dyson V10 are the top 2 products in terms of potential lost profit with values of $440,000 and $390,000 for next cycle.",
          mode: "dec",
        },
      ],
      what_did_happen: [],
    },
    next_week: {
      what_happen: [
        {
          text: "For the supply source DC10, Winix 4 and Dyson V10 are the top 2 products in terms of potential lost profit with values of $440,000 and $390,000 for next cycle.",
          mode: "dec",
        },
      ],
      what_did_happen: [],
    },
  };

  let what_happen02 = {
    this_week: {
      what_happen: [
        {
          text: "Supply source DC10 is likely to have a maximum delays at the shipping and delivery stage, with 39/84 delayed orders, and a value of $560K",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "39/84 orders are likely to be delayed at the shipping and delivery stage, for an average of 6 days because of the lack of availability of the required mode of transport from the shipping partners",
          mode: "dec",
        },
        {
          text: "Improve booking capacity with shipping partners X1, X23 and X4 for 12 orders, to minimize average delay at this stage by 3 days, and reduce delayed inventory by $175k",
          mode: "inc",
        },
      ],
    },
    next_week: {
      what_happen: [
        {
          text: "Supply source DC10 is likely to have a maximum delays at the shipping and delivery stage, with 39/84 delayed orders, and a value of $560K",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "39/84 orders are likely to be delayed at the shipping and delivery stage, for an average of 6 days because of the lack of availability of the required mode of transport from the shipping partners",
          mode: "dec",
        },
        {
          text: "Improve booking capacity with shipping partners X1, X23 and X4 for 12 orders, to minimize average delay at this stage by 3 days, and reduce delayed inventory by $175k",
          mode: "inc",
        },
      ],
    },
  };

  return (
    <>
      <RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => set_sel_com_view(e.currentTarget.value)}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>
        <RB.Row> &nbsp;</RB.Row>
        {sel_com_view === "this_week" ? (
          <>
            <RB.Row>
              <HealthBox data={health_box["this_week"]} />
            </RB.Row>
            <RB.Row>
              <h5 className="summary_text">
                <b>Executive Summary</b>
              </h5>
            </RB.Row>
            <RB.Row>
              <Summary data={summary["this_week"]} />
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <MultiBox data={multi_box["this_week"]} />
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="si_view_card zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"125vh"}
                      URL={
                        "https://public.tableau.com/views/SupplierRisks-Updated30dec/SupplierRisks-TW?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiNDVlNjg2YzAtMDRlMC00MDMxLTljYjctYzBjMjIyZTE1MTg4IiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={900}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <OnlyWhatHappen data={what_happen["this_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="si_what_if_view_card zero_padding zero_border_radius ">
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"135vh"}
                      URL={
                        "https://public.tableau.com/views/DC10_new-Updated30dec/DC10-NW?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiNmFjOWRiYTUtZjE1NS00ZTIyLWIwZDAtMjAxMTM1MGM2MzkyIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1000}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Row>
                <RB.Card className="zero_padding zero_border_radius ">
                  <RB.Card.Body>
                    <OnlyWhatHappen data={what_happen01["this_week"]} />
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>

              <RB.Row>&nbsp;</RB.Row>
              <RB.Row>
                <RB.Card className="what_if_view_card_35 zero_padding zero_border_radius ">
                  <RB.Card.Body className="card_body_view">
                    {d_v_m_thisweek === 1 ? (
                      <TabuleaView
                        WIDTH={"100%"}
                        HEIGHT={"40vh"}
                        URL={
                          "https://public.tableau.com/views/o2d_1/OrdertoDeliver-TW?:language=en-US&:display_count=n&:origin=viz_share_link"
                        }
                      />
                    ) : (
                      <POWERBI
                        URL={
                          "https://app.powerbi.com/view?r=eyJrIjoiOWVjOTRkNTMtYzkzYy00ODc5LWFiYzMtNzljNjZlNmYwNjRjIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                        }
                        height={300}
                      />
                    )}
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>

              <RB.Row>&nbsp;</RB.Row>
              <RB.Row>
                <RB.Card className="zero_padding zero_border_radius ">
                  <RB.Card.Body className="card_body_view">
                    {d_v_m_thisweek === 1 ? (
                      <TabuleaView
                        WIDTH={"100%"}
                        HEIGHT={"90vh"}
                        URL={
                          "https://public.tableau.com/views/o2d_2/OrdertoDeliver-TW?:language=en-US&:display_count=n&:origin=viz_share_link"
                        }
                      />
                    ) : (
                      <POWERBI
                        URL={
                          "https://app.powerbi.com/view?r=eyJrIjoiYTE4NmM3ODMtNWJjOS00ZDcxLWJhMGEtY2ZkZTU2OWVlMjVmIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                        }
                        height={900}
                      />
                    )}
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>

              <RB.Row>
                <RB.Card className="zero_padding zero_border_radius ">
                  <RB.Card.Body>
                    <WhatHappen data={what_happen02["this_week"]} />
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
        {sel_com_view === "next_week" ? (
          <>
            <RB.Row>
              <HealthBox data={health_box["next_week"]} />
            </RB.Row>
            <RB.Row>
              <h5 className="summary_text">
                <b>Executive Summary</b>
              </h5>
            </RB.Row>
            <RB.Row>
              <Summary data={summary["next_week"]} />
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <MultiBox data={multi_box["next_week"]} />
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="si_view_card_35 zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"125vh"}
                      URL={
                        "https://public.tableau.com/views/SupplierRisks-Updated30dec/SupplierRisks-TW?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiYmZlMjMwMDItNTFjNi00ZThlLWFkNmYtYjA1YzdmYWFmMTA1IiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1000}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <OnlyWhatHappen data={what_happen["next_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="what_if_view_card zero_padding zero_border_radius ">
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"125vh"}
                      URL={
                        "https://public.tableau.com/views/DC10_new-Updated30dec/DC10-NW?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiZjU5MjFhZDUtNzI4OS00YTcwLThlNjgtMDdlMzFhOTU3ZWMwIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1000}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Row>
                <RB.Card className="zero_padding zero_border_radius ">
                  <RB.Card.Body>
                    <OnlyWhatHappen data={what_happen01["next_week"]} />
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>

              <RB.Row>&nbsp;</RB.Row>
              <RB.Row>
                <RB.Card className="what_if_view_card_35 zero_padding zero_border_radius ">
                  <RB.Card.Body className="card_body_view">
                    {d_v_m_thisweek === 1 ? (
                      <TabuleaView
                        WIDTH={"100%"}
                        HEIGHT={"90vh"}
                        URL={
                          "https://public.tableau.com/views/o2d_1/OrdertoDeliver-TW?:language=en-US&:display_count=n&:origin=viz_share_link"
                        }
                      />
                    ) : (
                      <POWERBI
                        URL={
                          "https://app.powerbi.com/view?r=eyJrIjoiYTliYTczNTgtODcxNy00MjBiLWFiY2MtNTgxZjU3YWQzOGVhIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                        }
                        height={1000}
                      />
                    )}
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>

              <RB.Row>&nbsp;</RB.Row>
              <RB.Row>
                <RB.Card className="what_if_view_card_73 zero_padding zero_border_radius ">
                  <RB.Card.Body className="card_body_view">
                    {d_v_m_thisweek === 1 ? (
                      <TabuleaView
                        WIDTH={"100%"}
                        HEIGHT={"90vh"}
                        URL={
                          "https://public.tableau.com/views/o2d_2/OrdertoDeliver-TW?:language=en-US&:display_count=n&:origin=viz_share_link"
                        }
                      />
                    ) : (
                      <POWERBI
                        URL={
                          "https://app.powerbi.com/view?r=eyJrIjoiYTVkNWYwZGQtYzczMC00MzJlLWEyYmEtMWYxNDY0YjllY2MwIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                        }
                        height={1000}
                      />
                    )}
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>

              <RB.Row>
                <RB.Card className="zero_padding zero_border_radius ">
                  <RB.Card.Body>
                    <WhatHappen data={what_happen02["next_week"]} />
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
      </RB.Row>
    </>
  );
};

export default Default_Function;
