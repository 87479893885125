import React, { useRef, useEffect } from "react"
const { tableau } = window;

const TableauEmbed = (props) => {
  const ref = useRef(null);
  const url = props.URL

  const initViz = () => {
    new tableau.Viz(ref.current, url, {
      width: props.WIDTH,
      height: props.HEIGHT,
    });
  };

  useEffect(initViz, []);

  return (
    <div className="tableau">
      <div ref={ref} />
    </div>
  );
};

export default TableauEmbed;
