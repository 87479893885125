import * as RB from "react-bootstrap";
import {
  faArrowCircleDown,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

const Default_Function = (props) => {
  return (
    <RB.Row>
      <RB.Col md={6}>
        <RB.Card className="whathappen_card zero_padding zero_border_radius ">
          <RB.Card.Header className="zero_border_radius card_header_blue">
            What happened this week ?
          </RB.Card.Header>
          <RB.Card.Body className="">
            {props.data["what_happen"].map((item, index) => (
              <RB.Card
                className={`${
                  item["mode"] === "inc"
                    ? "zero_border_radius what_happen_list_green"
                    : "zero_border_radius what_happen_list_pink"
                }`}
              >
                <RB.Row>
                  <RB.Col md={1}>
                    {item["mode"] === "inc" ? (
                      <FontAwesomeIcon
                        className="whathappen_icon_green"
                        icon={faArrowCircleUp}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="whathappen_icon_pink"
                        icon={faArrowCircleDown}
                      />
                    )}
                  </RB.Col>
                  <RB.Col md={11}>{item["text"]}</RB.Col>
                </RB.Row>
              </RB.Card>
            ))}
          </RB.Card.Body>
        </RB.Card>
      </RB.Col>
      <RB.Col md={6}>
        <RB.Card className="whathappen_card zero_padding zero_border_radius ">
          <RB.Card.Header className="zero_border_radius card_header_blue">
            Why did it happen ?
          </RB.Card.Header>
          <RB.Card.Body className="">
            {props.data["what_did_happen"].map((item, index) => (
              <RB.Card
                className={`${
                  item["mode"] === "inc"
                    ? "zero_border_radius what_happen_list_green"
                    : "zero_border_radius what_happen_list_pink"
                }`}
              >
                <RB.Row>
                  <RB.Col md={1}>
                    {item["mode"] === "inc" ? (
                      <FontAwesomeIcon
                        className="whathappen_icon_green"
                        icon={faArrowCircleUp}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="whathappen_icon_pink"
                        icon={faArrowCircleDown}
                      />
                    )}
                  </RB.Col>
                  <RB.Col md={11}>{item["text"]}</RB.Col>
                </RB.Row>
              </RB.Card>
            ))}
          </RB.Card.Body>
        </RB.Card>
      </RB.Col>
    </RB.Row>
  );
};

export default Default_Function;
