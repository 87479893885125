import { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import Header from "./Header";
import Footer from "../Footer";
import SideBar from "./Sidebar";
import Landing from "./landing";
import Accelerator from "./accelerator";

import InboundAnalytics from "./Inbound_Analytics";

import AccociativeForecasting from "./Associative_forecasting";
import O2D from "./o2d_Analytics";
import ProfitEchelon from "./Profit_Echelon";
import OSA from "./OSA";
import LongTail from "./Long_Tail";

import SupplyIntelligence from "./Supply_Intelligence"; //yet to work

import Fishbone from "./Fishbone";

import WhatIf from "./What_If";
import Outbound from "./Outbound";
import InventoryAging from "./Inventory_aging";
import InventoryLongtail from "./Inventory_longtail";

import sidBarSrc from "./data_hub/side_bar.json";
import "./style.css";

const Default_Function = () => {
  const [view_mode, set_view_mode] = useState("home");
  const [tools_acc_view_mode, set_tools_acc_view_mode] = useState("");
  const [side_bar_navs, set_side_bar_navs] = useState([]);

  let side_bar_data = sidBarSrc;

  let change_home_view_mode = (mode) => {
    set_view_mode(mode);
    set_tools_acc_view_mode("");
  };

  let change_tools_with_dashboard = (mode, acc_view) => {
    set_view_mode(mode);
    set_tools_acc_view_mode(acc_view);
    set_side_bar_navs([...side_bar_data["home"], ...side_bar_data[acc_view]]);
  };

  let change_tools_with_dashboard_from_sidebar = (selected_side_bar) => {
    set_tools_acc_view_mode(selected_side_bar["mode"]);
    set_side_bar_navs([
      ...side_bar_data["home"],
      ...side_bar_data[selected_side_bar["side_bar_mode"]],
    ]);
  };

  let mode_from_side_bar = (side_bar_mode) => {
    switch (side_bar_mode["mode"]) {
      case "home":
        change_home_view_mode(side_bar_mode["mode"]);
        set_side_bar_navs(side_bar_data["home"]);
        break;
      case "tools_acc":
        change_home_view_mode("tools_acc");
        set_tools_acc_view_mode("");
        set_side_bar_navs(side_bar_data["home"]);
        break;
      default:
        change_tools_with_dashboard_from_sidebar(side_bar_mode);
    }
  };

  useEffect(() => {
    set_side_bar_navs(side_bar_data["home"]);
  }, []);

  return (
    <>
      <Header></Header>
      <SideBar
        change_home_view_mode={change_home_view_mode}
        mode_from_side_bar={mode_from_side_bar}
        tools_acc_view_mode={tools_acc_view_mode}
        side_bar_navs={side_bar_navs}
      ></SideBar>
      <RB.Row className="retail_landing_content">
        {view_mode === "home" ? (
          <Landing
            landing={{
              change_tools_with_dashboard: change_tools_with_dashboard,
              change_home_view_mode: change_home_view_mode,
            }}
          />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" && tools_acc_view_mode === "" ? (
          <Accelerator
            change_tools_with_dashboard={change_tools_with_dashboard}
            tools_acc_view_mode={tools_acc_view_mode}
          />
        ) : (
          <></>
        )}

        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "inbound_analytics" ? (
          <InboundAnalytics />
        ) : (
          <></>
        )}

        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "associative_forecasting" ? (
          <AccociativeForecasting />
        ) : (
          <></>
        )}

        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "o2d_analytics" ? (
          <O2D />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "echelon_sense" ? (
          <ProfitEchelon />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "optimal_optimizer" ? (
          <OSA />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" && tools_acc_view_mode === "long_tail" ? (
          <LongTail />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "supply_intelligence" ? (
          <SupplyIntelligence />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" && tools_acc_view_mode === "fishbone" ? (
          <Fishbone />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" && tools_acc_view_mode === "what_if" ? (
          <WhatIf />
        ) : (
          <></>
        )}

        {view_mode === "tools_acc" && tools_acc_view_mode === "outbound" ? (
          <Outbound />
        ) : (
          <></>
        )}

        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "inventory_aging" ? (
          <InventoryAging />
        ) : (
          <></>
        )}
        {view_mode === "tools_acc" &&
        tools_acc_view_mode === "inventory_longtail" ? (
          <InventoryLongtail />
        ) : (
          <></>
        )}
      </RB.Row>
      <Footer></Footer>
    </>
  );
};

export default Default_Function;
