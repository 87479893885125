import * as RB from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faStar,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const Default_Function = (props) => {
  const [f_anamolie, set_f_anamolie] = useState([]);
  const [active_index, set_active_index] = useState(0);

  let pick_anomalie = (data, a_index) => {
    set_f_anamolie(data);
    set_active_index(a_index);
  };

  useEffect(() => {
    pick_anomalie(props.f_anomalies[0], 0);
  }, []);

  return (
    <>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <RB.Col md={6}>
          {props.f_anomalies.map((value, index) => {
            if (value["anomolies"] !== "") {
              return (
                <RB.Card
                  className={
                    index === active_index
                      ? "zero_border_radius m_b10 active"
                      : "zero_border_radius m_b10"
                  }
                  onClick={() => {
                    pick_anomalie(value,index);
                  }}
                >
                  <RB.Card.Body className="ind_anomalies_list">
                    <RB.Row>
                      <RB.Col md={11}>{value["anomolies"]}</RB.Col>
                      <RB.Col md={1}>
                        <FontAwesomeIcon className="ind_arow_icon" icon={faChevronRight} />
                      </RB.Col>
                    </RB.Row>
                  </RB.Card.Body>
                </RB.Card>
              );
            }
          })}
        </RB.Col>
        <RB.Col md={6}>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>
            <RB.Row>
              <h5 className="ind_rec_header">Reasons</h5>
            </RB.Row>
            <RB.Row className="ind_res_text">
              <RB.Col md={12}>{f_anamolie["reasons"]}</RB.Col>
            </RB.Row>
          </RB.Row>

          <RB.Row>
            <RB.Card className="zero_border_radius m_b10">
              <RB.Card.Body className="ind_anomalies_list">
                <RB.Row>
                  <h5 className="ind_rec_header">Recommendation</h5>
                </RB.Row>
                {props.f_anomalies.map((value) => {
                  return (
                    <RB.Row>
                      <RB.Col md={1}>
                        <FontAwesomeIcon className="ind_arow_icon" icon={faChevronRight} />
                      </RB.Col>
                      <RB.Col md={11} className="ind_rec_text">
                        {value["anomolies"]}
                      </RB.Col>
                    </RB.Row>
                  );
                })}
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
