import { useState } from "react";
import POE1 from "../data_hub/poe/po1.json";
import POE2 from "../data_hub/poe/po2.json";
import POE3 from "../data_hub/poe/po3.json";
import "./style.css";

const POEData = () => {
  const [active, setActive] = useState(false);
  const btnClick = (event) => {
    event.preventDefault();
    setActive(event.target.id);
    setActive((active) => !active);
  };

  // const [showData, setShowData] = useState({
  //     isAgree: false,
  //     gender: 'Default'
  // });

  // const btnClick = (event) => {
  //     const target = event.target
  //     const name = target.name
  //     const value = target.value
  //     setShowData({
  //         ...showData,
  //         [name]: value

  //     })
  // }

  const [hide, setHide] = useState(true);
  const [skuDataHide, setSkuDataHide] = useState(false);
  const [optimizeHide, setOptimizeHide] = useState(false);

  const [defaultDataItems] = useState(POE1);
  const [defaultData, setDefaultData] = useState(false);
  const DefaultClick = () => {
    setDefaultData(true);
    setOptimizeData(false);
    setSkuData(false);
    setHide(false);
    setSkuDataHide(false);
    setOptimizeHide(false);
  };

  const [optimizeItems] = useState(POE2);
  const [optimizeData, setOptimizeData] = useState(false);
  const OptimizeClick = () => {
    setOptimizeData(true);
    setDefaultData(false);
    setSkuData(false);
    setHide(false);
    setSkuDataHide(true);
    setOptimizeHide(false);
  };

  const [skuDataItems] = useState(POE3);
  const [skuData, setSkuData] = useState(false);
  const SkuClick = () => {
    setSkuData(true);
    setOptimizeData(false);
    setDefaultData(false);
    setHide(false);
    setSkuDataHide(true);
    setOptimizeHide(true);
  };

  const formatter = new Intl.NumberFormat("en", {
    style: "decimal",
    signDisplay: "never",
    useGrouping: true,
    notation: "compact",
  });

  //radio
  const [radioValue, setRadiovalue] = useState("");
  useState(() => {
    setRadiovalue("pick"); // <-- Assume this value is taken from database, value may be either "delivery" or "pick"
  }, []);

  const changeSelection = (e) => {
    setRadiovalue(e.target.value);
    setRadiovalue("pick");
  };

  return (
    <div className="poe-data-wrapper">
      <div className="head">
        <h3>Profit Optimization Engine</h3>
        <h6>*As of 15th of every month/ middle of the inventory cycle</h6>
      </div>

      <div className="heading">
        <div>
          <div>
            {" "}
            <span>Region</span> <span>North</span>
          </div>
          <div>
            {" "}
            <span>Depot</span> <span>951</span>
          </div>
          <div>
            {" "}
            <span>Category</span> <span>MASSAGE CHAIRS</span>
          </div>
        </div>
        {/* <div> <span>Historical From Date:</span><span>'2021-01-01'</span></div>
                <div> <span>To Date:</span><span>'2021-05-31'</span></div>
                <div> <span>Forecast From Date:</span><span>'2021-06-01'</span></div>
                <div> <span>To Date:</span> <span>'2021-06-30'</span></div> */}

        {skuDataHide ? (
          <div>
            {" "}
            <span>Reallocate bottom SKU %</span> <span>10%</span>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="button-wrapper">
        <div className="left-buttons">
          <span>
            Populate Budget:{" "}
            <input className="poe_button" type="text" value="6M" />
          </span>

          {skuDataHide ? (
            <span>
              Total Profit:{" "}
              <input className="poe_button" type="text" value="1.07M" />
            </span>
          ) : (
            ""
          )}

          {optimizeHide ? (
            <span>
              New profit:{" "}
              <input className="poe_button" type="text" value="1.11M" />
            </span>
          ) : (
            ""
          )}

          <div className="radio-btns">
            <input
              type="radio"
              id="delivery"
              name="orderType"
              value="delivery"
              required
              onChange={changeSelection}
              checked
            />
            <label className="radio_label" htmlFor="delivery">Minimize risk</label>

            <input
              type="radio"
              id="pick"
              name="orderType"
              value="pick"
              onChange={changeSelection}
            />
            <label className="radio_label" htmlFor="pick">Maximise profit</label>
          </div>
        </div>

        <div className="right-buttons">
          <button
            onClick={() => [DefaultClick(), btnClick()]}
            className={active ? "poe_active" : "poe_deactive"}
          >
            Default
          </button>
          <button
            onClick={() => [OptimizeClick(), btnClick()]}
            className={active ? "poe_active" : "poe_deactive"}
          >
            Optimize
          </button>

          {skuDataHide ? (
            <button
              onClick={() => [SkuClick(), btnClick()]}
              className={active ? "poe_active" : "poe_deactive"}
            >
              Reallocate SKU's
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Default data visible initially */}
      {hide ? (
        <table className="long_tail_table">
          <thead className="long_tail_thead">
            <tr>
              <th className="long_tail_th">S.No</th>
              <th className="long_tail_th">SKU</th>
              <th className="long_tail_th">Profit Selected Time Period</th>
              <th className="long_tail_th">Forecast</th>
              <th className="long_tail_th">Safety Stock</th>
              <th className="long_tail_th">Available Inventory</th>
              <th className="long_tail_th">Min Level</th>
              <th className="long_tail_th">Max Level</th>
              <th className="long_tail_th">Total Cost</th>
              <th className="long_tail_th">Total Profits</th>
            </tr>
          </thead>

          {defaultDataItems &&
            defaultDataItems.map((item, val) => (
              <tbody className="long_tail_tbody">
                <tr className="long_tail_tr item" key={val}>
                  <td className="long_tail_td">{val + 1}</td>
                  <td className="long_tail_td">{item.SKU}</td>
                  <td className="long_tail_td">
                    ${formatter.format(item.Profit_Selected_TimePeriod)}
                  </td>
                  <td className="long_tail_td">{item.Forecast}</td>
                  <td className="long_tail_td">{item.Safety_Stock}</td>
                  <td className="long_tail_td">{item.Available_Inventory}</td>
                  <td className="long_tail_td">{item.Min_Level}</td>
                  <td className="long_tail_td">{item.Max_Level}</td>
                  <td className="long_tail_td">
                    ${formatter.format(item.Total_Cost)}
                  </td>
                  <td className="long_tail_td">
                    ${formatter.format(item.Total_Profit)}
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      ) : (
        ""
      )}

      {/* Default 01 tab */}
      {defaultData ? (
        <table className="long_tail_table">
          <thead className="long_tail_thead">
            <tr>
              <th className="long_tail_th">S.No</th>
              <th className="long_tail_th">SKU</th>
              <th className="long_tail_th">Profit Selected Time Period</th>
              <th className="long_tail_th">Forecast</th>
              <th className="long_tail_th">Safety Stock</th>
              <th className="long_tail_th">Available Inventory</th>
              <th className="long_tail_th">Min Level</th>
              <th className="long_tail_th">Max Level</th>
              <th className="long_tail_th">Total Cost</th>
              <th className="long_tail_th">Total Profits</th>
            </tr>
          </thead>

          {defaultDataItems &&
            defaultDataItems.map((item, val) => (
              <tbody className="long_tail_tbody">
                <tr className="long_tail_tr item" key={val}>
                  <td className="long_tail_td">{val + 1}</td>
                  <td className="long_tail_td">{item.SKU}</td>
                  <td className="long_tail_td">${formatter.format(item.Profit_Selected_TimePeriod)}</td>

                  <td className="long_tail_td">{item.Forecast}</td>
                  <td className="long_tail_td">{item.Safety_Stock}</td>
                  <td className="long_tail_td">{item.Available_Inventory}</td>
                  <td className="long_tail_td">{item.Min_Level}</td>
                  <td className="long_tail_td">{item.Max_Level}</td>
                  <td className="long_tail_td">${formatter.format(item.Total_Cost)}</td>
                  <td className="long_tail_td">${formatter.format(item.Total_Profit)}</td>
                </tr>
              </tbody>
            ))}
        </table>
      ) : (
        ""
      )}

      {/* optimiza 02 tab */}
      {optimizeData ? (
        <table className="long_tail_table">
          <thead className="long_tail_thead">
            <tr>
              <th className="long_tail_th">S.No</th>
              <th className="long_tail_th">SKU</th>
              <th className="long_tail_th">Profit Selected Time Period</th>
              <th className="long_tail_th">Forecast</th>
              <th className="long_tail_th">Safety Stock</th>
              <th className="long_tail_th">Available Inventory</th>
              <th className="long_tail_th">Min Levels</th>
              <th className="long_tail_th">Max Levels</th>
              <th className="long_tail_th">Total Cost </th>
              <th className="long_tail_th">Total Profit</th>
              <th className="long_tail_th">Optimal Units to Purchase</th>
              <th className="long_tail_th">Total quantity after optimal reorder</th>
              <th className="long_tail_th">Profit for Optimal Reorder</th>
            </tr>
          </thead>

          {optimizeItems &&
            optimizeItems.map((opt, vl) => (
              <tbody className="long_tail_tbody">
                <tr className="long_tail_tr item" key={vl}>
                  <td className="long_tail_td">{vl + 1}</td>
                  <td className="long_tail_td">{opt.SKU}</td>
                  <td className="long_tail_td">${formatter.format(opt.Profit_Selected_TimePeriod)}</td>

                  <td className="long_tail_td">{opt.Forecast}</td>
                  <td className="long_tail_td">{opt.Safety_Stock}</td>
                  <td className="long_tail_td">{opt.Available_Inventory}</td>
                  <td className="long_tail_td">{opt.Min_Levels}</td>
                  <td className="long_tail_td">{opt.Max_Levels}</td>
                  <td className="long_tail_td">${formatter.format(opt.Total_Cost)}</td>
                  <td className="long_tail_td">${formatter.format(opt.Total_Profit)}</td>

                  <td className="long_tail_td">{opt.Optimal_units_to_purchase}</td>
                  <td className="long_tail_td">{opt.Total_quantity_after_optimal_reorder}</td>
                  <td className="long_tail_td">${formatter.format(opt.Profit_for_optimal_reorder)}</td>
                </tr>
              </tbody>
            ))}
        </table>
      ) : (
        ""
      )}

      {/* Reallocate 03 tab */}
      {skuData ? (
        <table className="long_tail_table">
          <thead className="long_tail_thead">
            <tr>
              <th className="long_tail_th">S.No</th>
              <th className="long_tail_th">SKU</th>
              <th className="long_tail_th">Profit Selected Time Period</th>
              <th className="long_tail_th">Forecast</th>
              <th className="long_tail_th">Safety Stock</th>
              <th className="long_tail_th">Available Inventory</th>
              <th className="long_tail_th">Max Levels</th>
              <th className="long_tail_th">Min Levels</th>
              <th className="long_tail_th">Total Cost </th>
              <th className="long_tail_th">Total Profit</th>
              <th className="long_tail_th">Optimal Units to Purchase</th>
              <th className="long_tail_th">Total Quantity after Optimal Reorder</th>
              <th className="long_tail_th">Profit for Optimal Reorder</th>
            </tr>
          </thead>

          {skuDataItems &&
            skuDataItems.map((list, cl) => (
              <tbody className="long_tail_tbody">
                <tr className="long_tail_tr item" key={cl}>
                  <td className="long_tail_td">{cl + 1}</td>
                  <td className="long_tail_td">{list.SKU}</td>
                  <td className="long_tail_td">${formatter.format(list.Profit_Selected_TimePeriod)}</td>

                  <td className="long_tail_td">{list.Forecast}</td>
                  <td className="long_tail_td">{list.Safety_Stock}</td>
                  <td className="long_tail_td">{list.Available_Inventory}</td>
                  <td className="long_tail_td">{list.Max_Levels}</td>
                  <td className="long_tail_td">{list.Min_Levels}</td>
                  <td className="long_tail_td">${formatter.format(list.Total_Cost)}</td>
                  <td className="long_tail_td">${formatter.format(list.Total_Profit)}</td>

                  <td className="long_tail_td">{list.Optimal_units_to_purchase}</td>
                  <td className="long_tail_td">{list.Total_quantity_after_optimal_reorder}</td>
                  <td className="long_tail_td">${formatter.format(list.Profit_for_optimal_reorder)}</td>
                </tr>
              </tbody>
            ))}
        </table>
      ) : (
        ""
      )}
    </div>
  );
};

export default POEData;
