import * as RB from "react-bootstrap";
import Icon01 from "../../../images/retail-images/icon-01.png";
import Icon02 from "../../../images/retail-images/icon-02.png";
import Icon03 from "../../../images/retail-images/icon-03.png";
import Icon04 from "../../../images/retail-images/icon-04.png";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row>
        <RB.Col md={12}>
          <RB.Row>
            <RB.Col md={3}>
              <RB.Card className="PE_health_p_card zero_padding zero_border_radius">
                <RB.Card.Body className="health_p_card_common">
                  <RB.Row>
                    <img
                      className="pe_heath_p_card_icon"
                      src={Icon01}
                      alt={Icon01}
                    />
                  </RB.Row>
                  <RB.Row>
                    <h3>{props.data["card"]["name"]}</h3>
                  </RB.Row>
                  <RB.Row>
                    <h5 className="pe_health_p_value">
                      {props.data["card"]["value"]}{" "}
                      {props.data["card"]["value_suffix"]}
                    </h5>
                  </RB.Row>
                  <RB.Row>
                    <h5 className="pe_health_p_subvalue">
                      {props.data["card"]["sub_value_prefix"]}{" "}
                      {props.data["card"]["sub_value"]}{" "}
                      {props.data["card"]["sub_value_suffix"]}
                    </h5>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={9}>
              <RB.Row className="PE_healh_repeat">
                {props.data.sub_card.map((d_item, index) => (
                  <RB.Card className="PE_health_card zero_border_radius">
                    <RB.Card.Body>
                      <RB.Row className="health_center">
                        <RB.Col md={5} className="zero_padding">
                          <h4 className="PE_health_card_text">
                            {d_item["name"]}
                          </h4>
                        </RB.Col>
                        <RB.Col md={5} className="zero_padding">
                          <RB.Row>
                            <h5 className="PE_health_card_value">
                              {d_item["value"]} {d_item["value_suffix"]}
                            </h5>
                          </RB.Row>
                          <RB.Row>
                            <h7 className="PE_health_card_sub_value">
                              {d_item["sub_value_prefix"]} {d_item["sub_value"]}{" "}
                              {d_item["sub_value_suffix"]}
                            </h7>
                          </RB.Row>
                        </RB.Col>
                        <RB.Col md={2} className="zero_padding">
                          {d_item["icon"] === "Icon01" ? (
                            <img
                              className="health_img"
                              src={Icon01}
                              alt={Icon01}
                            />
                          ) : (
                            <></>
                          )}
                          {d_item["icon"] === "Icon02" ? (
                            <img
                              className="PE_health_img"
                              src={Icon02}
                              alt={Icon02}
                            />
                          ) : (
                            <></>
                          )}
                          {d_item["icon"] === "Icon03" ? (
                            <img
                              className="PE_health_img"
                              src={Icon03}
                              alt={Icon03}
                            />
                          ) : (
                            <></>
                          )}
                          {d_item["icon"] === "Icon04" ? (
                            <img
                              className="PE_health_img"
                              src={Icon04}
                              alt={Icon04}
                            />
                          ) : (
                            <></>
                          )}
                        </RB.Col>
                      </RB.Row>
                    </RB.Card.Body>
                  </RB.Card>
                ))}
              </RB.Row>
            </RB.Col>
          </RB.Row>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
