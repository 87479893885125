import * as RB from "react-bootstrap";
import { NavLink } from "react-router-dom";
import TreeOsa from "../../../images/tree-osa.jpg";

import PoorFillRate from "../../../images/tree-poor-fill-rate.jpg";
import OrderToDeliveryAnalysis from "../../../images/tree-order-to-deliver-analysis.jpg";
import DeliverPromiseAccuracy from "../../../images/tree-deliver-promise-accuracy.jpg";
import DemandForecast from "../../../images/tree-demand-forecast-error.jpg";
import InventoryIssue from "../../../images/tree-inventory-issue.jpg";
import OrderProcessingDelay from "../../../images/tree-order-processing-delay.jpg";
import ProductionInefficiency from "../../../images/tree-production-inefficiency.jpg";
import SUpplyAvailability from "../../../images/tree-supply-availability.jpg";
import TransportEfficiency from "../../../images/tree-transport-inefficiency.jpg";
import WarehouseEfficiency from "../../../images/tree-warehouse-inefficiency.jpg";
import OOS from "../../../images/tree-oos.jpg";
import "./style.css";
import "./connected_optimization.css";

const Default_Function = (props) => {
  let change_in_mode = (box) => {
    let bi_source = [
      "on_shelf_avalability",
      "fill_rate",
      // "demand_forecasting",
      "production_inefficiency",
      "oos",
    ];

    let bi_multiple_source = ["demand_forecasting"];

    let tabulea_source = [
      "inventory_issues",
      "order_processing_delays",
      "supply_delivery_time",
    ];

    let component = ["on_shelf_avalability"];

    let iframe_links = {
      on_shelf_avalability:
        "https://app.powerbi.com/view?r=eyJrIjoiOTE0M2ZhYWQtNDk1NC00YjU5LTg2YjgtYWFkMmJlYThiMTM0IiwidCI6IjFjMmJlMTE2LWQyMDctNDJmMy05NTJiLWM2NjI4OTc0NTg3OSIsImMiOjZ9",
      fill_rate:
        "https://app.powerbi.com/view?r=eyJrIjoiZWRlMjgyNTMtYjkwOS00NGNjLThkNGUtMDg0ZjdhNjZmZTE4IiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9",
      demand_forecasting: [
        "https://app.powerbi.com/view?r=eyJrIjoiODlhZTQ3NTktMjdjYS00ZjRjLWJjYzQtNTY0ZDEzOWEwOTcyIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9",
        "https://demand-forecasing-app.azurewebsites.net/",
      ],
      production_inefficiency:
        "https://app.powerbi.com/view?r=eyJrIjoiZjE2NThhMzYtMGVlNS00YjY5LTk4NWQtZjZkODA5NDNjZTRhIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9",
      inventory_issues:
        "https://public.tableau.com/views/SBD_proposal3/InventoryRisks?:language=en-US&:display_count=n&:origin=viz_share_link",
      order_processing_delays:
        "https://public.tableau.com/views/SBD_proposal3/Order-to-Deliver?:language=en-US&:display_count=n&:origin=viz_share_link",
      oos: "https://app.powerbi.com/view?r=eyJrIjoiOGQ3MTNiM2UtOWI2Yi00YjQyLTlhNmEtOGY0NTRhMWY1MDQ5IiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9&pageName=ReportSectionc74811ad3b500b055645",
      supply_delivery_time:
        "https://public.tableau.com/views/SBD_proposal3/SupplierRisks?:language=en-US&:display_count=n&:origin=viz_share_link",
    };

    if (bi_source.includes(box)) {
      props.optimization.view_mode_from_child("iFrames_powerbi");
      props.optimization.i_framelink_from_child(iframe_links[box], box);
      return;
    }

    if (tabulea_source.includes(box)) {
      props.optimization.view_mode_from_child("iFrames_tabulea");
      props.optimization.i_framelink_from_child(iframe_links[box], box);
      return;
    }

    if (bi_multiple_source.includes(box)) {
      props.optimization.i_framelink_from_child(iframe_links[box], box);
      props.optimization.view_mode_from_child("demand_forecasting");
      return;
    }

    if (component.includes(box)) {
      props.optimization.view_mode_from_child(box);
      // props.i_framelink_from_child(box);
    }
  };
  return (
    <>
      <RB.Row>
        <h3>
          <b>Connected Optimization</b>
        </h3>
      </RB.Row>
      <RB.Row>
        <div className="multidashboards-wrapper">
          <div className="tree-img-wrapper">
            <div className="tree-img">
              {/* Enabled */}
              <NavLink
                exact
                to="/cpg"
                onClick={() => {
                  change_in_mode("on_shelf_avalability");
                }}
                activeclassname="active"
              >
                <img src={TreeOsa} alt={TreeOsa} className="tree-osa" />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("fill_rate");
                }}
              >
                <img
                  src={PoorFillRate}
                  alt={PoorFillRate}
                  className="tree-poorfillrate"
                />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("demand_forecasting");
                }}
              >
                <img
                  src={DemandForecast}
                  alt={DemandForecast}
                  className="tree-demandforecast"
                />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("production_inefficiency");
                }}
              >
                <img
                  src={ProductionInefficiency}
                  alt={ProductionInefficiency}
                  className="tree-production"
                />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("inventory_issues");
                }}
              >
                <img
                  src={InventoryIssue}
                  alt={InventoryIssue}
                  className="tree-inventoryissue"
                />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("order_processing_delays");
                }}
              >
                <img
                  src={OrderProcessingDelay}
                  alt={OrderProcessingDelay}
                  className="tree-orderprocess"
                />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("oos");
                }}
              >
                <img src={OOS} alt={OOS} className="tree-oos" />
              </NavLink>

              <NavLink
                to="/cpg"
                onClick={() => {
                  change_in_mode("supply_delivery_time");
                }}
              >
                <span className="supply-delivery-time">
                  {" "}
                  Supply Delivery Time
                </span>
              </NavLink>

              {/* disabled */}
              <NavLink to="/cpg" className="disabled">
                <img
                  src={SUpplyAvailability}
                  alt={SUpplyAvailability}
                  className="tree-supply"
                />
              </NavLink>

              <NavLink to="/cpg" className="disabled">
                <img
                  src={TransportEfficiency}
                  alt={TransportEfficiency}
                  className="tree-transport"
                />
              </NavLink>

              <NavLink to="/cpg" className="disabled">
                <img
                  src={WarehouseEfficiency}
                  alt={WarehouseEfficiency}
                  className="tree-warehouse"
                />
              </NavLink>

              <NavLink to="/cpg" className="disabled">
                <img
                  src={OrderToDeliveryAnalysis}
                  alt={OrderToDeliveryAnalysis}
                  className="tree-delivery-analysis"
                />
              </NavLink>

              <NavLink to="/cpg" className="disabled">
                <img
                  src={DeliverPromiseAccuracy}
                  alt={DeliverPromiseAccuracy}
                  className="tree-deliver-promise"
                />
              </NavLink>
            </div>
          </div>
        </div>
      </RB.Row>
    </>
  );
};

export default Default_Function;
