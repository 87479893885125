import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import * as RB from "react-bootstrap";
import Switch from "react-switch";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import LineBar from "./line_bar_chart";
import ApiIcon from "@mui/icons-material/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillChatLeftFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import Dropdown from "react-dropdown";
import data_src from "./data.json";
import Graph_data_src from "./graph_data.json";

import "./style.css";
import "./dark.css";

const filter_modal_style = {
  content: {
    top: "0%",
    left: "70%",
    right: "0%",
    bottom: "0%",
  },
};

const chatbot_modal_style = {
  content: {
    top: "20%",
    left: "20%",
    right: "20%",
    bottom: "20%",
  },
};

Modal.setAppElement("#root");

const Default_Function = () => {
  const [actual_colour] = useState("#1c7ce8");
  const [predicted_colour] = useState("rgb(0, 136, 0)");
  const [border_colour] = useState("white");
  const [bar_chart] = useState("bar");
  const [line_chart] = useState("line");
  const [current_year] = useState(2023);

  const [theme, set_theme] = useState("light");

  let [graph_src_data, set_graph_src_data] = useState({});
  const [src_data] = useState(data_src);
  let [data_chart, set_data_chart] = useState(true);
  let [sel_dc, set_sel_dc] = useState([]);
  let [sel_city, set_sel_city] = useState([]);
  let [sel_store, set_sel_store] = useState([]);
  let [sel_brands, set_sel_brands] = useState([]);
  let [sel_products, set_sel_products] = useState([]);
  let [sel_year, set_sel_year] = useState([]);
  let [sel_horizon, set_sel_horizon] = useState([]);
  let [top_impact, set_top_impact] = useState([]);

  let [dc, set_dc] = useState({});
  let [city, set_city] = useState({});
  let [store, set_store] = useState({});
  let [brand, set_brand] = useState({});
  let [product, set_product] = useState({});
  let [year, set_year] = useState({});

  let [horizon, set_horizon] = useState({});

  let [graph_data, set_graph_data] = useState({});

  let [monthly_graph, set_monthly_graph] = useState({
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Auguest",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        type: "bar",
        label: "BAR",
        backgroundColor: "rgb(75, 192, 192)",
        data: [
          31151, 77012, 77057, 29954, 6172, 28292, 10338, 98726, 34490, 11215,
          25558, 51574,
        ],
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  });

  let [weekly_graph, set_weekly_graph] = useState({});
  let [sel_weekly_graph_filter, set_sel_weekly_graph_filter] = useState([]);
  let modify_theme = () => {
    if (theme === "light") {
      set_theme("dark");
    } else {
      set_theme("light");
    }
  };

  let data_as_d_obj = (data) => {
    return { value: data["name"], label: data["name"] };
  };

  let prepare_year_dropdown = (store_id, product_id, its_initial) => {
    let drop_down = [];
    for (let year of [2020, 2021, 2022, 2023]) {
      if (store_id + "_" + product_id + "_" + year in graph_src_data) {
        drop_down.push({ label: year, value: year });
      }
    }

    set_sel_year(drop_down);
    sel_year = drop_down;
    if (its_initial) {
      drop_down_modified("year", drop_down[drop_down.length - 1], its_initial);
    }
  };

  let prepare_horizon_dropdown = (its_initial) => {
    let drop_down = [
      { value: "0", label: "Select prediction" },
      { value: "1", label: "1'm prediction" },
      { value: "2", label: "2'm prediction" },
      { value: "3", label: "3'm prediction" },
    ];

    if (!graph_data["having_prediction"]) {
      drop_down = [{ value: "0", label: "No prediction" }];
    }

    set_sel_horizon(drop_down);
    sel_horizon = drop_down;
    if (its_initial) {
      drop_down_modified("horizon", drop_down[0], its_initial);

      // drop_down_modified("horizon", drop_down[drop_down.length -1], its_initial);
    }
  };

  let prepare_dropdown = (d_type, src, fil, its_initial) => {
    let d = [];

    // {value: 'CARRIZO SPRINGS', label: 'CARRIZO SPRINGS'}
    for (let v of src) {
      let m_d = v;
      let need_append = true;
      switch (d_type) {
        case "dc":
          m_d["name"] = v["name"];
          m_d["label"] = v["name"];
          m_d["check"] = "check";
          break;
        case "city":
          m_d["name"] = v["name"];
          m_d["label"] = v["name"];
          if (fil.length > 0) {
            need_append = false;
            if (fil.includes(m_d["city_id"])) {
              need_append = true;
            }
          }
          break;
        case "store":
          m_d["name"] = v["name"];
          m_d["label"] = v["name"];
          if (fil.length > 0) {
            need_append = false;
            if (fil.includes(v["store_id"])) {
              need_append = true;
            }
          }
          break;
        case "brands":
          m_d["name"] = v["name"];
          m_d["label"] = v["name"];
          if (fil.length > 0) {
            need_append = false;
            if (fil.includes(v["Brand_id"])) {
              need_append = true;
            }
          }
          break;
        case "products":
          m_d["name"] = v["name"];
          m_d["label"] = v["name"];
          if (fil.length > 0) {
            need_append = false;
            if (fil.includes(v["Product_id"])) {
              need_append = true;
            }
          }
          break;
        default:
          console.log("DEFAULT LOG");
      }
      if (need_append) d.push(m_d);
    }

    switch (d_type) {
      case "dc":
        set_sel_dc(d);
        sel_dc = d;
        if (its_initial) {
          drop_down_modified("dc", data_as_d_obj(d[0]), its_initial);
        }
        break;
      case "city":
        set_sel_city(d);
        sel_city = d;
        if (its_initial) {
          drop_down_modified("city", data_as_d_obj(d[0]), its_initial);
        }
        break;
      case "store":
        set_sel_store(d);
        sel_store = d;
        if (its_initial) {
          drop_down_modified("store", data_as_d_obj(d[0]), its_initial);
        }
        break;
      case "brands":
        set_sel_brands(d);
        sel_brands = d;
        if (its_initial) {
          drop_down_modified("brands", data_as_d_obj(d[0]), its_initial);
        }
        break;
      case "products":
        set_sel_products(d);
        sel_products = d;
        if (its_initial) {
          drop_down_modified("products", data_as_d_obj(d[0]), its_initial);
        }
        break;
      case "horizon":
        set_sel_horizon(d);
        sel_horizon = d;
        if (its_initial) {
          drop_down_modified("horizon", data_as_d_obj(d[0]), its_initial);
        }
        break;
      default:
        console.log("DEFAULT LOG");
    }
  };

  let prepare_top_impact = (src) => {
    let d = [];
    for (let v of src) {
      d.push({
        name: v["Feature"],
        value: v["importance"],
        suffix: "",
      });
    }
    set_top_impact(d);
  };

  let data_model_res = [
    {
      text: "ML Accuracy",
      mode: "",
      value: 90,
      val_suffix: "%",
      sub_text: "",
    },
    {
      text: "ML Accuracy Vs Demand",
      mode: "inc",
      value: 10,
      val_suffix: "%",
      sub_text: "",
    },
    {
      text: "Last Consensus Forecase vs ML",
      mode: "inc",
      value: 13,
      val_suffix: "%",
      sub_text: "",
    },

    {
      text: "Order Qty.(Jul-Dec,2020)",
      mode: "",
      value: 1.703,
      val_suffix: "M",
      sub_text: "(+21.76% from previous timeframe)",
    },
  ];

  let filter_icon_trigger = (tri) => {
    set_show_filters(!tri);
  };

  let get_d_from_array = (d_type, e) => {
    switch (d_type) {
      case "dc":
        for (let v of sel_dc) {
          if (v["name"] === e["value"]) {
            return v;
          }
        }
        break;
      case "city":
        for (let v of sel_city) {
          if (v["name"] === e["value"]) {
            return v;
          }
        }
        break;
      case "store":
        for (let v of sel_store) {
          if (v["name"] === e["value"]) {
            return v;
          }
        }
        break;
      case "brands":
        for (let v of sel_brands) {
          if (v["name"] === e["value"]) {
            return v;
          }
        }
        break;
      case "products":
        for (let v of sel_products) {
          if (v["name"] === e["value"]) {
            return v;
          }
        }
        break;
      case "year":
        for (let v of sel_year) {
          if (v["value"] === e["value"]) {
            return v;
          }
        }
        break;
      case "horizon":
        for (let v of sel_horizon) {
          if (v["value"] === e["value"]) {
            return v;
          }
        }
        break;
      default:
        console.log("DEFAUT LOG");
    }
  };

  let weekly_graph_till = (till) => {
    assign_weekly_graph(graph_data, "horizon", horizon["value"], till);
  };

  let get_pred_month = (m_w, p_month) => {
    console.log("m_w", m_w);
    console.log("p_month", p_month);
    let src_d = {
      month: {
        1: "pred_monthly_1y",
        2: "pred_monthly_2y",
        3: "pred_monthly_3y",
      },
      week: {
        1: "pred_weekly_1y",
        2: "pred_weekly_2y",
        3: "pred_weekly_3y",
      },
    };

    return src_d[m_w][p_month];
  };

  let make_weekly_graph = (g_data, filter_months, field) => {
    let x = [];
    let y = [];

    for (let i = 0; i < g_data["monthly_x"].length; i++) {
      if (filter_months.includes(g_data["monthly_x"][i])) {
        if (field in g_data) {
          y = y.concat(g_data[field][g_data["monthly_x"][i]]);
        }
      }
    }

    for (let i = 0; i < y.length; i++) {
      x.push(i + 1);
    }

    return { x: x, y: y };
  };

  let assign_weekly_graph = (g_data, from, p_month, m_till) => {
    let w_g = make_weekly_graph(
      g_data,
      prepare_weekly_till(g_data["monthly_x"], m_till),
      "weekly_y"
    );

    let w_g_obj = {
      labels: w_g["x"],
      datasets: [
        {
          type: bar_chart,
          label: "Actual",
          borderColor: actual_colour,
          data: w_g["y"],
          borderWidth: 2,
        },
      ],
    };

    if (from === "horizon") {
      let p_g = make_weekly_graph(
        g_data,
        prepare_weekly_till(g_data["monthly_x"], m_till),
        get_pred_month("week", p_month)
      );
      w_g_obj["datasets"].push({
        type: line_chart,
        label: "Predicted",
        backgroundColor: predicted_colour,
        borderColor: predicted_colour,
        borderWidth: 2,
        data: p_g["y"] || [],
      });
    }

    set_weekly_graph(w_g_obj);
  };

  let assign_monthly_graph = (g_data, from, p_month) => {
    let g_obj = {
      labels: g_data["monthly_x"],
      datasets: [
        {
          type: bar_chart,
          label: "Actual",
          borderColor: actual_colour,
          data: g_data["monthly_y"],
          borderWidth: 2,
        },
      ],
    };

    if (from === "horizon") {
      let y_p_key = get_pred_month("month", p_month);
      g_obj["datasets"].push({
        type: line_chart,
        label: "Predicted",
        backgroundColor: predicted_colour,
        borderColor: predicted_colour,
        borderWidth: 2,
        data: g_data[y_p_key] || [],
      });
    }

    set_monthly_graph(g_obj);
  };

  let prepare_weekly_till = (months, till) => {
    let res_arr = [];
    for (let i = 0; i < months.length; i++) {
      if (months[i] === till) {
        res_arr.push(months[i]);
        break;
      }
      res_arr.push(months[i]);
    }

    return res_arr;
  };

  let prepare_weekly_filter_dropdown = (months) => {
    let d_data = [];
    for (let i = 0; i < months.length; i++) {
      d_data.push({
        label: months[i],
        name: months[i],
      });
    }
    set_sel_weekly_graph_filter(d_data);
  };

  let clear_dropdown_value = (d_type) => {
    switch (d_type) {
      case "dc":
        set_dc({});
        dc = {};
        break;
      case "city":
        set_city({});
        city = {};
        break;
      case "store":
        set_store({});
        store = {};
        break;
      case "brands":
        set_brand({});
        brand = {};
        break;
      case "products":
        set_product({});
        product = {};
        break;
      case "year":
        set_year({});
        year = {};
        set_graph_data({});
        graph_data = {};
        break;
      case "horizon":
        set_horizon({});
        horizon = {};
        break;
      case "graph":
        set_monthly_graph({});
        set_weekly_graph({});
        break;
      default:
        console.log("DEFAULT");
    }
  };

  let drop_down_modified = (d_type, sel_e, its_initial) => {
    console.log("d_type", d_type);
    console.log("sel_e", sel_e);
    console.log("its_initial", its_initial);
    let e = get_d_from_array(d_type, sel_e);
    clear_dropdown_value("graph");

    its_initial = true;
    switch (d_type) {
      case "dc":
        clear_dropdown_value("city");
        set_dc(e);
        dc = e;
        prepare_dropdown("city", src_data["city"], e["city_id"], its_initial);
        break;
      case "city":
        clear_dropdown_value("store");
        set_city(e);
        city = e;
        prepare_dropdown(
          "store",
          src_data["store"],
          e["store_id"],
          its_initial
        );
        break;
      case "store":
        clear_dropdown_value("brands");
        set_store(e);
        store = e;
        prepare_dropdown(
          "brands",
          src_data["brands"],
          e["Brand_id"],
          its_initial
        );
        break;
      case "brands":
        clear_dropdown_value("products");
        set_brand(e);
        brand = e;
        prepare_dropdown(
          "products",
          src_data["products"],
          e["Product_id"],
          its_initial
        );
        break;
      case "products":
        clear_dropdown_value("year");
        set_product(e);
        product = e;
        prepare_year_dropdown(store["store_id"], e["Product_id"], its_initial);
        break;
      case "year":
        clear_dropdown_value("horizon");
        set_year(e);
        year = e;
        let g_key = get_g_key(year["value"]);
        set_graph_data(graph_src_data[g_key]);
        graph_data = graph_src_data[g_key];
        prepare_horizon_dropdown(its_initial);
        break;
      case "horizon":
        set_horizon(e);
        horizon = e;
        if (sel_year.length === 0) {
          set_monthly_graph({});
          set_weekly_graph({});
        }

        if (sel_year.length !== 0) {
          assign_monthly_graph(graph_data, "horizon", horizon["value"]);
          prepare_weekly_filter_dropdown(graph_data["monthly_x"]);
          assign_weekly_graph(graph_data, "horizon", horizon["value"], "");
        }

        break;
      default:
        console.log("DEFAULT DATA");
    }
  };

  let get_g_key = (year) => {
    return store["store_id"] + "_" + product["Product_id"] + "_" + year;
  };

  // MODEL FNS
  const [show_filters, set_show_filters] = React.useState(false);
  const [show_chat_bot, set_show_chat_bot] = React.useState(false);
  function close_filter() {
    set_show_filters(false);
  }

  function close_chat_bot() {
    set_show_filters(false);
  }

  const graph_mapping_obj = (src) => {
    let src_obj = {};
    for (let i = 0; i < src.length; i++) {
      src_obj[
        src[i]["store_id"] + "_" + src[i]["product_id"] + "_" + src[i]["year"]
      ] = src[i];
    }
    return src_obj;
  };

  useEffect(() => {
    set_graph_src_data(graph_mapping_obj(Graph_data_src));
    graph_src_data = graph_mapping_obj(Graph_data_src);
    prepare_top_impact(src_data["forecast_impact"]);
    prepare_dropdown("dc", src_data["distribution_center"], [], true);
  }, []);

  return (
    <RB.Row
      className={`${
        theme === "light"
          ? "zero_margin zero_padding"
          : theme === "dark"
          ? "dark_bg zero_margin zero_padding"
          : "zero_margin zero_padding"
      }`}
    >
      <RB.Row className="bg_blue zero_margin zero_padding">
        <RB.Col md={4}>&nbsp;</RB.Col>
        <RB.Col md={4}>
          <h2 className="text_center">Demand Simulator</h2>
        </RB.Col>
        <RB.Col md={3} className="theme_toggle">
          <RB.Row>
            <RB.Col md={2} className="text_right">
              {"Light"}
            </RB.Col>
            <RB.Col md={2} className="zero_margin zero_padding">
              <Switch
                onChange={() => {
                  modify_theme();
                }}
                checked={theme === "dark"}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={50}
              />
            </RB.Col>
            <RB.Col md={2} className="text_left">
              {"Dark"}
            </RB.Col>
          </RB.Row>
        </RB.Col>
        <RB.Col md={1} className="theme_toggle">
          <ApiIcon
            className="filter_icon"
            onClick={() => {
              filter_icon_trigger(show_filters);
            }}
          />
        </RB.Col>
      </RB.Row>
      <RB.Row className="zero_margin zero_padding">
        <RB.Col md={2}>
          <RB.Form.Label htmlFor="dc">{"DC"}</RB.Form.Label>
          <Dropdown
            id="dc"
            options={sel_dc}
            value={dc}
            onChange={(e) => {
              drop_down_modified("dc", e, false);
            }}
            placeholder="Select"
          />
        </RB.Col>
        <RB.Col md={2}>
          <RB.Form.Label htmlFor="city">{"City"}</RB.Form.Label>
          <Dropdown
            id="city"
            options={sel_city}
            value={city}
            onChange={(e) => {
              drop_down_modified("city", e, false);
            }}
            placeholder="Select"
          />
        </RB.Col>
        <RB.Col md={2}>
          <RB.Form.Label htmlFor="store">{"Customer/Store"}</RB.Form.Label>
          <Dropdown
            id="store"
            options={sel_store}
            value={store}
            onChange={(e) => {
              drop_down_modified("store", e, false);
            }}
            placeholder="Select"
          />
        </RB.Col>
        <RB.Col md={2}>
          <RB.Form.Label htmlFor="brands">{"Brand"}</RB.Form.Label>
          <Dropdown
            id="brands"
            value={brand}
            options={sel_brands}
            onChange={(e) => {
              drop_down_modified("brands", e, false);
            }}
            placeholder="Select"
          />
        </RB.Col>
        <RB.Col md={2}>
          <RB.Form.Label htmlFor="products">{"Product"}</RB.Form.Label>
          <Dropdown
            id="products"
            value={product}
            options={sel_products}
            onChange={(e) => {
              drop_down_modified("products", e, false);
            }}
            placeholder="Select"
          />
        </RB.Col>
        <RB.Col md={2}>
          <RB.Form.Label htmlFor="year">{"Sale Year"}</RB.Form.Label>
          <Dropdown
            value={year}
            id="year"
            options={sel_year}
            onChange={(e) => {
              drop_down_modified("year", e, false);
            }}
            placeholder="Select"
          />
        </RB.Col>
      </RB.Row>
      <RB.Row className="zero_margin zero_padding">&nbsp;</RB.Row>
      <RB.Row className="zero_margin zero_padding">
        <RB.Col md={2}>
          {/* <RB.Row> */}
          <RB.Card
            className={`${
              theme === "light"
                ? "zero_border_radius"
                : theme === "dark"
                ? "dark_card zero_border_radius"
                : "zero_border_radius"
            }`}
          >
            <RB.Card.Header className="text_center">
              Top 5 Featured Impact Forecasting
            </RB.Card.Header>
            <RB.Card.Body className="impact_card_body">
              <RB.Row>
                {top_impact.map((impact, index) => {
                  return (
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "impact_card zero_padding"
                          : theme === "dark"
                          ? "dark_card impact_card zero_padding"
                          : "impact_card zero_padding"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Row>
                          <RB.Col md={9}>
                            <RB.Card.Text className="zero_padding">
                              {impact["name"]}
                            </RB.Card.Text>
                          </RB.Col>
                          <RB.Col md={3}>
                            <RB.Card.Text className="zero_padding">
                              {impact["value"]}
                              {impact["suffix"]}
                            </RB.Card.Text>
                          </RB.Col>
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  );
                })}
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
          {/* </RB.Row> */}
          <RB.Row>&nbsp;</RB.Row>
          {/* <RB.Row> */}
          <RB.Card
            className={`${
              theme === "light"
                ? "zero_border_radius"
                : theme === "dark"
                ? "dark_card zero_border_radius"
                : "zero_border_radius"
            }`}
          >
            <RB.Card.Header className="text_center">
              Top 5 Featured Impact Forecasting
            </RB.Card.Header>
            <RB.Card.Body className="impact_card_body">
              <RB.Row>
                {top_impact.map((impact, index) => {
                  return (
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "impact_card zero_padding"
                          : theme === "dark"
                          ? "dark_card impact_card zero_padding"
                          : "impact_card zero_padding"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Row>
                          <RB.Col md={9}>
                            <RB.Card.Text className="zero_padding">
                              {impact["name"]}
                            </RB.Card.Text>
                          </RB.Col>
                          <RB.Col md={3}>
                            <RB.Card.Text className="zero_padding">
                              {impact["value"]}
                              {impact["suffix"]}
                            </RB.Card.Text>
                          </RB.Col>
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  );
                })}
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
          {/* </RB.Row> */}
        </RB.Col>
        <RB.Col md={2}>
          <RB.Card
            className={`${
              theme === "light"
                ? "zero_border_radius"
                : theme === "dark"
                ? "dark_card zero_border_radius"
                : "zero_border_radius"
            }`}
          >
            <RB.Card.Body className="impact_list_card text_center">
              {data_model_res.map((impact, index) => {
                return (
                  <>
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "zero_padding"
                          : theme === "dark"
                          ? "dark_card zero_padding"
                          : "zero_padding"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Card.Text>{impact["text"]}</RB.Card.Text>
                        <RB.Card.Title className="text_green">
                          {impact["value"]}
                          {impact["val_suffix"]}
                        </RB.Card.Title>
                        <RB.Card.Text>{impact["sub_text"]}</RB.Card.Text>
                      </RB.Card.Body>
                    </RB.Card>
                    <RB.Row>&nbsp;</RB.Row>
                  </>
                );
              })}
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
        <RB.Col md={8}>
          <RB.Row>
            <RB.Col md={3} className="fix_center">
              <RB.Row>
                <RB.Col md={4} className="text_right">
                  <h7 className="zero_padding">Data</h7>
                </RB.Col>
                <RB.Col md={4}>
                  <Switch
                    onChange={set_data_chart}
                    checked={data_chart}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={50}
                  />
                </RB.Col>
                <RB.Col md={4}>
                  <h7 className="zero_padding">Charts</h7>
                </RB.Col>
              </RB.Row>
            </RB.Col>

            <RB.Col md={6} className="bg_blue fix_center">
              <h5 className="text_center">Order Quantity Forecast</h5>
            </RB.Col>
            <RB.Col md={3}>
              <RB.Row>
                <Dropdown
                  value={horizon}
                  disabled={!graph_data["having_prediction"]}
                  id="horizon"
                  options={sel_horizon}
                  onChange={(e) => {
                    drop_down_modified("horizon", e, false);
                  }}
                  placeholder="Select"
                />
              </RB.Row>
            </RB.Col>
          </RB.Row>
          <RB.Row>
            <LineBar chart_option={monthly_graph} />
          </RB.Row>
          <RB.Row>
            <RB.Col md={2}>
              <h5 className="chart_dropdown_text text_center">
                {"Weekly Till"}
              </h5>
            </RB.Col>
            <RB.Col md={10}>
              <Dropdown
                id="chart"
                options={sel_weekly_graph_filter}
                onChange={(e) => {
                  weekly_graph_till(e.value);
                }}
                placeholder="Select"
              />
            </RB.Col>
          </RB.Row>
          <RB.Row>
            <LineBar chart_option={weekly_graph} />
          </RB.Row>
        </RB.Col>
      </RB.Row>
      <RB.Row className="zero_margin zero_padding">&nbsp;</RB.Row>
      <RB.Row className="zero_margin zero_padding">
        <RB.Col md={2}>
          <RB.Button className="_100_width" variant="primary" size="sm">
            Clear All Selection
          </RB.Button>
        </RB.Col>
        <RB.Col md={7}> &nbsp;</RB.Col>
        <RB.Col md={3} className="flex_span text_right">
          <Switch
            onChange={() => {
              set_show_chat_bot(!show_chat_bot);
            }}
            checked={show_chat_bot}
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={50}
          />
          <h5 className="chat_bot_text">{"Toggle for Chat Bot"}</h5>
        </RB.Col>
      </RB.Row>
      <RB.Row className="zero_margin zero_padding">&nbsp;</RB.Row>

      {/* MODAL PART */}

      <Modal
        isOpen={show_filters}
        onRequestClose={close_filter}
        style={filter_modal_style}
        contentLabel="FIlter Modal"
      >
        <RB.Row className="modal_container">
          <RB.Row>
            <h5 className="bg_blue text_center">Scenario Shaper</h5>
          </RB.Row>
          <RB.Row>
            <h4 className="bg_blue text_center">Simulate Scenario</h4>
          </RB.Row>

          <RB.Row>
            <RB.Card
              className={`${
                theme === "light"
                  ? "zero_border_radius"
                  : theme === "dark"
                  ? "dark_card zero_border_radius"
                  : "zero_border_radius"
              }`}
            >
              <RB.Card.Body className="filter_inner_card zero_padding">
                <RB.Row className="text_center">
                  <h7>{"Inventory"}</h7>
                </RB.Row>
                <RB.Row>
                  <RB.Col md={1}>
                    <RB.Card.Title>
                      <h3>{"+"}</h3>
                    </RB.Card.Title>
                  </RB.Col>
                  <RB.Col md={10} className="range_slider">
                    <RB.Form.Range />
                  </RB.Col>
                  <RB.Col md={1}>
                    <RB.Card.Title>
                      <h3>{"-"}</h3>
                    </RB.Card.Title>
                  </RB.Col>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>

          <RB.Row className="filter_empty_row">&nbsp;</RB.Row>

          <RB.Row>
            <RB.Card
              className={`${
                theme === "light"
                  ? "zero_border_radius"
                  : theme === "dark"
                  ? "dark_card zero_border_radius"
                  : "zero_border_radius"
              }`}
            >
              <RB.Card.Body className="filter_inner_card">
                <RB.Row className="text_center">
                  <h7>{"Promotion spent"}</h7>
                </RB.Row>
                <RB.Row>
                  <RB.Col md={3}>
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "zero_border_radius"
                          : theme === "dark"
                          ? "dark_card zero_border_radius"
                          : "zero_border_radius"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Row>
                          <RB.Col md={6} className="nrp text_right">
                            <RB.Card.Text>10</RB.Card.Text>
                          </RB.Col>
                          <RB.Col md={6} className="nlp">
                            <RB.Card.Text>
                              <ArrowUpwardIcon />
                            </RB.Card.Text>
                          </RB.Col>
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                  <RB.Col md={3}>
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "zero_border_radius"
                          : theme === "dark"
                          ? "dark_card zero_border_radius"
                          : "zero_border_radius"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Row>
                          <RB.Col md={6} className="nrp text_right">
                            <RB.Card.Text>10</RB.Card.Text>
                          </RB.Col>
                          <RB.Col md={6} className="nlp">
                            <RB.Card.Text>
                              <ArrowUpwardIcon />
                            </RB.Card.Text>
                          </RB.Col>
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                  <RB.Col md={3}>
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "zero_border_radius"
                          : theme === "dark"
                          ? "dark_card zero_border_radius"
                          : "zero_border_radius"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Row>
                          <RB.Col md={6} className="nrp text_right">
                            <RB.Card.Text>10</RB.Card.Text>
                          </RB.Col>
                          <RB.Col md={6} className="nlp">
                            <RB.Card.Text>
                              <ArrowUpwardIcon />
                            </RB.Card.Text>
                          </RB.Col>
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                  <RB.Col md={3}>
                    <RB.Card
                      className={`${
                        theme === "light"
                          ? "zero_border_radius"
                          : theme === "dark"
                          ? "dark_card zero_border_radius"
                          : "zero_border_radius"
                      }`}
                    >
                      <RB.Card.Body className="zero_padding">
                        <RB.Row>
                          <RB.Col md={6} className="nrp text_right">
                            <RB.Card.Text>10</RB.Card.Text>
                          </RB.Col>
                          <RB.Col md={6} className="nlp">
                            <RB.Card.Text>
                              <ArrowUpwardIcon />
                            </RB.Card.Text>
                          </RB.Col>
                        </RB.Row>
                      </RB.Card.Body>
                    </RB.Card>
                  </RB.Col>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>

          <RB.Row className="filter_empty_row">&nbsp;</RB.Row>

          <RB.Row>
            <RB.Card
              className={`${
                theme === "light"
                  ? "zero_border_radius"
                  : theme === "dark"
                  ? "dark_card zero_border_radius"
                  : "zero_border_radius"
              }`}
            >
              <RB.Card.Body className="filter_inner_card">
                <RB.Row className="text_center">
                  <h7>{"Inflation"}</h7>
                </RB.Row>
                {/* <RB.Row>&nbsp;</RB.Row> */}
                <RB.Row>
                  <RB.InputGroup size="sm" className="mb-3">
                    <RB.InputGroup.Text id="inputGroup-sizing-sm">
                      Input Value
                    </RB.InputGroup.Text>
                    <RB.Form.Control
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </RB.InputGroup>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>

          <RB.Row className="filter_empty_row">&nbsp;</RB.Row>

          <RB.Row>
            <RB.Card
              className={`${
                theme === "light"
                  ? "zero_border_radius"
                  : theme === "dark"
                  ? "dark_card zero_border_radius"
                  : "zero_border_radius"
              }`}
            >
              <RB.Card.Body className="filter_inner_card zero_padding">
                <RB.Row className="text_center">
                  <h7>{"Product Price"}</h7>
                </RB.Row>
                <RB.Row className="fil_p_price">
                  <RB.Card
                    className={`${
                      theme === "light"
                        ? "zero_border_radius"
                        : theme === "dark"
                        ? "dark_card zero_border_radius"
                        : "zero_border_radius"
                    }`}
                  >
                    <RB.Card.Body className="filter_inner_card zero_padding">
                      No change
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Row>
                <RB.Row>
                  <RB.Col md={1}>
                    <RB.Card.Title>
                      <h3>{"+"}</h3>
                    </RB.Card.Title>
                  </RB.Col>
                  <RB.Col md={10} className="range_slider">
                    <RB.Form.Range />
                  </RB.Col>
                  <RB.Col md={1}>
                    <RB.Card.Title>
                      <h3>{"-"}</h3>
                    </RB.Card.Title>
                  </RB.Col>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>
          <RB.Row className="filter_empty_row">&nbsp;</RB.Row>

          <RB.Row>
            <RB.Card
              className={`${
                theme === "light"
                  ? "zero_border_radius"
                  : theme === "dark"
                  ? "dark_card zero_border_radius"
                  : "zero_border_radius"
              }`}
            >
              <RB.Card.Body className="filter_inner_card zero_padding">
                <RB.Row className="text_center">
                  <h7>{"Discount offered in Store"}</h7>
                </RB.Row>
                <RB.Row>
                  <RB.Col md={1}>
                    <RB.Card.Title>
                      <h3>{"+"}</h3>
                    </RB.Card.Title>
                  </RB.Col>
                  <RB.Col md={10} className="range_slider">
                    <RB.Form.Range />
                  </RB.Col>
                  <RB.Col md={1}>
                    <RB.Card.Title>
                      <h3>{"-"}</h3>
                    </RB.Card.Title>
                  </RB.Col>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>
          <RB.Row className="filter_empty_row">&nbsp;</RB.Row>
          <RB.Row>
            <RB.Card
              className={`${
                theme === "light"
                  ? "zero_border_radius"
                  : theme === "dark"
                  ? "dark_card zero_border_radius"
                  : "zero_border_radius"
              }`}
            >
              <RB.Card.Body className="filter_inner_card zero_padding">
                <RB.Row className="text_center">
                  <h7>{"Introduce Marketing Campaign"}</h7>
                </RB.Row>
                <RB.Row>
                  <RB.Col md={6} className="filter_date_picker">
                    <DatePicker />
                  </RB.Col>
                  <RB.Col md={6} className="filter_date_picker">
                    <DatePicker />
                  </RB.Col>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>

          <RB.Row className="filter_empty_row">&nbsp;</RB.Row>

          <RB.Row className="filter_empty_row">
            <RB.Col md={4}>&nbsp;</RB.Col>
            <RB.Col md={4} className="zero_padding">
              <RB.Button
                className="_95_width zero_border_radius"
                variant="secondary"
                size="sm"
              >
                Reset
              </RB.Button>
            </RB.Col>
            <RB.Col md={4} className="zero_padding">
              <RB.Button
                className="_95_width zero_border_radius"
                variant="primary"
                size="sm"
              >
                Apply
              </RB.Button>
            </RB.Col>
          </RB.Row>
        </RB.Row>
      </Modal>

      <Modal
        isOpen={show_chat_bot}
        onRequestClose={close_chat_bot}
        style={chatbot_modal_style}
        contentLabel="Example Modal"
      >
        <RB.Row>
          <RB.Col md={11}>
            <h4 className="text_center">{"Chat Bot"}</h4>
          </RB.Col>
          <RB.Col md={1} className="text_right">
            <AiFillCloseCircle
              onClick={() => {
                set_show_chat_bot(false);
              }}
            />
          </RB.Col>
        </RB.Row>
        <RB.Row>
          <RB.Card
            className={`${
              theme === "light"
                ? "zero_border_radius"
                : theme === "dark"
                ? "dark_card zero_border_radius"
                : "zero_border_radius"
            }`}
          >
            <RB.Card.Body className="zero_padding">
              <h4>
                <BsFillChatLeftFill />
                {"Ask a question about your data"}
              </h4>
            </RB.Card.Body>
          </RB.Card>
        </RB.Row>
        <RB.Row>
          <h4>{"Try one of these to get started"}</h4>
        </RB.Row>

        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>

        <RB.Row>
          <RB.Col md={4}>
            <RB.Card className="bg_blue">
              <RB.Card.Body className="chat_bot_card text_center">
                <h6>
                  What is the total sales for xyz DC in Q3 for the year 2020-21
                </h6>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={4}>
            <RB.Card className="bg_blue">
              <RB.Card.Body className="chat_bot_card text_center">
                <h6>
                  What is the total spent on promotion in fiscal year
                  2019-20What is the total spent on promotion in fiscal year
                  2019-20
                </h6>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={4}>
            <RB.Card className="bg_blue">
              <RB.Card.Body className="chat_bot_card text_center">
                <h6>
                  What is the number of quantities sold for the product abc in
                  def state
                </h6>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
        </RB.Row>
      </Modal>

      {/* MODAL PART */}
    </RB.Row>
  );
};

export default Default_Function;
