import * as RB from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import POWERBI from "../../DashboardViews/POWERBI";
import { LuCalendarClock } from "react-icons/lu";
import {
  faDiagnoses,
  faAsterisk,
  faChartArea,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const Default_Function = (props) => {
  const [active_mode, set_active_mode] = useState("");
  const [iframe_link_src] = useState(props.bi_links);
  const [iframe_url, set_iframe_url] = useState("");

  function show_bi(link, mode) {
    if (active_mode === mode) {
      return;
    }
    set_active_mode(mode);
    set_iframe_url(link);
  }

  useEffect(() => {
    show_bi(iframe_link_src["multi_tire"], "multi_tire");
  }, []);
  return (
    <>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <RB.Col md={3}>
          <RB.Card
            className="zero_border_radius"
            onClick={() => {
              show_bi(iframe_link_src["multi_tire"], "multi_tire");
            }}
          >
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={2} className="acc_card_icon_col">
                  <FontAwesomeIcon
                    className="acc_card_icon_icon"
                    icon={faAsterisk}
                  />
                </RB.Col>
                <RB.Col md={9}>
                  <h4 className="acc_card_text">
                    Multi-tier supplier visibility
                  </h4>
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
        <RB.Col md={3}>
          <RB.Card
            className="zero_border_radius"
            onClick={() => {
              show_bi(iframe_link_src["predictive"], "predictive");
            }}
          >
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={2} className="acc_card_icon_col">
                  <FontAwesomeIcon
                    className="acc_card_icon_icon"
                    icon={faLayerGroup}
                  />
                </RB.Col>
                <RB.Col md={9}>
                  <h4 className="acc_card_text">Predictive maintenance</h4>
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
        <RB.Col md={3}>
          <RB.Card
            className="zero_border_radius"
            onClick={() => {
              show_bi(iframe_link_src["order_delivery"], "order_delivery");
            }}
          >
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={2} className="acc_card_icon_col">
                  <FontAwesomeIcon
                    className="acc_card_icon_icon"
                    icon={faChartArea}
                  />
                </RB.Col>
                <RB.Col md={9}>
                  <h4 className="acc_card_text">O2D Analytics</h4>
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
        <RB.Col md={3}>
          <RB.Card
            className="zero_border_radius"
            onClick={() => {
              show_bi(iframe_link_src["inventory"], "inventory");
            }}
          >
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={2} className="acc_card_icon_col">
                  <FontAwesomeIcon
                    className="acc_card_icon_icon"
                    icon={faDiagnoses}
                  />
                </RB.Col>
                <RB.Col md={9}>
                  <h4 className="acc_card_text">Inventory Risks</h4>
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <RB.Col md={3}>
          <RB.Card
            className="zero_border_radius"
            onClick={() => {
              show_bi(
                iframe_link_src["production_scheduling"],
                "production_scheduling"
              );
            }}
          >
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={2} className="acc_card_icon_col">
                <LuCalendarClock className="acc_card_icon_icon" />
                </RB.Col>
                <RB.Col md={9}>
                  <h4 className="acc_card_text">Production Scheduling</h4>
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <POWERBI height={"1000"} URL={iframe_url} />
      </RB.Row>
    </>
  );
};

export default Default_Function;
