import * as RB from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import ToolTip from "../../Tooltip";

const SidebarCPG = (props) => {
  return (
    <RB.Row className="cpg_sidebar_rework">
      <div>
        <ToolTip tooltip="home" href="#" id="cpg_home">
          <FontAwesomeIcon
            tooltip="Default tooltip"
            className="cpg_left_bar_icon"
            onClick={() => {
              props.left_pan_selector("home");
            }}
            icon={faHome}
          />
        </ToolTip>
      </div>
    </RB.Row>
  );
};

export default SidebarCPG;
