import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Line_Component = (props) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: props.chart_option.header||"",
      },
    },
  };


  const data = {
    labels:props.chart_option.labels||[],
    datasets: props.chart_option.datasets||[],
  };
  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

export default Line_Component;
