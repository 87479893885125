import * as RB from "react-bootstrap";
import { useState } from "react";
import HealthBox from "./healthbox.jsx";
import Summary from "./summary.jsx";
import TabuleaView from "../../DashboardViews/TABLEAU";
import POWERBI from "../../DashboardViews/POWERBI";
import WhatHappenView from "../Inbound_Analytics/what_happen.jsx";
import "./style.css";

const Profit_echelon = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");
  let [d_v_m_thisweek, set_d_v_m_thisweek] = useState(1);

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];
  let health = {
    this_week: {
      card: {
        icon: "Icon01",
        name: "OSA",
        value: 77,
        value_suffix: "%",
        sub_value: 15.61,
        sub_value_suffix: "%",
        sub_value_prefix: "+",
      },
      sub_card: [
        {
          icon: "Icon01",
          name: "Demand Sales",

          value: 30.02,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 17.21,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
        {
          icon: "Icon02",
          name: "Margin Sales",

          value: 9,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 15.38,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
        {
          icon: "Icon03",
          name: "Inventory Sales Ratio ",

          value: 2.56,
          value_prefix: "",
          value_suffix: "",

          sub_value: 1.1,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
        {
          icon: "Icon04",
          name: "Inventory Turnover",

          value: 3.34,
          value_prefix: "",
          value_suffix: "",

          sub_value: 1.01,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
      ],
    },
    next_week: {
      card: {
        icon: "Icon01",
        name: "OSA",
        value: 71,
        value_suffix: "%",
        sub_value: 14.21,
        sub_value_suffix: "%",
        sub_value_prefix: "+",
      },
      sub_card: [
        {
          icon: "Icon01",
          name: "Demand Sales",

          value: 28.22,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 16.22,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
        {
          icon: "Icon02",
          name: "Margin Sales",

          value: 8.46,
          value_prefix: "$",
          value_suffix: "M",

          sub_value: 16.3,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
        {
          icon: "Icon03",
          name: "Inventory Sales Ratio",

          value: 2.56,
          value_prefix: "",
          value_suffix: "",

          sub_value: 1.1,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
        {
          icon: "Icon04",
          name: "Inventory Turnover",

          value: 3.34,
          value_prefix: "",
          value_suffix: "",

          sub_value: 1.01,
          sub_value_suffix: "%",
          sub_value_prefix: "+",
        },
      ],
    },
  };

  let summary = {
    this_week: [
      {
        text: "The OSA level for this week is 15.61% higher than the same week last year",
        mode: "",
      },
      {
        text: "This improves sales by 17.21% compared to the same week last year",
        mode: "",
      },
      {
        text: "The total risk of lost sales for this week is $43mil, out of which $24mil can be recovered through optimal sourcing.",
        mode: "",
      },
      {
        text: "The total excess holding costs for this week is $156k across all depots, out which $ 49k can be saved through reallocation.",
        mode: "",
      },
    ],
    next_week: [
      {
        text: "The OSA level for next week is 14.21% higher than the same week last year.",
        mode: "",
      },
      {
        text: "This is likely to improve sales by 16.22%",
        mode: "",
      },
      {
        text: "The total risk of lost sales for this week is $45mil, out of which $25mil can be recovered through optimal sourcing",
        mode: "",
      },
      {
        text: "The total excess holding costs for this week is $161k across all depots, out which $ 51k can be saved through reallocation.",
        mode: "",
      },
    ],
  };

  let what_happen = {
    this_week: {
      what_happen: [
        {
          text: "Stock outs for products Trumedic and Foodsaver are likely to result in a weekly loss of $245k, in the next 2 weeks.",
          mode: "inc",
        },
      ],
      what_did_happen: [
        {
          text: "Suppliers 2 and 3 are options to recover $174k of the $245k, based on lowest costs and lead times.",
          mode: "inc",
        },
      ],
    },
    next_week: {
      what_happen: [
        {
          text: "Stock outs for products Trumedic and Foodsaver are likely to result in a weekly loss of $245k, in the next 2 weeks.",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "Suppliers 2 and 3 are options to recover $174k of the $245k, based on lowest costs and lead times",
          mode: "inc",
        },
      ],
    },
  };

  let what_happen01 = {
    this_week: {
      what_happen: [
        {
          text: "SKUs Brother 1134 and GHD Tools have the highest excess holding costs of $12K for the next 2 weeks",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "Brother 1134 can be transfered to depot 731 and other locations shown above to recover a total profit value of $5.3k for the next 2 weeks",
          mode: "inc",
        },
      ],
    },
    next_week: {
      what_happen: [
        {
          text: "SKUs Brother 1134 and GHD Tools have the highest excess holding costs of $12K for the next 2 weeks",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "Brother 1134 can be transfered to depot 731 and other locations shown above to recover a total profit value of $5.3k for the next 2 weeks",
          mode: "inc",
        },
      ],
    },
  };

  return (
    <>
      <RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => set_sel_com_view(e.currentTarget.value)}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>

        {sel_com_view === "this_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["this_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text">Executive Summary</h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["this_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"315vh"}
                      URL={
                        "https://public.tableau.com/views/IM1_TW-24Mar/Dashboard1-TW?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiMTUxZGUzZTItN2RmZi00NDc1LWFhODEtOTM0YzlmN2Q1NmJmIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1860}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappenView data={what_happen["this_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="pe_view_card_1 zero_padding zero_border_radius ">
                <RB.Card.Body className="card_body_view">
                  <TabuleaView
                    WIDTH={"100%"}
                    HEIGHT={"315vh"}
                    URL={
                      "https://public.tableau.com/views/holdingcost_im1/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link"
                    }
                  />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappenView data={what_happen01["this_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
        {sel_com_view === "next_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["next_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text">
                    <b>Executive Summary</b>
                  </h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["next_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"305vh"}
                      URL={
                        "https://public.tableau.com/views/IM1_TW-24Mar/Dashboard1-TW?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiNjU4ZGEzZTQtNzMzYy00MzJmLTg3YTUtM2RiNTAwOWNhNWY5IiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1500}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappenView data={what_happen["next_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="pe_view_card_1 zero_padding zero_border_radius ">
                <RB.Card.Body className="card_body_view">
                  <TabuleaView
                    WIDTH={"100%"}
                    HEIGHT={"315vh"}
                    URL={
                      "https://public.tableau.com/views/holdingcost_im1/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link"
                    }
                  />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappenView data={what_happen01["next_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
      </RB.Row>
    </>
  );
};

export default Profit_echelon;
