import * as RB from "react-bootstrap";
import Chatbot from "./Chatbot_icon";

import "./style.css";

const Header = () => {
  return (
    <RB.Row className="null_p_m footer_content">
      <RB.Col xs={5}></RB.Col>
      <RB.Col xs={3}>
        <RB.Row className="footer_link">
          <a
            href="https://supplychain.latentview.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about ConnectedView
          </a>
        </RB.Row>
      </RB.Col>
      <RB.Col xs={3}>&nbsp;</RB.Col>
      <RB.Col xs={1}>
        <RB.Row>
          <Chatbot />
        </RB.Row>
      </RB.Col>
    </RB.Row>
  );
};

export default Header;
