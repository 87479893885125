import * as RB from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTip from "../../Tooltip";
import {
  faHome,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const SidebarCPG = (props) => {
  return (
    <RB.Row className="indus_sidebar_rework">
      <div>
        <ToolTip tooltip="home" href="#" id="cpg_home">
          <FontAwesomeIcon
            className="indus_left_bar_icon"
            onClick={() => {
              props.left_pan_selector("home");
            }}
            icon={faHome}
          />
        </ToolTip>
        <ToolTip tooltip="Tools and Acclerator" href="#" id="cpg_home">
          <FontAwesomeIcon
            className="indus_left_bar_icon"
            onClick={() => {
              props.left_pan_selector("accelerator");
            }}
            icon={faScrewdriverWrench}
          />
        </ToolTip>
        {/* <FontAwesomeIcon className="indus_left_bar_icon" icon={faUserAlt} /> */}
      </div>
    </RB.Row>
  );
};

export default SidebarCPG;
