import { useNavigate } from "react-router-dom";
import CPG from "../../images/cpg-icon.png";
import Retail from "../../images/retail-icon.png";
import Industrial from "../../images/industrial-icon.png";
import Technology from "../../images/technology-icon.png";
import "./style.css";
import * as RB from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import {get_user_obj} from "../app_service"

const AllDashboard = (props) => {
  const history = useNavigate();

  const naigate_to = (nav_to) => {
    history("/" + nav_to);
  };
  get_user_obj()
  return (
    <>
      <Header />
      <RB.Row className="zero_margin popins_font font_size_13 bg_gray">
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row className="popins_font font_size_13 bg_gray">
            <RB.Col md={1}>&nbsp;</RB.Col>
            <RB.Col md={10}>
              <RB.Row>
                <RB.Col md={3}>
                  <RB.Card className="landing_card shadow center">
                    <RB.Card.Body>
                      <RB.Card.Title>
                        <img src={CPG} alt={CPG} />
                      </RB.Card.Title>
                      <RB.Card.Title className="font-weight-bold dashboard_header_text">
                        Consumer Products
                      </RB.Card.Title>
                      <hr></hr>
                      <RB.Card.Text className="primary_text">
                        Customer Service levels - the key north star metric for
                        CPG companies, is impacted by Fill Rate,
                        Order-to-Deliver velocity, and Delivery quality. A drop
                        in Service levels & OTIF leads to loss in sales and
                        penalties.
                      </RB.Card.Text>
                      <RB.Card.Text className="text_gray secondary_text">
                        ConnectedView helps CPG companies drive service level
                        transformation through the power of connecting the
                        supply chain to drive better & faster decisions.
                      </RB.Card.Text>
                      <RB.Button
                        onClick={() => {
                          naigate_to("cpg");
                        }}
                        variant="primary width_95"
                      >
                        Go
                      </RB.Button>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Col>
                <RB.Col md={3}>
                  <RB.Card className="landing_card shadow center">
                    <RB.Card.Body>
                      <RB.Card.Title>
                        <img src={Retail} alt={Retail} />
                      </RB.Card.Title>
                      <RB.Card.Title className="font-weight-bold dashboard_header_text">
                        Retail
                      </RB.Card.Title>
                      <hr></hr>
                      <RB.Card.Text className="primary_text">
                        On Shelf Optimum Availability drives the right customer
                        experience for Retail consumers while balancing
                        Inventory excess through On shelf availability, On Time
                        Rate, and Delivery quality.
                      </RB.Card.Text>
                      <RB.Card.Text className="text_gray secondary_text">
                        ConnectedView helps Retail companies drive Optimum
                        availability transformation through the power of
                        connecting the supply chain to drive better &
                      </RB.Card.Text>
                      <RB.Button
                        onClick={() => {
                          naigate_to("retail");
                        }}
                        variant="btn btn-warning width_95"
                      >
                        Go
                      </RB.Button>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Col>
                <RB.Col md={3}>
                  <RB.Card className="landing_card shadow center">
                    <RB.Card.Body>
                      <RB.Card.Title>
                        <img src={Industrial} alt={Industrial} />
                      </RB.Card.Title>
                      <RB.Card.Title className="font-weight-bold dashboard_header_text">
                        Industrial
                      </RB.Card.Title>
                      <hr></hr>
                      <RB.Card.Text className="primary_text">
                        An industrial company's Delivery to Promise is impacted
                        by Supplier Lead Times, Inventory Risks,
                        Order-to-delivery Lead Times, and Multi-tier Supply
                        Chain Visibility.
                      </RB.Card.Text>
                      <RB.Card.Text className="text_gray secondary_text">
                        ConnectedView helps Industrial companies drive
                        Deliver-to-Promise transformation through the power of
                        connecting the supply chain to drive better &
                      </RB.Card.Text>
                      <RB.Button
                        onClick={() => {
                          naigate_to("industrial");
                        }}
                        variant="btn btn-danger width_95"
                      >
                        Go
                      </RB.Button>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Col>
                <RB.Col md={3}>
                  <RB.Card className="landing_card shadow fade_out center">
                    <RB.Card.Body>
                      <RB.Card.Title>
                        <img src={Technology} alt={Technology} />
                      </RB.Card.Title>
                      <RB.Card.Title className="font-weight-bold">
                        Technology
                      </RB.Card.Title>
                      <hr></hr>
                      <RB.Card.Text className="primary_text">
                        Customer Service levels is the key north star metric for
                        Tech companies, driven by Fill Rate, Order-to-Deliver
                        velocity, and Delivery quality.
                      </RB.Card.Text>
                      <RB.Card.Text className="text_gray secondary_text">
                        ConnectedView helps Tech companies drive service level
                        transformation through the power of connecting the
                        supply chain to drive better & faster decisions
                      </RB.Card.Text>
                      <RB.Button variant="primary width_95">Go</RB.Button>
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Col>
              </RB.Row>
            </RB.Col>
            <RB.Col md={1}>&nbsp;</RB.Col>
          </RB.Row>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>&nbsp;</RB.Row>
      </RB.Row>
      <Footer />
    </>
  );
};

export default AllDashboard;
