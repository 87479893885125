import * as RB from "react-bootstrap";
import { useState } from "react";
import POWERBI from "../../DashboardViews/POWERBI";
import Map from "../google_map";
import "./style.css";

const Default_Function = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];
  return (
    <>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>

      <RB.Row>
        <RB.ButtonGroup>
          {com_view.map((radio, idx) => (
            <RB.ToggleButton
              className="zero_border_radius zero_zindex button_gp_btn"
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={"outline-primary"}
              name="radio"
              value={radio.value}
              checked={sel_com_view === radio.value}
              onChange={(e) => set_sel_com_view(e.currentTarget.value)}
            >
              {radio.name}
            </RB.ToggleButton>
          ))}
        </RB.ButtonGroup>
      </RB.Row>

      {sel_com_view === "this_week" ? (
        <RB.Row>
          <POWERBI
            height={750}
            URL={
              "https://app.powerbi.com/view?r=eyJrIjoiMTZhYmMwODItZmYyOS00NzBhLTg3NmMtMDk4NzlmZDA0NmZhIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
            }
          />
          {/* <Map zoom={6}></Map> */}
        </RB.Row>
      ) : (
        <></>
      )}
      {sel_com_view === "next_week" ? (
        <RB.Row>
          <POWERBI height={800} URL={"http://54.162.175.248:7474/bloom/"} />
          {/* <Map zoom={6}></Map> */}
        </RB.Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default Default_Function;
