import * as RB from "react-bootstrap";
import Icon01 from "../../../images/retail-images/icon-01.png";
import Icon02 from "../../../images/retail-images/icon-02.png";
import Icon03 from "../../../images/retail-images/icon-03.png";
import Icon04 from "../../../images/retail-images/icon-04.png";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row className="healh_repeat">
        {props.data.map((d_item, index) => (
          <RB.Card
            className={`${
              index === 0
                ? "o2d_health_card zero_border_radius health_card_1"
                : "o2d_health_card zero_border_radius"
            }`}
          >
            <RB.Card.Body>
              <RB.Row className="health_center">
                <RB.Col md={5} className="zero_padding">
                  <h4 className="health_card_text">{d_item["name"]}</h4>
                </RB.Col>
                <RB.Col md={5} className="zero_padding">
                  <RB.Row>
                    <h5 className="health_card_value">
                      {d_item["value"]} {d_item["value_suffix"]}
                    </h5>
                  </RB.Row>
                  <RB.Row>
                    {index === 0 ? (
                      <RB.Col>
                        <RB.Button
                          className="green_btn green_bg"
                          variant="primary"
                          size="sm"
                        >
                          {d_item["sub_value_prefix"]} {d_item["sub_value"]}
                          {d_item["sub_value_suffix"]}
                        </RB.Button>
                      </RB.Col>
                    ) : (
                      <RB.Col className="o2d_health_card_sub_value_col">
                        <h7 className="health_card_sub_value">
                          {d_item["sub_value_prefix"]} {d_item["sub_value"]}
                          {d_item["sub_value_suffix"]}
                        </h7>
                      </RB.Col>
                    )}
                  </RB.Row>
                </RB.Col>
                <RB.Col md={2} className="zero_padding">
                  {d_item["icon"] === "Icon01" ? (
                    <img className="health_img" src={Icon01} alt={Icon01} />
                  ) : (
                    <></>
                  )}
                  {d_item["icon"] === "Icon02" ? (
                    <img className="health_img" src={Icon02} alt={Icon02} />
                  ) : (
                    <></>
                  )}
                  {d_item["icon"] === "Icon03" ? (
                    <img className="health_img" src={Icon03} alt={Icon03} />
                  ) : (
                    <></>
                  )}
                  {d_item["icon"] === "Icon04" ? (
                    <img className="health_img" src={Icon04} alt={Icon04} />
                  ) : (
                    <></>
                  )}
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        ))}
      </RB.Row>
    </>
  );
};

export default Default_Function;
