import React, { forwardRef, useImperativeHandle, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import * as RB from "react-bootstrap";
import map_obj from "./source.json";
import "./style.css";

const containerStyle = {
  height: "550px",
  width: "100%",
};

const GV8 = forwardRef((props, ref) => {
  let circles = [];
  let circle_max_stroke = 0.7;
  let [cirlce_colours] = useState({
    DC: {
      fill: "#6FBAFE",
      stroke: "#0A56C3",
    },
    PLANT: {
      fill: "#6C76C0",
      stroke: "#242579",
    },
    WAREHOUSE: {
      fill: "#D9917D",
      stroke: "#812A22",
    },
  });
  // let dc_light = "#B6D4EF";
  // let dc_dark = "#6FBAFE";
  // let plat_light = "#B8BCDA";
  // let plant_dark = "#6C76C0";
  // let warehouse_light = "#CDB3BD";
  // let warehouse_dark = "#D9917D";
  const [map_src] = useState(map_obj);
  const [map, setMap] = useState(null);
  let [summary, set_summary] = useState([]);
  let [map_center, set_map_center] = useState({
    lat: 35.213532527590964,
    lng: -80.82472274923373,
  });

  const { isLoaded } = useJsApiLoader({
    id: "dataKey",
    googleMapsApiKey: "AIzaSyDIjCKrQwojqZUh9-2FujVvZy2Amzh2ls4",
  });

  function makeInfoWindow(info) {
    let make_info_html = (info) => {
      return (
        `<div>
      <p >Location Type --> ` +
        info["d_type"] +
        `</p>
      <p ><span >LAT_CODE --> ` +
        info["loc"]["lat"] +
        `</p>
      <p >LON_CODE --> ` +
        info["loc"]["lng"] +
        `</p>
      <p >Location NAME --> ` +
        info["name"] +
        `</p>
   </div>`
      );
    };
    return new window.google.maps.InfoWindow({
      position: info["loc"],
      content: make_info_html(info),
    });
  }

  function getCircle(loc, circleType, map) {
    let c_colour = cirlce_colours[circleType];
    let circle = new window.google.maps.Circle({
      strokeColor: c_colour["stroke"],
      strokeWeight: circle_max_stroke,
      fillColor: c_colour["fill"],
      fillOpacity: circle_max_stroke,
      visible: true,
      map: map,
      center: loc,
      radius: 2000,
    });
    return circle;
  }

  let map_outside_click = () => {
    set_summary(map_src["overall_summary"]);
    for (let i = 0; i < circles.length; i++) {
      circles[i].setOptions({
        strokeWeight: circle_max_stroke,
        fillOpacity: circle_max_stroke,
      });
    }
  };

  let change_the_circle_colour = (circle, c_src) => {
    set_summary(c_src["summary"]);

    for (let i = 0; i < circles.length; i++) {
      let opacity = 0.35;
      if (circle.getCenter() === circles[i].getCenter()) {
        opacity = circle_max_stroke;
      }
      circles[i].setOptions({ strokeWeight: opacity, fillOpacity: opacity });
    }
  };

  let refix_radius = (zoom) => {
    let values = {
      20: 20,
      21: 20,
      22: 20,
      23: 20,
      1: 80000,
      0: 80000,
      3: 80000,
      2: 80000,
      5: 70000,
      4: 75000,
      7: 20000,
      6: 60000,
      9: 5000,
      8: 10000,
      11: 900,
      10: 2000,
      13: 500,
      12: 700,
      15: 200,
      14: 400,
      17: 50,
      16: 100,
      19: 30,
      18: 40,
    };
    for (let i = 0; i < circles.length; i++) {
      circles[i].setRadius(values[zoom]);
    }
  };

  let prepare_circles = (circle) => {
    circles.push(circle);
    console.log("CLEN", circles.length);
  };

  let prepare_infowindow = (src_data, circle, map) => {
    let info_window = makeInfoWindow(src_data);

    circle.addListener("mouseover", () => {
      info_window.open(map);
    });

    circle.addListener("mouseout", () => {
      info_window.close();
    });

    prepare_circles(circle);
  };

  let prepare_circle = (src_data, map) => {
    let circle = getCircle(src_data["loc"], src_data["d_type"], map);

    circle.addListener("click", () => {
      change_the_circle_colour(circle, src_data);
    });

    prepare_infowindow(src_data, circle, map);
  };

  let initiate_data_to_map = (map) => {
    console.log("DATA TO MAP");
    set_summary(map_src["overall_summary"]);
    for (let i = 0; i < map_src["induvial_summary"].length; i++) {
      prepare_circle(map_src["induvial_summary"][i], map);
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    console.log("ONLOAD");
    initiate_data_to_map(map, map_src["overall_summary"]);
    refix_radius(map.getZoom());
    map.addListener("click", () => {
      map_outside_click();
    });

    map.addListener("zoom_changed", () => {
      console.log("ZOOM VALUE", map.getZoom());
      set_map_center(map.getCenter());
      refix_radius(map.getZoom());
      setMap(map);
    });

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <RB.Row className="map_margin">
      <RB.Col md={10} className="zero_p_m">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={map_center}
          zoom={props.zoom || 5}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <></>
        </GoogleMap>
      </RB.Col>
      <RB.Col md={2} className="zero_p_m">
        {summary.map((item, index) => (
          <RB.Card key={index} className="map_r_card zero_border_radius">
            <RB.Card.Body className="map_r_card_body">
              <RB.Row>
                <h5>{item["summary"]}</h5>
              </RB.Row>
              <RB.Row>
                <h4>
                  {item["value"]} {item["suffix"]}
                </h4>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        ))}
      </RB.Col>
    </RB.Row>
  ) : (
    <></>
  );
});

export default React.memo(GV8);
