import * as RB from "react-bootstrap";
import FishboneDashboard from "../../../images/retail-images/fish-bone.jpg";

import "./style.css";

const Default_Function = () => {
  return (
    <>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>

      <RB.Row>
        <h3><b>FishBone</b></h3>
      </RB.Row>
      <RB.Row>
        <RB.Card className="zero_padding zero_border_radius ">
          <RB.Card.Body className="card_body_view">
            <img src={FishboneDashboard} alt={FishboneDashboard} />
          </RB.Card.Body>
        </RB.Card>
      </RB.Row>
    </>
  );
};

export default Default_Function;
