import * as RB from "react-bootstrap";
import React, { useEffect, useState, createRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import POWERBI from "../../DashboardViews/POWERBI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faStar } from "@fortawesome/free-solid-svg-icons";
import Worst from "../../../images/worst.jpg";
import Bad from "../../../images/bad.jpg";
import Avg from "../../../images/avg.jpg";
import Good from "../../../images/good.jpg";
import Excellent from "../../../images/excellent.jpg";
import { TiTick } from "react-icons/ti";
import { IoIosClose } from "react-icons/io";

import "./style.css";
import "./sty.css";

const Default_Function = (props) => {
  const selectRef = createRef();
  const [source_loc_options, set_source_loc_options] = useState([]);
  const [products_options, set_products_options] = useState([]);
  const [selected_location, set_selected_location] = useState([]);
  const [selected_products, set_selected_products] = useState([]);
  const [f_anamolies_loc, set_f_anamolies_loc] = useState([]);
  const [f_anamolies, set_f_anamolies] = useState([]);
  const [f_anamolie, set_f_anamolie] = useState({});
  const [active_index, set_active_index] = useState(-1);

  const recomendations = [
    "Training pickers on package handling",
    "Increasing quality checks",
    "IoT incorportaion to instigate safe-driving practices",
  ];

  let object_as_array = (obj_data) => {
    let arr = [];
    for (const key in obj_data) {
      arr.push({ name: key, value: key, label: key });
    }
    return arr;
  };

  let make_location_options = () => {
    let source_loc_obj = {};
    let data = props.anomalies;
    for (let i = 0; i < data.length; i++) {
      source_loc_obj[data[i]["Source_Loc"]] = data[i]["Source_Loc"];
    }

    let arr_data = object_as_array(source_loc_obj);
    set_source_loc_options(arr_data);
    assign_default_anamolies(arr_data);
  };

  let assign_default_anamolies = (arr_data) => {
    if (arr_data.length > 0) {
      set_selected_location([arr_data[0]]);
      make_product_options([arr_data[0]]);
    }
  };

  let make_product_options = (e) => {
    let data = props.anomalies;
    let obj = {};
    let s_v = [];
    let f_p = [];
    for (let i = 0; i < e.length; i++) {
      s_v.push(e[i]["value"]);
    }

    for (let i = 0; i < data.length; i++) {
      if (s_v.includes(data[i]["Source_Loc"])) {
        obj[data[i]["Products"]] = data[i]["Products"];
        data[i]["selector_type"] = 0;
        f_p.push(data[i]);
      }
    }

    set_products_options(object_as_array(obj));
    set_f_anamolies_loc(f_p);
    set_f_anamolies(f_p);
    assign_default_anamolie(f_p);
  };

  let anomaly_click = (index, value) => {
    let f_p_c = f_anamolies;
    f_p_c[index]["selector_type"] = value;
    set_f_anamolies(f_p_c);
    set_f_anamolie(value);
  };

  let assign_default_anamolie = (data) => {
    if (data.length > 0) {
      set_f_anamolie(data[0]);
      set_active_index(0);
    } else {
      set_f_anamolie({});
    }
  };

  let make_f_anamolies = (e) => {
    let s_v = [];
    let f_p = [];

    for (let i = 0; i < e.length; i++) {
      s_v.push(e[i]["value"]);
    }

    for (let i = 0; i < f_anamolies_loc.length; i++) {
      if (s_v.includes(f_anamolies_loc[i]["Products"])) {
        f_p.push(f_anamolies_loc[i]);
      }
      if (s_v.length === 0) {
        f_p.push(f_anamolies_loc[i]);
      }
    }
    set_f_anamolies(f_p);
    assign_default_anamolie(f_p);
  };

  useEffect(() => {
    make_location_options();
  }, []);

  return (
    <>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row className="p_l_10">
        <RB.Col md={6}>
          <RB.Row>
            <RB.Col md={6}>
              <RB.Form.Label htmlFor="source_location">
                {"Source Location"}
              </RB.Form.Label>
              <Multiselect
                id="source_location"
                ref={selectRef}
                onSelect={(e) => {
                  set_selected_location(e);
                  make_product_options(e);
                }}
                onRemove={(e) => {
                  set_selected_location(e);
                  make_product_options(e);
                }}
                options={source_loc_options}
                selectedValues={selected_location}
                displayValue="name"
                placeholder="Select"
              />
            </RB.Col>
            <RB.Col md={6}>
              <RB.Form.Label htmlFor="products">{"products"}</RB.Form.Label>
              <Multiselect
                ref={selectRef}
                id="products"
                options={products_options}
                onSelect={(e) => {
                  set_selected_products(e);
                  make_f_anamolies(e);
                }}
                onRemove={(e) => {
                  set_selected_products(e);
                  make_f_anamolies(e);
                }}
                selectedValues={selected_products}
                displayValue="name"
                placeholder="Select"
              />
            </RB.Col>
          </RB.Row>
        </RB.Col>
        <RB.Col md={5}>&nbsp;</RB.Col>
        <RB.Col md={1}>
        <RB.Row>&nbsp;</RB.Row>
          <RB.Row>
            <RB.Button variant="outline-primary">Apply</RB.Button>
          </RB.Row>
        </RB.Col>
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row className="p_l_10">
        <RB.Col md={7}>
          <RB.Row>
            {f_anamolies.map((value, index) => {
              if (value["Statement"] !== "") {
                return (
                  <RB.Row className="zero_p_m">
                    <RB.Col className="ana_list_selector zero_p_m">
                      <RB.ToggleButtonGroup
                        className="ana_list_selector_btn zero_border_radius"
                        type="radio"
                        name="options"
                        defaultValue={value["selector_type"]}
                      >
                        <RB.ToggleButton
                          variant={
                            value["selector_type"] === 1
                              ? "primary"
                              : "outline-primary"
                          }
                          id="tbg-radio-1"
                          onClick={() => {
                            anomaly_click(index, 1);
                            set_f_anamolie(value);
                            set_active_index(index);
                          }}
                        >
                          <TiTick></TiTick>
                        </RB.ToggleButton>
                        <RB.ToggleButton
                          variant={
                            value["selector_type"] === 2
                              ? "primary"
                              : "outline-primary"
                          }
                          id="tbg-radio-2"
                          onClick={() => {
                            anomaly_click(index, 2);
                            set_f_anamolie(value);
                            set_active_index(index);
                          }}
                        >
                          <IoIosClose></IoIosClose>
                        </RB.ToggleButton>
                      </RB.ToggleButtonGroup>
                    </RB.Col>
                    <RB.Col className="ana_list_card zero_p_m">
                      <RB.Card
                        className={
                          index === active_index
                            ? "zero_border_radius m_b10 active"
                            : "zero_border_radius m_b10"
                        }
                        onClick={() => {
                          set_f_anamolie(value);
                          set_active_index(index);
                        }}
                      >
                        <RB.Card.Body className="cpg_anomalies_list">
                          <RB.Row>
                            <RB.Col md={11}>
                              {value["selector_type"]} {"**"}
                              {value["Statement"]}
                            </RB.Col>
                            <RB.Col md={1}>
                              <FontAwesomeIcon
                                className="cpg_arow_icon"
                                icon={faChevronRight}
                              />
                            </RB.Col>
                          </RB.Row>
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  </RB.Row>
                );
              }
            })}
          </RB.Row>
        </RB.Col>
        <RB.Col md={5}>
          <RB.Card className="zero_border_radius">
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={1}>&nbsp;</RB.Col>
                <RB.Col md={10}>
                  <RB.Row>
                    <RB.Col>
                      <div>
                        <img className="rating_img" src={Worst} alt={Worst} />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="rating_icon"
                          icon={faStar}
                        />
                      </div>
                      <div>{"Worst"}</div>
                    </RB.Col>
                    <RB.Col>
                      <div>
                        <img className="rating_img" src={Bad} alt={Bad} />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="rating_icon"
                          icon={faStar}
                        />
                      </div>
                      <div>{"Bad"}</div>
                    </RB.Col>
                    <RB.Col>
                      <div>
                        <img className="rating_img" src={Avg} alt={Avg} />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="rating_icon"
                          icon={faStar}
                        />
                      </div>
                      <div>{"Avg"}</div>
                    </RB.Col>
                    <RB.Col>
                      <div>
                        <img className="rating_img" src={Good} alt={Good} />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="rating_icon"
                          icon={faStar}
                        />
                      </div>
                      <div>{"Good"}</div>
                    </RB.Col>
                    <RB.Col>
                      <div>
                        <img
                          className="rating_img"
                          src={Excellent}
                          alt={Excellent}
                        />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="rating_icon"
                          icon={faStar}
                        />
                      </div>
                      <div>{"Excellent"}</div>
                    </RB.Col>
                  </RB.Row>
                </RB.Col>
                <RB.Col md={1}>&nbsp;</RB.Col>
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>
              {Object.keys(f_anamolie).length > 0 ? (
                <RB.Row>
                  <RB.Row>
                    <h5 className="cpg_rec_header">Reasons</h5>
                  </RB.Row>
                  <RB.Row className="cpg_res_text">
                    <RB.Col md={12}>{f_anamolie["Reasons"]}</RB.Col>
                  </RB.Row>

                  <RB.Row>
                    <POWERBI
                      height={300}
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiZjUwYjM5ZDAtNzQ5ZS00MTgwLWE5OTgtYjRkYzllZGRlNDQ2IiwidCI6IjFjMmJlMTE2LWQyMDctNDJmMy05NTJiLWM2NjI4OTc0NTg3OSIsImMiOjZ9"
                      }
                    />
                  </RB.Row>
                </RB.Row>
              ) : (
                <></>
              )}

              {Object.keys(f_anamolie).length > 0 ? (
                <RB.Row className="rec_card">
                  <RB.Card className="zero_border_radius gray_bg m_b10">
                    <RB.Card.Body className="cpg_anomalies_list">
                      <RB.Row>
                        <h5 className="cpg_rec_header">Recommendation</h5>
                      </RB.Row>
                      {recomendations.map((value) => {
                        return (
                          <RB.Row>
                            <RB.Col md={1} className="">
                              <FontAwesomeIcon
                                className="cpg_arow_icon"
                                icon={faChevronRight}
                              />
                            </RB.Col>
                            <RB.Col md={11} className="cpg_rec_text">
                              {value}
                            </RB.Col>
                          </RB.Row>
                        );
                      })}
                    </RB.Card.Body>
                  </RB.Card>
                </RB.Row>
              ) : (
                <></>
              )}
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
