// import * as RB from "react-bootstrap";
import "./style.css";

const Default_Function = () => {
  return (
    <></>
  );
};

export default Default_Function;
