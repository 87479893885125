import * as RB from "react-bootstrap";
import { useState } from "react";
import HealthBox from "./health_box";
import Summary from "./summary";
import WhatHappen from "./what_happen";
import TabuleaView from "../../DashboardViews/TABLEAU";
import Calculation from "../calculation";
import POWERBI from "../../DashboardViews/POWERBI";

import "./style.css";

const Default_Function = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");
  let [d_v_m_thisweek, set_d_v_m_thisweek] = useState(1);

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];

  let health_box = {
    tab_header: [
      { name: "This Week", id: "this_week" },
      { name: "Next Week", id: "next_week" },
    ],
    this_week: [
      {
        name: "OSA",
        value: 77.21,
        value_suffix: "%",
        sub_value: 15.61,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "Sales",
        value: 30.02,
        value_suffix: "M",
        sub_value: 17.21,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Profits",
        value: 9,
        value_suffix: "M",
        sub_value: 15.38,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "Perfect Supply Rate",
        value: 49.58,
        value_suffix: "%",
        sub_value: 4.11,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "Fulfilment Rate",
        value: 68.34,
        value_suffix: "%",
        sub_value: 3.52,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
      {
        name: "On Time Rate",
        value: 68.98,
        value_suffix: "%",
        sub_value: 2.41,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
    ],
    next_week: [
      {
        name: "OSA",
        value: 71,
        value_suffix: "%",
        sub_value: 14.21,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "Sales",
        value: 28.22,
        value_suffix: "M",
        sub_value: 16.22,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Profits",
        value: 8.46,
        value_suffix: "M",
        sub_value: 16.3,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "Perfect Supply Rate",
        value: 45.18,
        value_suffix: "%",
        sub_value: 8.87,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
      {
        name: "FulFilment Rate",
        value: 4.56,
        value_suffix: "%",
        sub_value: 4.56,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
      {
        name: "On Time Rate",
        value: 66.71,
        value_suffix: "%",
        sub_value: 3.21,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
    ],
  };

  let summary = {
    this_week: [
      {
        text: "The OSA level for this week is 15.61% higher than the same week last year",
        mode: "",
      },
      {
        text: "This improves sales by 17.21% compared to the same week last year",
        mode: "",
      },
      {
        text: "Supply inbound fulfilment improvement of 6.8% is one of the leading factors to improved OSA.",
        mode: "",
      },
      {
        text: "View factors contributing to improved Inbound supply fulfilment at a SKU level.",
        mode: "",
      },
    ],
    next_week: [
      {
        text: "The OSA level for next week is 14.21% higher than the same week last year.",
        mode: "",
      },
      {
        text: "This is likely to improve sales by 16.22%",
        mode: "",
      },
      {
        text: "A drop of 4.56% in the inbound supply fulfilment is the leading factor for the decrease in sales/OSA.",
        mode: "",
      },
      {
        text: "View factors contributing to a decline in Inbound supply fulfilment at a SKU level.",
        mode: "",
      },
    ],
  };

  let what_happen = {
    this_week: {
      what_happen: [
        {
          text: "MC2000 and Trumedic are likely to have the highest lost sales this week with values of $10.13K and $8.25K",
          mode: "inc",
        },
        {
          text: "The Inbound Fulfilment for these segments are higher by 4% and 5% on average from the same week last year.",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "Supply availability of 76% and safety stock levels of 30% at the source are 2 of the leading factors contributing to inbound supply fulfilment",
          mode: "inc",
        },
      ],
    },
    next_week: {
      what_happen: [
        {
          text: "Earthlite and BMC200 are likely to have the highest lost sales next week with values of $11.34K and $10.49K",
          mode: "dec",
        },
        {
          text: "The Inbound Fulfilment for these segments are likely to decrease by 4% and 5% on average from the same week last year.",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "A 2% increase in inbound logistics delay and a 3% increase turnaround time at source locations, are 2 of contributing factors to a drop inbound fulfilment.",
          mode: "dec",
        },
      ],
    },
  };
  return (
    <>
      <RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => {
                  set_sel_com_view(e.currentTarget.value);
                  set_d_v_m_thisweek(1);
                }}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>
        <RB.Row>&nbsp;</RB.Row>

        {sel_com_view === "this_week" ? (
          <>
            <RB.Row>
              <HealthBox data={health_box["this_week"]} />
            </RB.Row>
            <RB.Row>
              <h5 className="summary_text">
                <b>Executive Summary</b>
              </h5>
            </RB.Row>
            <RB.Row>
              <Summary data={summary["this_week"]} />
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="inbound_view_card zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup
                        size="sm"
                        aria-label="Basic example"
                        className="font_Candara"
                      >
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"300vh"}
                      URL={
                        "https://public.tableau.com/views/inbound_dashboard/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiOGU0MTRhYTItZGY0ZC00ZmZjLWE5ZjgtNzA0MTk1NjFmMjFkIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1800}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappen data={what_happen["this_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"110vh"}
                      URL={
                        "https://public.tableau.com/views/inbound_dashboard_whatif/Dashboard2?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiNGZkN2RjYmItMTVjNy00NDE4LTkxOWUtMzk2MDJiNzkwNDRiIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={650}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <Calculation
                data={{
                  summary: "Perfect Supply Rate",
                  input1: "On Time Rate",
                  input2: "Supply Fulfilment Rate",
                  input3: "Prediction Variable",
                  drop_down: [
                    {
                      label: "OA",
                      value: 1,
                    },
                    {
                      label: "Sales",
                      value: 2,
                      isDisabled: false,
                    },
                    {
                      label: "Profit",
                      value: 3,
                      isDisabled: false,
                    },
                  ],
                }}
              />
            </RB.Row>
          </>
        ) : (
          <></>
        )}
        {sel_com_view === "next_week" ? (
          <>
            <RB.Row>
              <HealthBox data={health_box["next_week"]} />
            </RB.Row>
            <RB.Row>
              <h5 className="summary_text">Executive Summary</h5>
            </RB.Row>
            <RB.Row>
              <Summary data={summary["next_week"]} />
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="inbound_view_card zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup
                        size="sm"
                        aria-label="Basic example"
                        className="font_Candara"
                      >
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="card_body_view">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"300vh"}
                      URL={
                        "https://public.tableau.com/views/inbound_dashboard_NW/Dashboard-NW?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiODNmZGYwOWUtMDIyZC00Y2ViLTkyYTAtODQ1ZDA1M2U3ZjAwIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={1800}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Body>
                  <WhatHappen data={what_happen["next_week"]} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
          </>
        ) : (
          <></>
        )}
      </RB.Row>
    </>
  );
};

export default Default_Function;
