import * as RB from "react-bootstrap";
import React, { useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./style.css";

const Default_Function = (props) => {
  const [calculated_value, set_calculated_value] = useState(0);
  const [drop_down_value, set_drop_down_value] = useState("");
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);

  let calculate = () => {
    set_calculated_value((number1 * number2) / 100);
  };

  let drop_down_modified = (e) => {
    console.log("DROPDOWN LOGGER", e);
  };

  let update_number = (id, value) => {
    if (value > 100) {
      value = 100;
    }

    if (id === "id1") {
      setNumber1(value);
    }

    if (id === "id2") {
      setNumber2(value);
    }
  };

  return (
    <>
      <RB.Card className="zero_border_radius">
        <RB.Card.Body className="">
          <RB.Row>
            <h3>
              <b>Growth Simulator</b>
            </h3>
          </RB.Row>
          <RB.Row>
            <RB.Col md={3}>
              <RB.Card className="calculation_card zero_border_radius">
                <RB.Card.Body className="calculation_card_body">
                  <RB.Row>
                    <RB.Col md={8} className="text_center">
                      <h5 className="calculation_card_text">
                        {props.data["summary"]}
                      </h5>
                    </RB.Col>
                    <RB.Col md={4} className="text_center">
                      <h6 className="calculation_card_value">
                        {calculated_value + "%"}
                      </h6>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={9}>
              <RB.Row>
                <RB.Col md={3}>
                  <RB.Form.Label htmlFor="osa">
                    {props.data["input1"]}
                  </RB.Form.Label>
                  <RB.Form.Control
                    className="calculation_input"
                    type="number"
                    min={0}
                    max={100}
                    value={number1}
                    onChange={(e) => update_number("id1", e.target.value)}
                    id="osa"
                  />
                </RB.Col>
                <RB.Col md={3}>
                  <RB.Form.Label htmlFor="delivery_promise">
                    {props.data["input2"]}
                  </RB.Form.Label>
                  <RB.Form.Control
                    className="calculation_input"
                    type="number"
                    min={0}
                    max={100}
                    value={number2}
                    onChange={(e) => update_number("id2", e.target.value)}
                    id="delivery_promise"
                  />
                </RB.Col>
                <RB.Col md={3}>
                  <RB.Form.Label htmlFor="prediction_variable">
                    {props.data["input3"]}
                  </RB.Form.Label>
                  <Dropdown
                    id="prediction_variable"
                    options={props.data.drop_down}
                    onChange={drop_down_modified}
                    value={drop_down_value}
                    placeholder="Select"
                  />
                </RB.Col>
                <RB.Col md={3}>
                  <RB.Form.Label htmlFor="submit_btn">&nbsp;</RB.Form.Label>
                  <RB.Button
                    id="submit_btn"
                    className="calculation_btn"
                    variant="primary"
                    onClick={calculate}
                  >
                    Calculate
                  </RB.Button>
                </RB.Col>
              </RB.Row>
            </RB.Col>
          </RB.Row>
        </RB.Card.Body>
      </RB.Card>
    </>
  );
};

export default Default_Function;
