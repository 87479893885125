import * as RB from "react-bootstrap";
import { useState } from "react";
import HealthBox from "./healthbox";
import Summary from "./summary";
import Dashboard01 from "../../../images/outbound-images/outbound-this-week.png";
import Dashboard02 from "../../../images/outbound-images/outbound-next-week.png";
import Dashboard001 from "../../../images/outbound-images/outbound-page-001.jpg";
import WhatHappen from "../Inbound_Analytics/what_happen";

import "./style.css";

const Default_Function = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];

  let health = {
    this_week: [
      {
        name: "Delivery Promise",
        value: 62.31,
        value_suffix: "%",
        sub_value: 17.45,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "Fill Rate",
        value: 80.02,
        value_suffix: "%",
        sub_value: 19.21,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
      {
        name: "On Time Rate",
        value: 73.01,
        value_suffix: "%",
        sub_value: 18.42,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Perfect Order Rate",
        value: 58,
        value_suffix: "%",
        sub_value: 23.44,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
    ],
    next_week: [
      {
        name: "Delivery Promise",
        value: 60.3,
        value_suffix: "%",
        sub_value: 17.12,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "Fill Rate",
        value: 78.01,
        value_suffix: "M",
        sub_value: 19.21,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
      {
        name: "On Time Rate",
        value: 66.71,
        value_suffix: "M",
        sub_value: 3.21,
        sub_value_prefix: "-",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Perfect Order Rate",
        value: 55,
        value_suffix: "%",
        sub_value: 20.14,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon04",
      },
    ],
  };

  let summary = {
    this_week: [
      {
        text: "The Delivery Promise levels for next week is predicted to be around 70% which is 5% lower than this week",
        mode: "",
      },
      {
        text: "This is likely to result in decreased sales of 2% and a value of $ 1.2 million.",
        mode: "",
      },
      {
        text: "Next week, orders from Shippers 9, 16, and 17 are likely to be delayed with a total lost opportunity value of $33 Million across 27 different products",
        mode: "",
      },
      {
        text: "Place orders for 13 potentially delayed products with Shippers 12,15 and 16 for the lowest cost and shortest lead times, to deliver cost savings of $11 Million.",
        mode: "",
      },
    ],
    next_week: [
      {
        text: "The Delivery Promise levels for next week is predicted to be around 70% which is 5% lower than this week",
        mode: "",
      },
      {
        text: "This is likely to result in decreased sales of 2% and a value of $ 1.2 million.",
        mode: "",
      },
      {
        text: "Next week, orders from Shippers 9, 16, and 17 are likely to be delayed with a total lost opportunity value of $33 Million across 27 different products",
        mode: "",
      },
      {
        text: "Place orders for 13 potentially delayed products with Shippers 12,15 and 16 for the lowest cost and shortest lead times, to deliver cost savings of $11 Million.",
        mode: "",
      },
    ],
  };

  let what_happen = {
    this_week: {
      what_happen: [
        {
          text: "SKU categories Sycamore and BMC 400 had the highest profit values of $1mil and 89k.",
          mode: "inc",
        },
        {
          text: "The CFR for these segments improved by 3 and 4% on average from the same week last year.",
          mode: "inc",
        },
      ],
      what_did_happen: [
        {
          text: "A 4% increase in availability and 2% increase in safety stock are the 2 leading contributors.",
          mode: "inc",
        },
      ],
    },
  };

  return (
    <>
      <RB.Row>

      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => set_sel_com_view(e.currentTarget.value)}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>
        {sel_com_view === "this_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["this_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text"><b>Executive Summary</b></h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["this_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="o2d_view_card zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius card_header_blue">
                  * All growth values are in comparison with the same week the
                  previous FY by default
                </RB.Card.Header>
                <RB.Card.Body className="">
                  <img src={Dashboard01} alt={Dashboard01} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <WhatHappen data={what_happen["this_week"]} />
            </RB.Row>

            <RB.Row>&nbsp;</RB.Row>

            <RB.Row>
              <RB.Row>
                <RB.Card className="o2d_view_card_1 zero_padding zero_border_radius ">
                  <RB.Card.Header className="zero_border_radius card_header_blue">
                    * All growth values are in comparison with the same week the
                    previous FY by default
                  </RB.Card.Header>
                  <RB.Card.Body className="">
                    <img src={Dashboard001} alt={Dashboard001} />
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
        {sel_com_view === "next_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["next_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text"><b>Executive Summary</b></h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["next_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="o2d_view_card zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius card_header_blue">
                  * All growth values are in comparison with the same week the
                  previous FY by default
                </RB.Card.Header>
                <RB.Card.Body className="">
                  <img src={Dashboard02} alt={Dashboard02} />
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Row>
                <RB.Card className="o2d_view_card_1 zero_padding zero_border_radius ">
                  <RB.Card.Header className="zero_border_radius card_header_blue">
                    * All growth values are in comparison with the same week the
                    previous FY by default
                  </RB.Card.Header>
                  <RB.Card.Body className="">
                    <img src={Dashboard001} alt={Dashboard001} />
                  </RB.Card.Body>
                </RB.Card>
              </RB.Row>
            </RB.Row>
          </>
        ) : (
          <></>
        )}
      </RB.Row>
    </>
  );
};

export default Default_Function;
