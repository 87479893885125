import * as RB from "react-bootstrap";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import LatentviewLogo from "../../images/latent-view-analytics-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import * as service from "../app_service";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const Header = (props) => {
  const history = useNavigate();
  const [user, set_user] = useState({});

  let logout = () => {
    service.logout();
    history("/login");
  };

  let fetch_user_obj = () => {
    let user_obj = service.get_user_obj();
    if (user_obj !== null && user_obj["email"] !== "") {
      set_user(user_obj);
    } else {
      history("/login");
    }
  };

  useEffect(() => {
    fetch_user_obj();
  }, []);

  return (
    <RB.Row className="null_p_m header_content">
      <RB.Row className="null_p_m">
        <RB.Col md={1}>
          <img
            className="header_lvlogo"
            src={LatentviewLogo}
            alt={LatentviewLogo}
          />
        </RB.Col>
        <RB.Col md={4} className=""></RB.Col>
        <RB.Col md={7} className="common_calender_content">
          <span className="com_calender_p_r25">Hi, {user.displayName}</span>
          <span>
            <FontAwesomeIcon
              className="logout_icon com_calender_p_r25"
              icon={faSignOut}
              title="Logout"
              onClick={() => {
                logout();
              }}
            />
          </span>
        </RB.Col>
      </RB.Row>
    </RB.Row>
  );
};

export default Header;
