import * as RB from "react-bootstrap";
import POWERBI from "../../DashboardViews/POWERBI";
import Anomalies from "../../../images/anomalies.jpg";
import SupplyChain from "../../../images/supply-chain.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row>
        <RB.Col md={6}>
          <RB.Card className="zero_border_radius">
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={10}>
                  <h5>
                    <b>Your Connected Visibility</b>
                  </h5>
                </RB.Col>
                <RB.Col md={2} className="text_right con_visiility_header">
                  <img
                    className="conn_visibility_icon"
                    src={SupplyChain}
                    alt={SupplyChain}
                  />
                </RB.Col>
              </RB.Row>
              <RB.Row>
                <POWERBI
                  height={"300"}
                  URL={
                    "https://app.powerbi.com/view?r=eyJrIjoiZDMwYmI1MjQtNjZkYS00MGYyLWFmNDItODM1ZGQ5NmE0OWRjIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                  }
                />
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
        <RB.Col md={6} className="no_r_padding">
          <RB.Card className="anomaly_card zero_border_radius">
            <RB.Card.Body>
              <RB.Row className="anomaly_content">
                <RB.Col md={9}>
                  <h5>
                    <b>What’s disrupting your network</b>
                  </h5>
                </RB.Col>
                <RB.Col
                  md={3}
                  className="text_right anomaly_icon"
                  onClick={() => {
                    props.visibility.left_pan_selector("anomalies");
                  }}
                >
                  <img
                    className="anomaly_danger"
                    src={Anomalies}
                    alt={Anomalies}
                  />
                  <FontAwesomeIcon className="anomaly_bell" icon={faBell} />
                </RB.Col>
              </RB.Row>
              <RB.Row>
                {props.visibility.f_anomalies.map((value) => {
                  if (value["anomolies"] !== "") {
                    return (
                      <RB.Card className="zero_border_radius m_b10">
                        <RB.Card.Body className="zero_padding">
                          {value["anomolies"]}
                        </RB.Card.Body>
                      </RB.Card>
                    );
                  }
                })}
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
