import * as RB from "react-bootstrap";
import POWERBI from "../../DashboardViews/POWERBI";
import Anomalies from "../../../images/anomalies.jpg";
import SupplyChain from "../../../images/supply-chain.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Map from "../google_map"

import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row>
        <RB.Col md={6}>
          <RB.Card className="cpg_anomaly_card zero_border_radius shadow">
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={9}>
                  <h5>
                    <b>Your Connected Visibility</b>
                  </h5>
                </RB.Col>
                <RB.Col
                  md={3}
                  className="text_right con_visiility_header"
                  onClick={() => {
                    props.visibility.left_pan_selector("connected_visibility");
                  }}
                >
                  <img
                    className="conn_visibility_icon"
                    src={SupplyChain}
                    alt={SupplyChain}
                  />
                </RB.Col>
              </RB.Row>
              <RB.Row>
                <RB.Col md={7}>&nbsp;</RB.Col>
                <RB.Col md={5} className="text_right">
                  {"Explore Your Network"}
                </RB.Col>
              </RB.Row>
              <RB.Row>
                <POWERBI
                  height={"300"}
                  URL={
                    "https://app.powerbi.com/view?r=eyJrIjoiMTZhYmMwODItZmYyOS00NzBhLTg3NmMtMDk4NzlmZDA0NmZhIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                  }
                />
                {/* <Map zoom={4}></Map> */}
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
        <RB.Col md={6} className="no_r_padding">
          <RB.Card className="cpg_anomaly_card shadow zero_border_radius">
            <RB.Card.Body>
              <RB.Row>
                <RB.Col md={9}>
                  <h5>
                    <b>What’s disrupting your network</b>
                  </h5>
                </RB.Col>
                <RB.Col
                  md={3}
                  className="text_right cpg_anomaly_icon"
                  onClick={() => {
                    props.visibility.left_pan_selector("anomalies");
                  }}
                >
                  <img
                    className="anomaly_danger"
                    src={Anomalies}
                    alt={Anomalies}
                  />
                  <span>
                    <FontAwesomeIcon
                      className="anomaly_bell_icon anomaly_bell"
                      icon={faBell}
                    />
                    <span>
                      <p className="notifier">{"3"}</p>
                    </span>
                  </span>
                </RB.Col>
              </RB.Row>
              <RB.Row className="cpg_anomaly_content">
                <RB.Col md={9}> &nbsp;</RB.Col>
                <RB.Col md={3} className="text_right">
                  {"Anomalies"}
                </RB.Col>
              </RB.Row>
              <RB.Row className="cpg_anomalie_list_row">
                {props.visibility.f_anomalies.map((value, index) => {
                  if (value["Statement"] !== "") {
                    return (
                      <RB.Card
                        className={
                          index === 0
                            ? "zero_border_radius cpg_m_b5 cpg_anomalie_list_selected"
                            : "zero_border_radius cpg_m_b5 cpg_anomalie_list"
                        }
                      >
                        <RB.Card.Body className="zero_padding">
                          {value["Statement"]}
                        </RB.Card.Body>
                      </RB.Card>
                    );
                  }
                })}
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
