import * as RB from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPenToSquare,
  faNetworkWired,
  faMagnifyingGlassChart,
  faChartColumn,
  faScrewdriverWrench,
  faMarsStrokeRight,
} from "@fortawesome/free-solid-svg-icons";
import ToolTip from "../../Tooltip";
import "./style.css";

const SidebarCPG = (props) => {
  return (
    <RB.Row className="retail_sidebar_rework">
      {props.side_bar_navs.map((list, index) => (
        <>
          <ToolTip tooltip={list["tool_tip"]} href="#" id="retail_tooltip">
            <div
              onClick={() => {
                props.mode_from_side_bar(list);
              }}
            >
              {list["icon_mode"] === "img" ? (
                <div>
                  <img
                    className="retail_left_bar_img"
                    src={require(`../../../images/inventory-images/${list["icon_name"]}.png`)}
                    alt={require(`../../../images/inventory-images/${list["icon_name"]}.png`)}
                  />
                </div>
              ) : (
                <></>
              )}

              {list["icon_name"] === "faPenToSquare" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faPenToSquare}
                  alt={faPenToSquare}
                />
              ) : (
                <></>
              )}

              {list["icon_name"] === "faNetworkWired" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faNetworkWired}
                  alt={faNetworkWired}
                />
              ) : (
                <></>
              )}

              {list["icon_name"] === "faHome" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faHome}
                  alt={faHome}
                />
              ) : (
                <></>
              )}

              {list["icon_name"] === "faChartColumn" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faChartColumn}
                  alt={faChartColumn}
                />
              ) : (
                <></>
              )}

              {list["icon_name"] === "faScrewdriverWrench" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faScrewdriverWrench}
                />
              ) : (
                <></>
              )}
              {list["icon_name"] === "faMagnifyingGlassChart" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faMagnifyingGlassChart}
                />
              ) : (
                <></>
              )}
              {list["icon_name"] === "faMarsStrokeRight" ? (
                <FontAwesomeIcon
                  className="retail_left_bar_icon"
                  icon={faMarsStrokeRight}
                  alt={faMarsStrokeRight}
                />
              ) : (
                <></>
              )}
            </div>
          </ToolTip>
        </>
      ))}
      {/* <div>
        <FontAwesomeIcon className="retail_left_bar_icon" icon={faUserAlt} />
      </div> */}
    </RB.Row>
  );
};

export default SidebarCPG;
