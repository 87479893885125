import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
const Chatbot = () => {
  const history = useNavigate();

  let chatbot_clicker = () => {
    console.log("CHAT BOT CLICKER");
    // history("/chatbot");
  };
  return (
    <div className="chatbot">
      <FontAwesomeIcon
        onClick={() => {
          chatbot_clicker();
        }}
        className="chatbot_svg"
        icon={faCommentDots}
      />
    </div>
  );
};

export default Chatbot;
