import * as RB from "react-bootstrap";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

const Default_Function = (props) => {
  return (
    <RB.Card className="pe_summary_min_height zero_border_radius">
      <RB.Card.Body className="font_13">
        {props.data.map((item, index) => (
          <RB.Row>
          <RB.Col md={1} className="zero_padding">
            <FontAwesomeIcon className="summary_arrow" icon={faLongArrowRight} />
          </RB.Col>
          <RB.Col md={11} className="zero_padding">
            {item["text"]}
          </RB.Col>
        </RB.Row>
        ))}
      </RB.Card.Body>
    </RB.Card>
  );
};

export default Default_Function;
