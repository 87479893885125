import * as RB from "react-bootstrap";
import HealthCheck from "./Health_check";
import Visibility from "./visibillity";
import Optimization from "./connected_optimization";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <HealthCheck Health={props.health_check} />
        <RB.Row>&nbsp;</RB.Row>
        <Visibility visibility={props.visibility} />
        <RB.Row>&nbsp;</RB.Row>
        <Optimization optimization={props.optimization} />
    </>
  );
};

export default Default_Function;
