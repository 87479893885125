import * as RB from "react-bootstrap";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row className="multi_box_card_row">
        {props.data.map((d_item, index) => (
          <RB.Card className="multi_box_card zero_padding zero_border_radius">
            <RB.Card.Header className="zero_border_radius card_header_blue">
              <h4 className="multi_box_card_header">{d_item["header"]}</h4>
            </RB.Card.Header>
            <RB.Card.Body className="multibox_body">
              <RB.Row>
                <h4 className="multibox_value">
                  {d_item["value_prefix"]}
                  {d_item["value"]}
                  {d_item["value_suffix"]}
                </h4>
              </RB.Row>
              <RB.Row>
                <h5 className="multibox_sub_value">
                  {d_item["sub_value_prefix"]}
                  {d_item["sub_value"]}
                  {d_item["sub_value_suffix"]}
                </h5>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        ))}
      </RB.Row>
    </>
  );
};

export default Default_Function;
