import { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import Header from "./Header";
import Footer from "../Footer";
import SideBar from "./Sidebar";
import Landing from "./landing";
import Accelerator from "./accelerator";
import Anomalies from "./anomalies";
import industrials_data from "./data_hub/source_data.json";

import "./style.css";

const Default_Function = () => {
  const [colour_code] = useState({
    orange: "#ed9853",
    green: "#46db46",
    blue: "#7163db",
    yellow: "#c5c754",
  });
  const [industrials_src] = useState(industrials_data);
  const [f_anomalies, set_f_anomalies] = useState([]);
  const [service_level, set_service_level] = useState([]);
  const [pan_view_mode, set_pan_view_mode] = useState("home");
  const [bi_links] = useState(industrials_src["bi_links"]);
  const [month_picker] = useState(industrials_src["month_picker"]);
  const [supplier_ricks_graph, set_supplier_ricks_graph] = useState({});
  const [inventory_ricks_graph, set_inventory_ricks_graph] = useState({});
  const [responsiveness_graph, set_responsiveness_graph] = useState({});
  const [quality_graph, set_quality_graph] = useState({});

  let get_data_till_index = (src_data, index) => {
    let new_data = [];
    for (let i = 0; i <= index; i++) {
      new_data.push(src_data[i]);
    }
    return new_data;
  };

  let filter_the_graph_view = (month, src_data) => {
    let index = industrials_src["available_months"].indexOf(month["value"]);
    if (index <= 3) {
      index = 3;
    }
    let copy_src_data = JSON.parse(JSON.stringify(src_data));
    let new_label = get_data_till_index(copy_src_data["labels"], index);
    copy_src_data["labels"] = new_label;
    for (let i = 0; i < copy_src_data["datasets"].length; i++) {
      let m_d = get_data_till_index(
        copy_src_data["datasets"][i]["data"],
        index
      );
      copy_src_data["datasets"][i]["data"] = m_d;
    }
    return copy_src_data;
  };

  let prepare_chart_supplier_risks = () => {
    let map_obj = {
      header: "Supplier Risks",
      labels: industrials_src["available_months"],
      datasets: [
        {
          label: "% of OTIF",
          data: [51.67, 53.76, 55.66, 50.0, 56.16, 54.39, 57.14, 59.74],
          borderColor: colour_code["green"],
          backgroundColor: colour_code["green"],
        },
        {
          label: "% of orders cancelled",
          data: [21.05, 16.22, 23.74, 16.2, 17.05, 13.64, 22.22, 18.95],
          borderColor: colour_code["orange"],
          backgroundColor: colour_code["orange"],
        },
        {
          label: "Transit delays",
          data: [13.33, 7.53, 12.26, 9.94, 5.48, 7.02, 10.71, 7.79],
          borderColor: colour_code["yellow"],
          backgroundColor: colour_code["yellow"],
        },
        {
          label: "% of shortages",
          data: [27.0, 30.11, 24.53, 30.0, 30.14, 36.84, 30.95, 20.0],
          borderColor: colour_code["blue"],
          backgroundColor: colour_code["blue"],
        },
      ],
    };
    return map_obj;
  };

  let prepare_chart_inventory_risks = () => {
    let map_obj = {
      header: "Inventory Risks",
      labels: industrials_src["available_months"],
      datasets: [
        {
          label: "Inventory Available",
          data: [62.94, 69.6, 69.0, 81.72, 75.9, 81.05, 87.12, 85.28],
          borderColor: colour_code["green"],
          backgroundColor: colour_code["green"],
        },
        {
          label: "Inventory Risks",
          data: [19.99, 24.62, 17.97, 12.89, 20.89, 18.42, 5.11, 7.21],
          borderColor: colour_code["orange"],
          backgroundColor: colour_code["orange"],
        },
      ],
    };
    return map_obj;
  };

  let prepare_chart_responsiveness = () => {
    let map_obj = {
      header: "Responsiveness",
      labels: industrials_src["available_months"],
      datasets: [
        {
          label: "Orders On Time",
          data: [69.7, 83.55, 56.39, 79.65, 66.89, 85.47, 83.93, 95.96],
          borderColor: colour_code["green"],
          backgroundColor: colour_code["green"],
        },
        {
          label: "Orderd Delayed",
          data: [30.3, 16.45, 43.61, 20.35, 33.11, 14.53, 16.07, 4.04],
          borderColor: colour_code["orange"],
          backgroundColor: colour_code["orange"],
        },
      ],
    };
    return map_obj;
  };

  let prepare_chart_quality = () => {
    let map_obj = {
      header: "Quality",
      labels: industrials_src["available_months"],
      datasets: [
        {
          label: "Raw Material Quality",
          data: [83.75, 87.63, 84.95, 83.8, 85.62, 89.09, 95.24, 93.51],
          borderColor: colour_code["orange"],
          backgroundColor: colour_code["orange"],
        },
        {
          label: "Promised Lead Time",
          data: [69.7, 83.55, 56.39, 79.65, 66.89, 85.47, 83.93, 95.96],
          borderColor: colour_code["green"],
          backgroundColor: colour_code["green"],
        },
        {
          label: "Defect Free Delivery",
          data: [77.07, 87.28, 72.15, 82.66, 77.83, 85.9, 86.52, 88.68],
          borderColor: colour_code["yellow"],
          backgroundColor: colour_code["yellow"],
        },
        {
          label: "Yield Rate",
          data: [83.33, 93.91, 89.56, 92.51, 97.11, 90.8, 97.65, 94.69],
          borderColor: colour_code["blue"],
          backgroundColor: colour_code["blue"],
        },
      ],
    };
    return map_obj;
  };

  let selected_month = (e) => {
    set_f_anomalies(industrials_src["anamolies"][e["value"]]);
    set_service_level(industrials_src["service_level"][e["value"]]);
  };

  let left_pan_selector = (trigger_bar) => {
    set_pan_view_mode(trigger_bar);
  };

  let month_child_select = (month) => {
    selected_month(month);
    filter_graph_initial_select(month);
  };

  let date_event_change = (e) => {
    let month_hub = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Auguest",
      "September",
      "October",
      "November",
      "December",
    ];
    let m = e.getMonth();
    if (m <= 4) {
      m = 4;
    }
    month_child_select({
      label: month_hub[m],
      value: month_hub[m],
    });
  };

  let filter_graph_initial_select = (month) => {
    set_supplier_ricks_graph(
      filter_the_graph_view(month, prepare_chart_supplier_risks())
    );
    set_inventory_ricks_graph(
      filter_the_graph_view(month, prepare_chart_inventory_risks())
    );
    set_responsiveness_graph(
      filter_the_graph_view(month, prepare_chart_responsiveness())
    );
    set_quality_graph(filter_the_graph_view(month, prepare_chart_quality()));
  };

  useEffect(() => {
    selected_month(month_picker[month_picker.length - 1]);
    filter_graph_initial_select(month_picker[month_picker.length - 1]);
  }, []);

  return (
    <>
      <Header
        month_child_select={month_child_select}
        month_picker={month_picker}
        pan_view_mode={pan_view_mode}
        date_event_change={date_event_change}
      ></Header>
      <SideBar left_pan_selector={left_pan_selector}></SideBar>
      <RB.Row className="landing_content">
        {pan_view_mode === "home" ? (
          <Landing
            health_check={{
              health: service_level,
              f_anomalies: f_anomalies,
            }}
            chart={{
              supplier_ricks_graph: supplier_ricks_graph,
              inventory_ricks_graph: inventory_ricks_graph,
              responsiveness_graph: responsiveness_graph,
              quality_graph: quality_graph,
            }}
            visibility={{
              f_anomalies: f_anomalies,
              left_pan_selector: left_pan_selector,
            }}
          ></Landing>
        ) : (
          <></>
        )}
        {pan_view_mode === "accelerator" ? (
          <Accelerator bi_links={bi_links} />
        ) : (
          <></>
        )}
        {pan_view_mode === "anomalies" ? (
          <Anomalies f_anomalies={f_anomalies} />
        ) : (
          <></>
        )}
      </RB.Row>
      <Footer></Footer>
    </>
  );
};

export default Default_Function;
