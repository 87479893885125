import * as RB from "react-bootstrap";
import { useState } from "react";
import HealthBox from "./healthbox";
import Summary from "./summary";
import WhatHappen from "../Inbound_Analytics/what_happen";
import TabuleaView from "../../DashboardViews/TABLEAU";
import POWERBI from "../../DashboardViews/POWERBI";
import "./style.css";

const Default_Function = () => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");
  let [d_v_m_thisweek, set_d_v_m_thisweek] = useState(1);

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];
  let health = {
    this_week: [
      {
        name: "Optimal Availability",
        value: 56.91,
        value_suffix: "%",
        sub_value: 20,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "OSA",
        value: 76.5,
        value_suffix: "%",
        sub_value: 15.61,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Delivery Promise",
        value: 62.31,
        value_suffix: "%",
        sub_value: 17.45,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
    ],
    next_week: [
      {
        name: "Optimal Availability",
        value: 42.81,
        value_suffix: "%",
        sub_value: 20,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon01",
      },
      {
        name: "OSA",
        value: 76.5,
        value_suffix: "%",
        sub_value: 15.61,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon03",
      },
      {
        name: "Delivery Promise",
        value: 62.31,
        value_suffix: "%",
        sub_value: 17.45,
        sub_value_prefix: "+",
        sub_value_suffix: "%",
        icon: "Icon02",
      },
    ],
  };

  let summary = {
    this_week: [
      {
        text: "The OSA level for this week is 15.61% higher than the same week last year",
        mode: "",
      },
      {
        text: "This improves sales by 17.21% compared to the same week last year",
        mode: "",
      },
      {
        text: "North has the highest OSA of 81.83% and west has the lowest of 71.06%",
        mode: "",
      },
    ],
    next_week: [
      {
        text: "The OSA level for next week is 14.21% higher than the same week last year",
        mode: "",
      },
      {
        text: "This is likely to improve sales by 16.22%",
        mode: "",
      },
      {
        text: "North has the highest OSA of 77.13% and west has the lowest of 66.03%.",
        mode: "",
      },
    ],
  };

  let what_happen = {
    this_week: {
      what_happen: [
        {
          text: "Mc2000 and Trumedic are the products with highest lost sales of $27.50K and $26.14K for the particular month and week respectively.",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "Forecast Accuracy and On Time Fulfilment are the leading contributors for the drop in OSA for MC2000",
          mode: "inc",
        },
      ],
    },
    next_week: {
      what_happen: [
        {
          text: "Mc2000 and Trumedic are the products with highest lost sales of $26.7K and $21.95K for the particular month and week respectively.",
          mode: "dec",
        },
      ],
      what_did_happen: [
        {
          text: "Forecast Accuracy and On Time Fulfilment are the leading contributors for the drop in OSA for MC2000",
          mode: "inc",
        },
      ],
    },
  };

  return (
    <>
      <RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => set_sel_com_view(e.currentTarget.value)}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>
        {sel_com_view === "this_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["this_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text">
                    <b>Executive Summary</b>
                  </h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["this_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"300vh"}
                      URL={
                        "https://public.tableau.com/views/OSA_TW3/OA-TW2?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiZWI4MWU4N2UtMTA3My00YmJhLTg1MDEtMmJiM2NjYzUxNGJkIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={2500}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <WhatHappen data={what_happen["this_week"]} />
            </RB.Row>
          </>
        ) : (
          <></>
        )}
        {sel_com_view === "next_week" ? (
          <>
            <RB.Row>
              <RB.Col md={9}>
                <RB.Row>
                  <h4>&nbsp;</h4>
                </RB.Row>
                <HealthBox data={health["next_week"]} />
              </RB.Col>
              <RB.Col md={3}>
                <RB.Row>
                  <h4 className="summary_text">
                    <b>Executive Summary</b>
                  </h4>
                </RB.Row>
                <RB.Row>
                  <Summary data={summary["next_week"]} />
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <RB.Card className="zero_padding zero_border_radius ">
                <RB.Card.Header className="zero_border_radius">
                  <RB.Row>
                    <RB.Col md={10}>
                      * All growth values are in comparison with the same week
                      the previous FY by default
                    </RB.Col>
                    <RB.Col md={2}>
                      <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(1);
                          }}
                        >
                          Tableau
                        </RB.Button>
                        <RB.Button
                          className="zero_border_radius"
                          variant={
                            d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                          }
                          onClick={() => {
                            set_d_v_m_thisweek(2);
                          }}
                        >
                          Power BI
                        </RB.Button>
                      </RB.ButtonGroup>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Header>
                <RB.Card.Body className="">
                  {d_v_m_thisweek === 1 ? (
                    <TabuleaView
                      WIDTH={"100%"}
                      HEIGHT={"300vh"}
                      URL={
                        "https://public.tableau.com/views/OSA_NW3/OA-NW2?:language=en-US&:display_count=n&:origin=viz_share_link"
                      }
                    />
                  ) : (
                    <POWERBI
                      URL={
                        "https://app.powerbi.com/view?r=eyJrIjoiZmRhMDI3NmQtZGRlYi00YmY5LTk1YWEtOTVkMjAxZDk3ZGI2IiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                      }
                      height={2500}
                    />
                  )}
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>
              <WhatHappen data={what_happen["next_week"]} />
            </RB.Row>
          </>
        ) : (
          <></>
        )}
      </RB.Row>
    </>
  );
};

export default Default_Function;
