import * as RB from "react-bootstrap";
import Icon01 from "../../../images/forecasting-images/icon-01.png";
import Icon02 from "../../../images/forecasting-images/icon-02.jpg";
import Icon03 from "../../../images/forecasting-images/icon-03.jpg";
import Icon04 from "../../../images/forecasting-images/icon-04.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row>
        <RB.Col md={12}>
          <RB.Row>
            <RB.Col md={3}>
              <RB.Card className="as_health_p_card zero_padding zero_border_radius">
                <RB.Card.Body className="health_p_card_common">
                  <RB.Row>
                    <img
                      className="as_heath_p_card_icon"
                      src={Icon01}
                      alt={Icon01}
                    />
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row>
                    <h3>{props.data["card"]["name"]}</h3>
                  </RB.Row>
                  <RB.Row>
                    <h5 className="as_health_p_value">
                      {props.data["card"]["value"]}
                      {props.data["card"]["value_suffix"]}
                    </h5>
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row>
                    <h5 className="as_health_p_subvalue">
                      {props.data["card"]["sub_value_prefix"]}
                      {props.data["card"]["sub_value"]}
                      {props.data["card"]["sub_value_suffix"]}
                    </h5>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={9}>
              <RB.Row className="as_healh_repeat">
                {props.data.sub_card.map((d_item, index) => (
                  <RB.Card className="as_healh_card zero_padding zero_border_radius">
                    <RB.Card.Body>
                      <RB.Row>
                        <RB.Col md={1}>
                          {d_item["icon"] === "faChartArea" ? (
                            <FontAwesomeIcon
                              className="as_healh_card_icon"
                              icon={faChartArea}
                            />
                          ) : (
                            <></>
                          )}
                          {d_item["icon"] === "Icon02" ? (
                            <img
                              className="as_heath_p_card_img"
                              src={Icon02}
                              alt={Icon02}
                            />
                          ) : (
                            <></>
                          )}
                          {d_item["icon"] === "Icon03" ? (
                            <img
                              className="as_heath_p_card_img"
                              src={Icon03}
                              alt={Icon03}
                            />
                          ) : (
                            <></>
                          )}
                          {d_item["icon"] === "Icon04" ? (
                            <img
                              className="as_heath_p_card_img"
                              src={Icon04}
                              alt={Icon04}
                            />
                          ) : (
                            <></>
                          )}
                        </RB.Col>
                        <RB.Col md={11}>
                          <h4 className="text_center">{d_item["name"]}</h4>
                        </RB.Col>
                      </RB.Row>
                      <RB.Row>
                        <RB.Col md={6} className="text_center">
                          <RB.Row>
                            <h5 className="as_healh_card_value">
                              {d_item["value_prefix"]}
                              {d_item["value"]}
                              {d_item["value_suffix"]}
                            </h5>
                          </RB.Row>
                          <RB.Row>
                            <h6 className="as_healh_card_sub_value text_green">
                              {d_item["sub_value"] ? (
                                <>
                                  {"("}
                                  {d_item["sub_value_prefix"]}
                                  {d_item["sub_value"]}
                                  {d_item["sub_value_suffix"]}
                                  {")"}
                                </>
                              ) : (
                                <></>
                              )}
                            </h6>
                          </RB.Row>
                        </RB.Col>
                        <RB.Col md={6} className="text_center">
                          <RB.Row>
                            {d_item["value1"] ? (
                              <>
                                <h5 className="as_healh_card_value">
                                  {d_item["value1_prefix"]}
                                  {d_item["value1"]}
                                  {d_item["value1_suffix"]}
                                </h5>
                              </>
                            ) : (
                              <></>
                            )}
                          </RB.Row>
                          <RB.Row>
                            <h6 className="as_healh_card_sub_value text_green">
                              {d_item["sub_value1"] ? (
                                <>
                                  {"("}
                                  {d_item["sub_value1_prefix"]}
                                  {d_item["sub_value1"]}
                                  {d_item["sub_value1_suffix"]}
                                  {")"}
                                </>
                              ) : (
                                <></>
                              )}
                            </h6>
                          </RB.Row>
                        </RB.Col>
                      </RB.Row>
                    </RB.Card.Body>
                  </RB.Card>
                ))}
              </RB.Row>
            </RB.Col>
          </RB.Row>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
