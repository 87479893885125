import * as RB from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faAsterisk,
  faChartArea,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const Default_Function = (props) => {
  let acc_view_mode = (mode) => {
    props.change_tools_with_dashboard("tools_acc", mode);
  };

  return (
    <>
      {props.tools_acc_view_mode === "" ? (
        <>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>&nbsp;</RB.Row>
          <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
          <RB.Row>
            <h3>Tools and Accelerators</h3>
          </RB.Row>
          <RB.Row>
            <RB.Col md={3}>
              <RB.Card className="zero_border_radius">
                <RB.Card.Body className="acc_card_body">
                  <RB.Row
                    onClick={() => {
                      acc_view_mode("inbound_analytics");
                    }}
                  >
                    <RB.Col md={2} className="acc_card_icon_col">
                      <FontAwesomeIcon
                        className="acc_card_icon_icon"
                        icon={faAsterisk}
                      />
                    </RB.Col>
                    <RB.Col md={10}>
                      <h4 className="acc_card_text">Inbound Analytics</h4>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={3}>
              <RB.Card className="zero_border_radius">
                <RB.Card.Body className="acc_card_body">
                  <RB.Row
                    onClick={() => {
                      acc_view_mode("associative_forecasting");
                    }}
                  >
                    <RB.Col md={2} className="acc_card_icon_col">
                      <FontAwesomeIcon
                        className="acc_card_icon_icon"
                        icon={faLayerGroup}
                      />
                    </RB.Col>
                    <RB.Col md={10}>
                      <h4 className="acc_card_text">Associative Forecasting</h4>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={3}>
              <RB.Card className="zero_border_radius">
                <RB.Card.Body className="acc_card_body">
                  <RB.Row
                    onClick={() => {
                      acc_view_mode("o2d_analytics");
                    }}
                  >
                    <RB.Col md={2} className="acc_card_icon_col">
                      <FontAwesomeIcon
                        className="acc_card_icon_icon"
                        icon={faChartArea}
                      />
                    </RB.Col>
                    <RB.Col md={10}>
                      <h4 className="acc_card_text">O2D Analytics</h4>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={3}>
              <RB.Card className="zero_border_radius">
                <RB.Card.Body className="acc_card_body">
                  <RB.Row
                    onClick={() => {
                      acc_view_mode("echelon_sense");
                    }}
                  >
                    <RB.Col md={2} className="acc_card_icon_col">
                      <FontAwesomeIcon
                        className="acc_card_icon_icon"
                        icon={faChartArea}
                      />
                    </RB.Col>
                    <RB.Col md={10}>
                      <h4 className="acc_card_text">Echelon Sense</h4>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
          </RB.Row>
          <RB.Row> &nbsp;</RB.Row>
          <RB.Row>
            <RB.Col md={3}>
              <RB.Card className="zero_border_radius">
                <RB.Card.Body className="acc_card_body">
                  <RB.Row
                    onClick={() => {
                      acc_view_mode("optimal_optimizer");
                    }}
                  >
                    <RB.Col md={2} className="acc_card_icon_col">
                      <FontAwesomeIcon
                        className="acc_card_icon_icon"
                        icon={faIndustry}
                      />
                    </RB.Col>
                    <RB.Col md={10}>
                      <h4 className="acc_card_text">
                        Optimal Availability Optimiser
                      </h4>
                    </RB.Col>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
          </RB.Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Default_Function;
