import { useNavigate } from "react-router-dom";
import "./style.css";
import * as RB from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";

const AllDashboard = () => {
  return (
    <>
      <Header />
      <RB.Row className="popins_font font_size_13 bg_gray">
        <RB.Row>
          <RB.Col md={3}>
            <RB.Card>
              <RB.Card.Body>
                <h2>This is some text within a card body.</h2>
              </RB.Card.Body>
            </RB.Card>
          </RB.Col>
          <RB.Col md={9}>
            <RB.Row>
              <RB.Card>
                <RB.Card.Body>
                  This is some text within a card body.
                </RB.Card.Body>
              </RB.Card>
            </RB.Row>
            <RB.Row>
              <RB.Col md={12}>
                <RB.InputGroup className="mb-3">
                  <RB.Form.Control
                    placeholder="Ask Your Questions"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <RB.Button variant="success" id="button-addon2">
                    Submit
                  </RB.Button>
                </RB.InputGroup>
              </RB.Col>
            </RB.Row>
          </RB.Col>
        </RB.Row>
      </RB.Row>
      <Footer />
    </>
  );
};

export default AllDashboard;
