import { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import Header from "./Header";
import Footer from "../Footer";
import SideBar from "./Sidebar";
import Landing from "./landing";
import Anomalies from "./anomalies";
import ConnectedVisibility from "./Connected_visibility";
// import PEPSICO from "./Pepsico"
import POWERBI from "../DashboardViews/POWERBI";
import TABULEA from "../DashboardViews/TABLEAU";
import { API_BASE_PATH, API_DEFAULT_HEADER } from "../app_service";

import "./style.css";
import "./style.scss";

const getAnomalies = async () => {
  const anomalise = await fetch(
    `${API_BASE_PATH}/anomalise`,
    API_DEFAULT_HEADER
  );
  return await anomalise.json();
};

const getQuickSummary = async () => {
  const quicksummary = await fetch(
    `${API_BASE_PATH}/quicksummary`,
    API_DEFAULT_HEADER
  );
  return await quicksummary.json();
};

const getCaseFillRate = async () => {
  const casefilerate = await fetch(
    `${API_BASE_PATH}/casefillrate`,
    API_DEFAULT_HEADER
  );
  return await casefilerate.json();
};

const getServiceLevel = async () => {
  const serviceLevel = await fetch(
    `${API_BASE_PATH}/servicelevel`,
    API_DEFAULT_HEADER
  );
  return await serviceLevel.json();
};

const getOOS = async () => {
  const outofstock = await fetch(
    `${API_BASE_PATH}/outofstock`,
    API_DEFAULT_HEADER
  );
  return await outofstock.json();
};

const Default_Function = () => {
  const [view_mode, set_view_mode] = useState("home");
  const [IframeURL, set_IframeURL] = useState("");

  const [anomalies, set_anomalies] = useState([]);
  const [quick_summary, set_quick_summary] = useState([]);
  const [case_fillrate, set_case_fillrate] = useState([]);
  const [service_level, set_service_level] = useState([]);
  const [oos, set_oos] = useState([]);

  const [f_anomalies, set_f_anomalies] = useState([]);
  const [f_quick_summary, set_f_quick_summary] = useState([]);
  const [f_case_fillrate, set_f_case_fillrate] = useState([]);
  const [f_service_level, set_f_service_level] = useState([]);
  const [f_oos, set_f_oos] = useState([]);

  const [demand_forecasting_links, set_demand_forecasting_links] = useState([]);

  let view_mode_from_child = (mode) => {
    set_view_mode(mode);
  };

  let i_framelink_from_child = (url, view_mode) => {
    if (view_mode === "demand_forecasting") {
      set_demand_forecasting_links(url);
      return;
    }
    set_IframeURL(url);
  };

  let get_date_by_datatype = (e, data_type) => {
    let d = e.getDate();
    let m = e.getMonth() + 1;
    let y = e.getFullYear();

    let singleDigit = (sd) => {
      if (sd < 10) {
        return "0" + sd;
      }
      return sd;
    };

    if (data_type === "anomalies") {
      //  "13/02/2022"
      return singleDigit(d) + "/" + singleDigit(m) + "/" + y;
    }

    if (data_type === "quick_summary") {
      //  "02-20-2022",
      return singleDigit(m) + "-" + singleDigit(d) + "-" + y;
    }

    if (data_type === "case_fillrate" || data_type === "service_level") {
      // "1/16/2022"
      return m + "/" + d + "/" + y;
    }

    if (data_type === "oos") {
      //  "2022-01-23"
      return y + "-" + singleDigit(m) + "-" + singleDigit(d);
    }
  };

  let filter_the_data_by_key_date = (data, key, date) => {
    let d = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i][key] === date) {
        d.push(data[i]);
      }
    }
    return d;
  };

  let date_event_change = (e) => {
    // let m = e.getMonth();
    filter_data(e);
  };

  let make_summary_arr = (data, omit_key) => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      for (const key in data[i]) {
        if (key !== omit_key) {
          arr.push(data[i][key]);
        }
      }
    }
    return arr;
  };

  let filter_data = (e) => {
    set_f_anomalies(
      filter_the_data_by_key_date(
        anomalies,
        "Date",
        get_date_by_datatype(e, "anomalies")
      )
    );
    set_f_quick_summary(
      make_summary_arr(
        filter_the_data_by_key_date(
          quick_summary,
          "Date",
          get_date_by_datatype(e, "quick_summary")
        ),
        "Date"
      )
    );
    set_f_case_fillrate(
      filter_the_data_by_key_date(
        case_fillrate,
        "GREG_WK_BEGIN_DT",
        get_date_by_datatype(e, "case_fillrate")
      )
    );
    set_f_service_level(
      filter_the_data_by_key_date(
        service_level,
        "GREG_WK_BEGIN_DT",
        get_date_by_datatype(e, "service_level")
      )
    );
    set_f_oos(
      filter_the_data_by_key_date(
        oos,
        "GREG_WK_BEGIN_DT",
        get_date_by_datatype(e, "oos")
      )
    );
  };

  useEffect(() => {
    (async () => {
      set_anomalies(await getAnomalies());
      set_quick_summary(await getQuickSummary());
      set_case_fillrate(await getCaseFillRate());
      set_service_level(await getServiceLevel());
      set_oos(await getOOS());
    })();
  }, []);

  useEffect(() => {
    if (anomalies && quick_summary && case_fillrate && service_level && oos)
      date_event_change(new Date("2022-02-06"));
  }, [anomalies, quick_summary, case_fillrate, service_level, oos]);

  return (
    <>
      <Header
        date_event_change={date_event_change}
        view_mode={view_mode}
      ></Header>
      <SideBar left_pan_selector={view_mode_from_child}></SideBar>
      <RB.Row className="cpg_landing_content">
        {view_mode === "home" ? (
          <Landing
            health_check={{
              f_anomalies: f_anomalies,
              quick_summary: f_quick_summary,
              case_fillrate: f_case_fillrate,
              service_level: f_service_level,
              oos: f_oos,
            }}
            visibility={{
              f_anomalies: f_anomalies,
              left_pan_selector: view_mode_from_child,
            }}
            optimization={{
              view_mode_from_child: view_mode_from_child,
              i_framelink_from_child: i_framelink_from_child,
            }}
          ></Landing>
        ) : (
          <></>
        )}

        {view_mode === "connected_visibility" ? (
          <>
            <ConnectedVisibility />
          </>
        ) : (
          <></>
        )}

        {view_mode === "anomalies" ? (
          <Anomalies anomalies={anomalies} />
        ) : (
          <></>
        )}

        {view_mode === "iFrames_powerbi" ? (
          <>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <POWERBI URL={IframeURL} height={1000} />
          </>
        ) : (
          <></>
        )}

        {view_mode === "iFrames_tabulea" ? (
          <>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <TABULEA WIDTH={"100%"} HEIGHT={"90vh"} URL={IframeURL} />
          </>
        ) : (
          <></>
        )}

        {/* {view_mode === "on_shelf_avalability" ? <PEPSICO/> : <></>} */}

        {view_mode === "demand_forecasting" ? (
          <>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <RB.Row>&nbsp;</RB.Row>
            <POWERBI URL={demand_forecasting_links[0]} height={800} />
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <POWERBI URL={demand_forecasting_links[1]} height={800} />
          </>
        ) : (
          <></>
        )}
      </RB.Row>
      <Footer></Footer>
    </>
  );
};

export default Default_Function;
