import * as RB from "react-bootstrap";
import React, { useRef, useState } from "react";
import LoginImg from "../../images/login-img.png";
import ReCAPTCHA from "react-google-recaptcha";

import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import * as service from "../app_service";

import "./style.css";

const Default_Function = () => {
  const captchaRef = useRef(null);
  const alert = useAlert();

  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const history = useNavigate();

  let captcha_token = () => {
    return captchaRef.current.getValue();
  };

  const login = async () => {
    let captcha_data = captcha_token();

    if (captcha_data === "") {
      alert.show("ReCaptcha Validation Failed");
      return;
    }

    if (email === "") {
      alert.show("Enter Valid Email");
      return;
    }

    if (password === "") {
      alert.show("Enter Valid password");
      return;
    }

    if (email && password && captcha_data) {
      signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
          console.log("ACCESS TOKEN *************", res);
          service.set_token(res["user"]["accessToken"]);
          service.set_auth_obj(res);
          let u_obj = service.user_from_authobj(res);
          console.log("u_obj", u_obj);
          service.set_user_obj(u_obj);
          history("/");
        })
        .catch((err) => {
          alert.show("Invalid Credential");
          console.log("Login Error",err.message);
        })
        .finally(() => {});
    }
  };

  return (
    <>
      <RB.Row className="bg_height zero_margin login_bg">
        <RB.Col md={7}>
          <RB.Row>
            <RB.Card className="login_img_card">
              <RB.Card.Body>
                <img className="login_img" src={LoginImg} alt={LoginImg}></img>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>
        </RB.Col>
        <RB.Col md={5}>
          <RB.Row className="login_input">
            <RB.Card>
              <RB.Card.Body>
                <RB.Row>
                  <h3 className="login_header">LOGIN NOW</h3>
                </RB.Row>
                <RB.Row>&nbsp;</RB.Row>

                <RB.Row>
                  <RB.Form.Control
                    size="lg"
                    type="email"
                    value={email}
                    onChange={(e) => set_email(e.target.value)}
                    placeholder="Email"
                  />
                </RB.Row>
                <RB.Row>&nbsp;</RB.Row>
                <RB.Row>
                  <RB.Form.Control
                    size="lg"
                    type="password"
                    value={password}
                    onChange={(e) => set_password(e.target.value)}
                    placeholder="password"
                  />
                </RB.Row>

                <RB.Row>&nbsp;</RB.Row>
                <RB.Row>
                  <ReCAPTCHA
                    sitekey="6Ldx7ewnAAAAAFTZ8oFA3p-p2rA4nNzovfC9umMb"
                    // size="invisible"
                    ref={captchaRef}
                  />
                </RB.Row>
                <RB.Row>&nbsp;</RB.Row>
                <RB.Row>
                  <RB.Button variant="primary" size="lg" onClick={login}>
                    Login
                  </RB.Button>
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </RB.Row>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
