import * as RB from "react-bootstrap";
import { useState } from "react";
import HomeImg from "../../../images/retail-images/retail-home-page.jpg";
import MetricsImg02 from "../../../images/retail-images/home-metrics-this-week.jpg";
import MetricsImg from "../../../images/retail-images/home-metrics-next-week.jpg";
import TabuleaView from "../../DashboardViews/TABLEAU";
import POWERBI from "../../DashboardViews/POWERBI";
import Calculation from "../calculation";
import Prediction from "../../../images/retail-images/highest-priority.jpg";
import Anomalies from "../../../images/retail-images/anomalies.jpg";
import Supplychain from "../../../images/retail-images/supply-chain.jpg";
import Accelerators from "../../../images/retail-images/tools.jpg";
import "./style.css";

const Default_Function = (props) => {
  let [sel_com_view, set_sel_com_view] = useState("this_week");
  let [d_v_m_thisweek, set_d_v_m_thisweek] = useState(1);

  let com_view = [
    { name: "This week", value: "this_week" },
    { name: "Next Week", value: "next_week" },
  ];

  return (
    <>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <img src={HomeImg} alt={HomeImg} />
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>

      <RB.Row>
        <RB.Row>&nbsp;</RB.Row>
        <RB.Row>
          <RB.ButtonGroup>
            {com_view.map((radio, idx) => (
              <RB.ToggleButton
                className="zero_border_radius zero_zindex button_gp_btn"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={sel_com_view === radio.value}
                onChange={(e) => set_sel_com_view(e.currentTarget.value)}
              >
                {radio.name}
              </RB.ToggleButton>
            ))}
          </RB.ButtonGroup>
        </RB.Row>
        <RB.Row>&nbsp;</RB.Row>

        {sel_com_view === "this_week" ? (
          <RB.Card className="zero_padding zero_border_radius ">
            <RB.Card.Header className="zero_border_radius">
              <RB.Row>
                <RB.Col md={10}>
                  * All growth values are in comparison with the same week the
                  previous FY by default
                </RB.Col>
                <RB.Col md={2}>
                  <RB.ButtonGroup
                    size="sm"
                    aria-label="Basic example"
                    className="font_Candara"
                  >
                    <RB.Button
                      className="zero_border_radius"
                      variant={
                        d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                      }
                      onClick={() => {
                        set_d_v_m_thisweek(1);
                      }}
                    >
                      Tableau
                    </RB.Button>
                    <RB.Button
                      className="zero_border_radius"
                      variant={
                        d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                      }
                      onClick={() => {
                        set_d_v_m_thisweek(2);
                      }}
                    >
                      Power BI
                    </RB.Button>
                  </RB.ButtonGroup>
                </RB.Col>
              </RB.Row>
            </RB.Card.Header>
            <RB.Card.Body>
              <RB.Row>
                <img
                  onClick={() => {
                    props.landing.change_tools_with_dashboard(
                      "tools_acc",
                      "optimal_optimizer"
                    );
                  }}
                  src={MetricsImg02}
                  alt={MetricsImg02}
                />
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>
              <RB.Row>
                {d_v_m_thisweek === 1 ? (
                  <TabuleaView
                    WIDTH={"100%"}
                    HEIGHT={"110vh"}
                    URL={
                      "https://public.tableau.com/views/OSA_homepage_TW/OA-TW2?:language=en-US&:display_count=n&:origin=viz_share_link"
                    }
                  />
                ) : (
                  <POWERBI
                    URL={
                      "https://app.powerbi.com/view?r=eyJrIjoiMWVkZDcwOTgtMzdjOC00NTAxLTk1MDktMGU1OWM2MTQ5NmZmIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                    }
                    height={1000}
                  />
                )}
              </RB.Row>
              <RB.Row>&nbsp;</RB.Row>
              <RB.Row>
                <RB.Col md={10}>&nbsp;</RB.Col>
                <RB.Col
                  md={2}
                  onClick={() => {
                    props.landing.change_tools_with_dashboard(
                      "tools_acc",
                      "optimal_optimizer"
                    );
                  }}
                >
                  View more details
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        ) : (
          <></>
        )}
        {sel_com_view === "next_week" ? (
          <RB.Card className="zero_padding zero_border_radius ">
            <RB.Card.Header className="zero_border_radius">
              <RB.Row>
                <RB.Col md={10}>
                  * All growth values are in comparison with the same week the
                  previous FY by default
                </RB.Col>
                <RB.Col md={2}>
                  <RB.ButtonGroup size="sm" aria-label="Basic example" className="font_Candara">
                    <RB.Button
                      className="zero_border_radius"
                      variant={
                        d_v_m_thisweek === 1 ? "primary" : "outline-primary"
                      }
                      onClick={() => {
                        set_d_v_m_thisweek(1);
                      }}
                    >
                      Tableau
                    </RB.Button>
                    <RB.Button
                      className="zero_border_radius"
                      variant={
                        d_v_m_thisweek === 2 ? "primary" : "outline-primary"
                      }
                      onClick={() => {
                        set_d_v_m_thisweek(2);
                      }}
                    >
                      Power BI
                    </RB.Button>
                  </RB.ButtonGroup>
                </RB.Col>
              </RB.Row>
            </RB.Card.Header>
            <RB.Card.Body>
              <RB.Row>
                <img src={MetricsImg} alt={MetricsImg} />
              </RB.Row>
              <RB.Row>
                {d_v_m_thisweek === 1 ? (
                  <TabuleaView
                    WIDTH={"100%"}
                    HEIGHT={"110vh"}
                    URL={
                      "https://public.tableau.com/views/OSA_homepage_NW/OA-NW2?:language=en-US&:display_count=n&:origin=viz_share_link"
                    }
                  />
                ) : (
                  <POWERBI
                    URL={
                      "https://app.powerbi.com/view?r=eyJrIjoiNzVlYjBjMDctYTlkNC00YWRmLWIwN2EtZGVmMjdkZGMzNzhjIiwidCI6ImIwODBmMDE5LTkwNWQtNDA1Yy05NjBhLTAxM2ZjY2M3NjFkZCJ9"
                    }
                    height={1000}
                  />
                )}
              </RB.Row>
              <RB.Row>
                <RB.Col md={10}>&nbsp;</RB.Col>
                <RB.Col
                  md={2}
                  onClick={() => {
                    props.landing.change_tools_with_dashboard(
                      "tools_acc",
                      "optimal_optimizer"
                    );
                  }}
                >
                  View more details
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        ) : (
          <></>
        )}
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>

      <RB.Row>
        <Calculation
          data={{
            summary: "Optimal Availability",
            input1: "Osa",
            input2: "Delivery Promise",
            input3: "Prediction Variable",
            drop_down: [
              {
                label: "OA",
                value: 1,
              },
              {
                label: "Sales",
                value: 2,
                isDisabled: false,
              },
              {
                label: "Profit",
                value: 3,
                isDisabled: false,
              },
            ],
          }}
        />
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <RB.Col md={7}>
          <img className="h_image" src={Prediction} alt={Prediction} />
        </RB.Col>
        <RB.Col md={5}>
          <RB.Card className="zero_border_radius">
            <RB.Card.Body className="">
              <RB.Row className="h_links">
                <RB.Col md={4}>
                  <img src={Anomalies} alt={Anomalies} />
                </RB.Col>
                <RB.Col md={4}>
                  <img src={Supplychain} alt={Supplychain} />
                </RB.Col>
                <RB.Col md={4}>
                  <img
                    onClick={() => {
                      props.landing.change_home_view_mode("tools_acc");
                    }}
                    src={Accelerators}
                    alt={Accelerators}
                  />
                </RB.Col>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
