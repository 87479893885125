import { useNavigate } from "react-router-dom";
import { useEffect} from "react";

import * as service from "./pages/app_service";

const Auth_Validator = ({ children }) => {
  const navigate = useNavigate();

  let is_login_expired = () => {
    if (service.login_expired()) {
      navigate("/login");
    }
  };

  let check_auth = () => {
    let accessToken = service.get_token();

    if (accessToken === null) {
      navigate("/login");
    }

    if (accessToken === "" || accessToken === null) {
      navigate("/login");
    }
  };
  useEffect(() => {
    check_auth();
  }, []);

  useEffect(() => {
    setInterval(() => {
      is_login_expired();
    }, 60000);
  },[]);

  return children;
};

export default Auth_Validator;
