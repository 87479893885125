import React from "react";
import ReactDOM from "react-dom";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login";
import Landing from "./pages/Landing";
import Industrials from "./pages/Industrial";
import CPG from "./pages/Cpg";
import Retail from "./pages/Retail";
import ChatBot from "./pages/Chatbot";
import DemandSimulator from "./pages/Demand_simulator";
import GMap from "./pages/Cpg/google_map";

import FLow from "./pages/react_flow";
import RandD from "./pages/R&D"

import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import AuthValidator from "./auth_validator";
import "./style.css";
import "./my_bootstrap.css";

const options = {
  position: "bottom center",
  timeout: 2000,
  offset: "10px",
  transition: "scale",
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <React.StrictMode>
      <Router>
        <Routes>
        {/* <Route path="/R&D" element={<RandD />} /> */}

          <Route path="/map" element={<GMap />} />

          <Route path="/login" element={<Login />} />
          {/* <Route path="/flow" element={<FLow />} /> */}
          <Route
            path="/"
            element={
              <AuthValidator>
                <Landing />
              </AuthValidator>
            }
          />

          <Route
            path="/industrial"
            element={
              <AuthValidator>
                <Industrials />
              </AuthValidator>
            }
          />

          <Route
            path="/cpg"
            element={
              <AuthValidator>
                <CPG />
              </AuthValidator>
            }
          />
          <Route
            path="/retail"
            element={
              <AuthValidator>
                <Retail />
              </AuthValidator>
            }
          />
          <Route
            path="/chatbot"
            element={
              <AuthValidator>
                <ChatBot />
              </AuthValidator>
            }
          />
          <Route
            path="/demand"
            element={
              <AuthValidator>
                <DemandSimulator />
              </AuthValidator>
            }
          />
        </Routes>
      </Router>
    </React.StrictMode>
  </AlertProvider>,
  document.getElementById("root")
);
