import * as RB from "react-bootstrap";
import LineComp from "./chart_comp";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row>
        <RB.Col md={6}>
          <RB.Card className="zero_border_radius">
            <LineComp chart_option={props.chart.supplier_ricks_graph} />
          </RB.Card>
        </RB.Col>
        <RB.Col md={6} className="no_r_padding">
          <RB.Card className="zero_border_radius">
            <LineComp chart_option={props.chart.inventory_ricks_graph} />
          </RB.Card>
        </RB.Col>
      </RB.Row>
      <RB.Row>&nbsp;</RB.Row>
      <RB.Row>
        <RB.Col md={6}>
          <RB.Card className="zero_border_radius">
            <LineComp chart_option={props.chart.responsiveness_graph} />
          </RB.Card>
        </RB.Col>
        <RB.Col md={6} className="no_r_padding">
          <RB.Card className="zero_border_radius">
            <LineComp chart_option={props.chart.quality_graph} />
          </RB.Card>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
