import * as RB from "react-bootstrap";
import Icon01 from "../../../images/osa-images/icon-01.png";
import Icon02 from "../../../images/osa-images/icon-02.jpg";
import Icon03 from "../../../images/osa-images/icon-03.jpg";
import "./style.css";

const Default_Function = (props) => {
  return (
    <>
      <RB.Row className="osa_healh_card_img">
        <RB.Col md={12}>
          <RB.Row>
            <RB.Col md={4}>
              <RB.Card className="osa_health_p_card zero_padding zero_border_radius">
                <RB.Card.Body className="health_p_card_common">
                  <RB.Row className="osa_healh_card_img">
                    <img
                      className="osa_heath_p_card_icon"
                      src={Icon01}
                      alt={Icon01}
                    />
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row>
                    <h3>{props.data[0]["name"]}</h3>
                  </RB.Row>
                  <RB.Row>
                    <h5 className="osa_health_p_value">
                      {props.data[0]["value"]}
                      {props.data[0]["value_suffix"]}
                    </h5>
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row className="osa_healh_sub_value">
                    <h5 className="osa_health_p_subvalue">
                      {props.data[0]["sub_value_prefix"]}
                      {props.data[0]["sub_value"]}
                      {props.data[0]["sub_value_suffix"]}
                    </h5>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={4}>
              <RB.Card className="osa_health_s_card zero_padding zero_border_radius">
                <RB.Card.Body className="osa_health_card">
                  <RB.Row className="osa_healh_card_img">
                    <img
                      className="osa_heath_p_card_icon"
                      src={Icon02}
                      alt={Icon02}
                    />
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row>
                    <h3>{props.data[1]["name"]}</h3>
                  </RB.Row>
                  <RB.Row>
                    <h5 className="osa_health_p_value">
                      {props.data[1]["value"]}
                      {props.data[1]["value_suffix"]}
                    </h5>
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row className="osa_healh_sub_value">
                    <h5 className="osa_health_p_subvalue">
                      {props.data[1]["sub_value_prefix"]}
                      {props.data[1]["sub_value"]}
                      {props.data[1]["sub_value_suffix"]}
                    </h5>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
            <RB.Col md={4}>
              <RB.Card className="osa_health_s_card zero_padding zero_border_radius">
                <RB.Card.Body className="health_p_card_common">
                  <RB.Row className="osa_healh_card_img">
                    <img
                      className="osa_heath_p_card_icon"
                      src={Icon03}
                      alt={Icon03}
                    />
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row>
                    <h3>{props.data[2]["name"]}</h3>
                  </RB.Row>
                  <RB.Row>
                    <h5 className="osa_health_p_value">
                      {props.data[2]["value"]}
                      {props.data[2]["value_suffix"]}
                    </h5>
                  </RB.Row>
                  <RB.Row>&nbsp;</RB.Row>
                  <RB.Row className="osa_healh_sub_value">
                    <h5 className="osa_health_p_subvalue">
                      {props.data[2]["sub_value_prefix"]}
                      {props.data[2]["sub_value"]}
                      {props.data[2]["sub_value_suffix"]}
                    </h5>
                  </RB.Row>
                </RB.Card.Body>
              </RB.Card>
            </RB.Col>
          </RB.Row>
        </RB.Col>
      </RB.Row>
    </>
  );
};

export default Default_Function;
