import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const Line_Component = (props) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: props.chart_option.header||"",
      },
    },
  };


  const data = {
    labels:props.chart_option.labels||[],
    datasets: props.chart_option.datasets||[],
  };

  return (
    <>
      <Chart type='bar' data={data} height={100} />
    </>
  );
};

export default Line_Component;
