export const API_BASE_PATH = "https://backend-dot-mattel-mvp.el.r.appspot.com";

export const API_DEFAULT_HEADER = {
  "X-Content-Type-Options": "nosniff",
  "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
  "X-Frame-Options": "sameorigin",
  // "Content-Security-Policy": "default-src https:; img-src * 'self' data:",
};

export const exp_time = 3600000; //60000 is approximately 1 min
export const cookie = "auth_token";
export const cookie_user = "auth_user";
export const cookie_auth = "auth_obj";
export const cookie_version = "app_version";
export const cookie_version_value = "1.0";

export function get_appversion() {
  return JSON.parse(localStorage.getItem(cookie_version));
}

export function set_appversion() {
  localStorage.setItem(cookie_version, cookie_version_value);
}

export function set_user_obj(obj) {
  localStorage.setItem(cookie_user, JSON.stringify(obj));
}

export function get_user_obj() {
  return JSON.parse(localStorage.getItem(cookie_user));
}

export function set_auth_obj(obj) {
  localStorage.setItem(cookie_auth, JSON.stringify(obj));
}

export function get_auth_obj() {
  return JSON.parse(localStorage.getItem(cookie_auth));
}

export function set_token(obj) {
  localStorage.setItem(cookie, obj);
}

export function get_token() {
  return localStorage.getItem(cookie);
}

export function user_from_authobj(obj) {
  let d = obj["user"];
  let u_name = "";

  if (d["email"] != "" && d["email"].includes("@")) {
    let sa = d["email"].split("@");
    u_name = sa[0];
  }

  if (u_name == "") {
    u_name = d["email"];
  }
  return {
    lastLoginAt: new Date(),
    name: u_name,
    email: d["email"],
    displayName: u_name,
  };
}

export function login_expired() {
  let user_obj = get_user_obj();
  console.log("USER OBJ in VALIDATOR", user_obj);
  if (user_obj === null) {
    return true;
  }

  if ("lastLoginAt" in user_obj) {
    if (user_obj["lastLoginAt"] == "" || user_obj["lastLoginAt"] == null) {
      return true;
    }

    let diff = new Date() - new Date(user_obj["lastLoginAt"]);

    if (diff > exp_time) {
      return true;
    }
  }
  return false;
}

export function logout() {
  localStorage.removeItem(cookie);
  localStorage.removeItem(cookie_user);
  localStorage.removeItem(cookie_auth);
}
